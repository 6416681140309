import React, { Component, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Nav, Navbar, Container, NavDropdown, Modal, Form, Row, Button } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom'
import { useLogout } from '../../hooks/useLogout';
import { useAuthContext } from "../../hooks/useAuthContext";
import { apiCall } from './../../helpers/api'
import { getProfilePicture } from '../../helpers/user';
import { useImageContext } from "../../hooks/useImageContext";
import { getImageUrl } from "../../helpers/images";
import { relativeTimeFormat } from "../../helpers/dates"

import './Topbar.css';

import avatar from './media/avatar.svg'
import list from './media/list.svg'
import notification from './media/bell.svg'
import logo from '../../images/theRoom.svg';
import mobileLogo from '../../images/mobileLogo.svg';
import admin from '../../images/nav-admin.svg';
import inbox from '../../images/nav-inbox.svg';
import apps from '../../images/apps.svg'

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceRegistered: false
    }
    this.getNotifications()
  }

  async getNotifications(){
    var response = null;
    this.props.setNotificationsLoading(true)
    var notifications = this.props.notifications;
    if (this.props.notifications.length > 0){
      response = await apiCall(`notifications?limit=5&after=${this.props.lastNotificationTime}`,{ "method" : "GET" });
    }
    else {
      response = await apiCall(`notifications?limit=5`,{ "method" : "GET" });
    }
    if (response["success"]){
      if (response["success"]["notifications"].length > 0){
        response["success"]["notifications"].forEach(notification => {
          notifications.push(notification)
        })
        notifications.sort((a, b) => {
          if (a.notifiedTime < b.notifiedTime) {
            return 1
          }
          return -1
        })
        this.props.setLastNotificationTime(notifications[0].notifiedTime)
      }
      if (response["success"]["unread"]){
        this.props.setHasUnreadNotifications(true)
      }
    }
    this.props.setNotificationsLoading(false)
    this.props.setNotifications(notifications.slice(0,5))
  }

  async updateTerritory(territory){
    if (territory != this.props.currentProfile.territory)
    var response = await apiCall(`admin/switchTerritory`,{ "method" : "POST", "data" : {"territory" : territory} });
      if (response["success"]){
        sessionStorage.removeItem("browsewallState")
        window.location.reload();
      }
  }

  async readNotifications(){
    if (this.props.notifications.length > 0 && this.props.hasUnreadNotifications){
      var response = await apiCall(`notifications/read`,{ "method" : "PUT" });
      if (response["success"]){
        this.props.setHasUnreadNotifications(false)
      }
    }
  }

  SwitchProfileModal(props){
    const switchProfile = async (type,id) => {
      var response = await apiCall(`switchProfile?id=${id}&type=${type}`,{ "method" : "GET" });
      if (response["success"]){
        sessionStorage.removeItem("browsewallState")
        window.location.href = "/";
      }
      else {
        console.log(response)
      }
    }
    return (
      <Modal centered show={props.show} onHide={() => props.class.props.setSwitchProfileModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="h6">Switch Profile</Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body className="pt-0">
            {props.memberships.influencers && props.memberships.influencers.length > 0 && (
            <>
              <div className="text-muted">Available Influencers</div>
              <Row className="selectedBids">
                {props.memberships.influencers.map(influencer => (
                  <div className="col-6 d-flex align-items-center small pt-3" key={influencer["id"]}>
                    <img src={getImageUrl(props.class.props.providers,influencer.profileImage,["w_80","h_80","c_scale"])} alt="avatar" width="42px" className="border rounded me-2 cursor-pointer" onClick={() => switchProfile("influencers",influencer["id"])} />
                     <span className={"cursor-pointer"} onClick={() => switchProfile("influencers",influencer["id"])}>{influencer.name}</span>
                  </div>
                ))}
              </Row>
              <br />
            </>
            )}
            {props.memberships.brands && props.memberships.brands.length > 0 && (
            <>
              <div className="text-muted">Available Brands</div>
              <Row className="selectedBids">
                {props.memberships.brands.map(brand => (
                  <div className="col-6 d-flex align-items-center small pt-3" key={brand["id"]}>
                    <img src={getImageUrl(props.class.props.providers,brand.profileImage,["w_80","h_80","c_scale"])} alt="avatar" width="42px" className="border rounded me-2 cursor-pointer" onClick={() => switchProfile("brands",brand["id"])} />
                    <span className={"cursor-pointer"} onClick={() => switchProfile("brands",brand["id"])}>{brand.name}</span>
                  </div>
                ))}
              </Row>
            </>
            )}
          </Modal.Body>
          <Modal.Footer className="p-3 pt-0 border-0">
            <Button variant="outline-secondary" size="sm" className="m-0 me-2" onClick={() => props.class.props.setSwitchProfileModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
  
  render() {
    return (
      <header className={`site-header ${this.props.currentProfile.type != "admins" && "d-none d-md-block"}`}>
        <this.SwitchProfileModal show={this.props.switchProfileModal} class={this} memberships={this.props.data.memberships}/>
        <Navbar expand="md" className='fixed-top' id="topBar">
          <Container fluid className='px-sm-0'>
            <Navbar.Brand className='d-none d-sm-block' onClick={() => { sessionStorage.removeItem("browsewallState"); this.props.navigate("/") }}><img src={logo} className="logo" alt="The Influencer Room" id="topBar-logo" /></Navbar.Brand>
            <Navbar.Brand className='d-flex d-sm-none ps-3' onClick={() => { sessionStorage.removeItem("browsewallState"); this.props.navigate("/") }}>              
              <img src={mobileLogo} height="30px" width="30px" alt="The Influencer Room" />
            </Navbar.Brand>
            {process.env.REACT_APP_DISABLE_AUTH == "true" && (
              <Navbar.Text><span style={{color: "red"}}>AUTHENTICATION IS DISABLED - DO NOT USE IN PRODUCTION</span></Navbar.Text>
            )}
            <Navbar id="basic-navbar-nav" className='justify-content-end'>
              <Nav id="topbar-right">
                {(this.props.currentProfile.type == "admins" || process.env.REACT_APP_DISABLE_AUTH == "true") && (
                  <>
                  <NavLink to="/Administration/Dashboard" activeclassname="active" className="leftSideMenuName p-2 me-2 me-md-3 text-primary">
                    Dashboard
                  </NavLink>
                  <NavLink to="/Administration/Applications" activeclassname="active" className="leftSideMenuName p-2 me-2 me-md-3 text-primary">
                    Applications
                  </NavLink>
                  </>
                )}
                {(this.props.currentProfile.type == "admins" || this.props.views.includes("influencersMultiple") || process.env.REACT_APP_DISABLE_AUTH == "true") &&
                  <NavLink to="/Administration/Influencers" activeclassname="active" className="leftSideMenuName p-2 me-2 me-md-3 text-primary">
                    Influencers
                  </NavLink>
                }
                {this.props.currentProfile.type == "admins" && 
                  <NavLink to="/Administration/Profiles" activeclassname="active" className="leftSideMenuName p-2 me-2 me-md-3 text-primary">
                    Profiles
                  </NavLink>
                }
                {(this.props.currentProfile.type == "admins" || this.props.views.includes("brandsMultiple") || process.env.REACT_APP_DISABLE_AUTH == "true") &&
                  <NavLink to="/Administration/Brands" activeclassname="active" className="leftSideMenuName p-2 me-2 me-md-3 text-primary">
                    Brands
                  </NavLink>
                }
                {(this.props.currentProfile.type == "admins" || process.env.REACT_APP_DISABLE_AUTH == "true") && (
                  <>
                  <NavLink to="/Administration/Users" activeclassname="active" className="leftSideMenuName p-2 me-2 me-md-3 text-primary">
                    Users
                  </NavLink>
                  <NavLink to="/Administration/Reporting" activeclassname="active" className="leftSideMenuName p-2 me-2 me-md-3 text-primary">
                    Reporting
                  </NavLink>
                  <NavLink to="/Administration/Referrals" activeclassname="active" className="leftSideMenuName p-2 me-2 me-md-3 text-primary">
                    Referrals
                  </NavLink>
                  <NavLink to="/Administration/Settings" activeclassname="active" className="leftSideMenuName p-2 me-2 me-md-3 text-primary">
                    Settings
                  </NavLink>
                  </>
                )}
                {(this.props.currentProfile.type == "admins" || process.env.REACT_APP_DISABLE_AUTH == "true") && (
                  <NavLink to="/Administration/Dashboard" activeclassname="active" className="p-2 me-2 me-md-3 text-primary leftSideMenuShift"><span className="d-block d-md-none"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#007AA6"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg></span><span className="d-none d-md-block">Administration</span></NavLink>
                )}
                {this.props.views.includes("influencersMultiple") && (
                  <NavLink to="/Administration/Influencers" activeclassname="active" className="p-2 me-2 me-md-3 text-primary leftSideMenuShift"><span className="d-block d-md-none"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#007AA6"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg></span><span className="d-none d-md-block">My Influencers</span></NavLink>
                )}
                {this.props.views.includes("brandsMultiple") && (
                  <>
                  <NavLink to="/Administration/Brands" activeclassname="active" className="p-2 me-2 me-md-3 text-primary leftSideMenuShift"><span className="d-block d-md-none"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#007AA6"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg></span><span className="d-none d-md-block">My Brands</span></NavLink>
                  </>
                )}
                {/* {(this.props.currentProfile.type == "brands" || process.env.REACT_APP_DISABLE_AUTH == "true") && (
                  <>
                  <NavLink to="/Dashboard" activeclassname="active" className="p-2 me-3 text-primary">
                    Dashboard
                  </NavLink>
                  </>
                )} */}
                {(this.props.currentProfile.type == "brands" || process.env.REACT_APP_DISABLE_AUTH == "true") && (
                  <>
                  <NavLink to="/Campaigns" activeclassname="active" className="p-2 me-3 text-primary">Campaigns</NavLink>
                  </>
                )}
                {(this.props.currentProfile.type == "brands" || process.env.REACT_APP_DISABLE_AUTH == "true") && (
                  <>
                  <NavLink to="/Community" activeclassname="active" className="p-2 me-3 text-primary">Community</NavLink>
                  </>
                )}
                {(this.props.currentProfile.type == "brands" || process.env.REACT_APP_DISABLE_AUTH == "true") && (
                  <>
                  <NavLink to="/Campaign/Create" activeclassname="active" className="p-2 me-3 text-primary">Create Campaign</NavLink>
                  </>
                )}
                {(this.props.currentProfile.type == "influencers" || process.env.REACT_APP_DISABLE_AUTH == "true") && (
                  <>
                  <NavLink to="/" activeclassname="active" className="p-2 me-3 text-primary">Home</NavLink>
                  <NavLink to="/MyBids" activeclassname="active" className="p-2 me-3 text-primary">My Bids</NavLink>
                  </>
                )}
                <NavLink to="/Inbox" activeclassname="active" className="p-2 me-2 me-md-3 text-primary">{this.props.currentProfile.type == "admins" && <span className="d-block d-md-none"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#007AA6"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2z"/></svg></span>}<span className="d-none d-md-block">Inbox</span></NavLink>
                {(this.props.currentProfile.type == "admins" || process.env.REACT_APP_DISABLE_AUTH == "true") && (
                  <>
                  <NavLink to="/Administration/BrowseWall" activeclassname="active" className="p-2 me-2 me-md-3 text-primary"><svg className='align-top' xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#007AA6"><g><rect fill="none" height="24" width="24"/></g><g><g><g><path d="M3,3v8h8V3H3z M9,9H5V5h4V9z M3,13v8h8v-8H3z M9,19H5v-4h4V19z M13,3v8h8V3H13z M19,9h-4V5h4V9z M13,13v8h8v-8H13z M19,19h-4v-4h4V19z"/></g></g></g></svg></NavLink>
                  </>
                )}
                <NavDropdown onClick={() => {this.readNotifications();this.getNotifications()}} className={`notification ${this.props.hasUnreadNotifications && "unreadBadge"} me-3`} id="menu-button" drop="down" align="end" title={<img src={notification} alt="Notification" width="18px" /> }>  
                  {this.props.notifications.length > 0 ? (
                    <>
                    {this.props.notifications.map(notification => (
                      <Nav.Item key={notification.id}>
                        <div className='d-flex align-items-center p-3 border-bottom'>
                          {notification.data.link ? (
                            <>
                            {notification.data.link.includes("http") ?
                              <a target="_blank" rel="noreferrer noopener" href={notification.data.link}><img src={getImageUrl(this.props.providers,notification.data.image,["w_46","c_scale"])} height={46} width={46} className='rounded border object-fit-cover' /></a>
                            :
                              <Link to={notification.data.link}>
                                <img src={getImageUrl(this.props.providers,notification.data.image,["w_46","c_scale"])} height={46} width={46} className='rounded border object-fit-cover' />
                              </Link>
                            }
                            </>
                          ) : (
                            <img src={getImageUrl(this.props.providers,notification.data.image,["w_46","c_scale"])} height={46} width={46} className='rounded border object-fit-cover' />
                          )}
                          <div className='ms-3 pt-1'>
                          {notification.data.link ? (
                            <>
                            {notification.data.link.includes("http") ?
                              <a target="_blank" rel="noreferrer noopener" href={notification.data.link}><h6 className='mb-0 text-nowrap'>{notification.data.subject}</h6></a>
                            :
                              <Link className="text-black" to={notification.data.link}>
                                <h6 className='mb-0 text-nowrap'>{notification.data.subject}</h6>
                            </Link>
                            }
                            </>
                          ) : (
                            <h6 className='mb-0 text-nowrap'>{notification.data.subject}</h6>
                          )}
                            <div className='d-flex align-items-center justify-content-between'>
                              <span className='text-muted small text-nowrap'>{relativeTimeFormat(notification.notifiedTime*1000,true)}</span>
                              {this.props.hasUnreadNotifications && !notification.read && <div className='position-relative unreadBadge'></div>}
                            </div>
                          </div>
                        </div>
                      </Nav.Item>
                    ))}
                    <div className="px-3 text-center text-nowrap">
                      <NavLink className="small text-muted" to="/Notifications">See All Notifications</NavLink>
                    </div>
                    </>
                  ) : (
                    <div className="px-3 text-center text-nowrap">
                      {this.props.notificationsLoading ? <span className="spinner-border text-primary" role="status" aria-hidden="true"></span> : "No new notifications!"}
                    </div>
                  )}
                </NavDropdown>
                <NavDropdown id="menu-button" drop="down" align="end" title={ <span><img src={list} alt="List" className="icon-list" /> <img src={getImageUrl(this.props.providers,this.props.currentProfile.hasOwnProperty("profile") ? this.props.currentProfile.profile.profileImage : {},["w_75","c_scale"])} alt="avatar" height="35px" className="icon-avatar" /></span> }>   
                  {((this.props.currentProfile.type == "influencers" || this.props.currentProfile.type == "brands") || process.env.REACT_APP_DISABLE_AUTH == "true") && (
                    <>
                    {this.props.data.memberships.brands && this.props.data.memberships.brands.length > 1 || this.props.data.memberships.influencers && this.props.data.memberships.influencers.length > 1 || this.props.data.memberships.admins && this.props.data.memberships.admins.length > 1 ? <NavDropdown.Item onClick={() => { this.props.setSwitchProfileModal(true) }} className="px-3 py-2">Switch Profile</NavDropdown.Item> : null}
                    <NavDropdown.Item onClick={() => { this.props.navigate("/Settings?setting=support") }} className="px-3 py-2">Help & Support</NavDropdown.Item>
                    </>
                  )}
                  <NavDropdown.Item onClick={() => { this.props.navigate("/Settings?setting=account") }} className="px-3 py-2">Settings</NavDropdown.Item>

                  {this.props.currentProfile.type == "admins" && (
                    <>
                    <NavDropdown.Divider />

                    <NavDropdown.Item className={`px-3 py-2 ${this.props.currentProfile.type == "admins" && this.props.currentProfile.territory == "all" ? "toggleActive" : null}`} onClick={() => this.updateTerritory("all")} >
                      <div>All Territories</div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16"> <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/></svg>
                    </NavDropdown.Item>

                    <NavDropdown.Item className={`px-3 py-2 d-flex justify-content-between align-items-center ${this.props.currentProfile.type == "admins" && this.props.currentProfile.territory == "GB" ? "toggleActive" : null}`} onClick={() => this.updateTerritory("GB")}>
                      <div>UK</div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16"> <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/></svg>
                    </NavDropdown.Item>
                    
                    <NavDropdown.Item className={`px-3 py-2 d-flex justify-content-between align-items-center ${this.props.currentProfile.type == "admins" && this.props.currentProfile.territory == "US" ? "toggleActive" : null}`} onClick={() => this.updateTerritory("US")}>
                      <div>USA</div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16"> <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/></svg>
                    </NavDropdown.Item>

                    <NavDropdown.Item className={`px-3 py-2 d-flex justify-content-between align-items-center ${this.props.currentProfile.type == "admins" && this.props.currentProfile.territory == "DE" ? "toggleActive" : null}`} onClick={() => this.updateTerritory("DE")}>
                      <div>Germany</div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16"> <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/></svg>
                    </NavDropdown.Item>
                  </>
                  )}

                  {process.env.REACT_APP_DISABLE_AUTH == "false" && (
                    <>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => { this.props.logout() }} className="px-3 py-2">Logout</NavDropdown.Item>  
                    </> 
                  )}    
                </NavDropdown>
              </Nav>
            </Navbar>
          </Container>          
        </Navbar>
      </header>
    )
  }
}

export default function (props) {
  const navigate = useNavigate();
  const { views, data, currentProfile } = useAuthContext();
  const { providers } = useImageContext();
  const { logout, error, isLoading } = useLogout();
  const [notifications, setNotifications] = useState([]);
  const [notificationsLoading, setNotificationsLoading] = useState(false);
  const [lastNotificationTime, setLastNotificationTime] = useState(0);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false)
  const [switchProfileModal, setSwitchProfileModal] = useState(false);

  return <Topbar navigate={navigate} logout={logout} views={views} data={data} currentProfile={currentProfile} providers={providers} notifications={notifications} setNotifications={setNotifications} notificationsLoading={notificationsLoading} setNotificationsLoading={setNotificationsLoading} lastNotificationTime={lastNotificationTime} setLastNotificationTime={setLastNotificationTime} hasUnreadNotifications={hasUnreadNotifications} setHasUnreadNotifications={setHasUnreadNotifications} switchProfileModal={switchProfileModal} setSwitchProfileModal={setSwitchProfileModal} />;
}
