import React, { Component, useState, useEffect } from "react";
import { Container, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import InputGroup from 'react-bootstrap/InputGroup';
import Badge from 'react-bootstrap/Badge';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { apiCall, PageScroller } from './../../helpers/api';
import { getFilter, setFilter } from './../../helpers/filters'
import { useSearchParams, Link } from "react-router-dom";
import { getStatusPill } from "../../helpers/status";
import { useImageContext } from "../../hooks/useImageContext";
import { getImageUrl } from "../../helpers/images";

import './MyBids.css';
import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import Topbar from "../../components/topbar/Topbar";
import Footer from "../../components/footer/Footer";
import Search from './../../images/search-dark.svg';
import blueChecks from './../../images/blue-double-check.svg';


class MyBidsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      bids: [],
      bidIDs: [],
    };
    if (getFilter("bid")) {
      props.setFilters(JSON.parse(getFilter("bid")))
    }
  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }

  updateSearch(text) {
    var filters = this.props.filters;
    filters["search"] = text.toLowerCase()
    this.props.setFilters(Object.assign({},this.props.filters, filters))
    setFilter(filters,"bid")
  }

  toggleInactive(checked) {
    var filters = this.props.filters;
    filters["showInactive"] = checked
    this.props.setFilters(Object.assign({},this.props.filters, filters))
    setFilter(filters,"bid")
  }

  BidItem(props) {
    return (
      <div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-4">
        <Link to={"/MyBid?id="+props.bid.bidDetail.id}>
          <div className="p-3 bidsCard rounded-xl shadow border h-100">
            <div className="pe-3">
              <img src={getImageUrl(props.class.props.providers,props.bid.campaignDetail.image,["w_80","h_80","c_scale"])} width="46px" height="46px" className="object-fit-cover rounded border bg-white" />
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="brandDetails">
                <div className="text-muted small mb-3">
                  <img src={getImageUrl(props.class.props.providers,props.bid.campaignDetail.brand.profileImage,["w_80","h_80","c_scale"])} className="rounded me-1 border" width="30px" height="30px" /> {props.bid.campaignDetail.brand.name}
                </div>
                <h6 className="fw-700 mb-2 text-navy">{props.bid.campaignDetail.title}</h6>
                {["Accepted","In Progress"].includes(props.bid.bidDetail.state) && (
                  <div className="text-truncate text-muted small mb-2">Due: {new Date(props.bid.bidDetail.dueDate*1000).toLocaleDateString('en-GB', { year:"numeric", month:"long", day:"numeric"})}</div>
                )}
                {["Pending","Cancelled","Rejected"].includes(props.bid.bidDetail.state) && (
                  <div className="text-truncate text-muted small mb-2">Bid: {new Date(props.bid.bidDetail.time*1000).toLocaleDateString('en-GB', { year:"numeric", month:"long", day:"numeric"})}</div>
                )}
                {props.bid.bidDetail.state == "Marked as Complete" && (
                  <div className="text-truncate text-muted small mb-2">Marked as Complete: {new Date(props.bid.bidDetail.completionTime*1000).toLocaleDateString('en-GB', { year:"numeric", month:"long", day:"numeric"})}</div>
                )}
                {props.bid.bidDetail.state == "Completed" && (
                  <div className="text-truncate text-muted small mb-2">Completed: {new Date(props.bid.bidDetail.completionTime*1000).toLocaleDateString('en-GB', { year:"numeric", month:"long", day:"numeric"})}</div>
                )}
                {getStatusPill(props.bid.bidDetail.state)}            
              </div>
              <div className="text-end w-30px">
                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.26993 13.365C0.433726 13.529 0.655826 13.6211 0.887532 13.6211C1.11922 13.6211 1.34146 13.529 1.50513 13.365L7.43914 7.43571C7.60309 7.27192 7.69519 7.04982 7.69519 6.81811C7.69519 6.58641 7.60309 6.3643 7.43914 6.20051L1.50513 0.271175C1.34286 0.10068 1.11863 0.00294914 0.883285 5.75786e-05C0.647788 -0.0026833 0.421292 0.089721 0.255045 0.256256C0.088813 0.422944 -0.0032857 0.649623 6.16717e-05 0.884964C0.00325837 1.12031 0.101295 1.34439 0.272094 1.50635L5.58842 6.818L0.272093 12.1297C0.107993 12.2931 0.0154391 12.5151 0.014979 12.7468C0.0146747 12.9785 0.106318 13.2009 0.269962 13.3648L0.26993 13.365Z" fill="#939FB4" />
                </svg>
              </div>
            </div>
          </div>
        </Link>
      </div>
    )
  }

  render() {
    const setState = async (event,result) => { 
      result = await result;
      for (var x in result["bids"]) {
        if (!this.state.bidIDs.includes(result["bids"][x]["bidDetail"]["id"])) {
          this.state.bidIDs.push(result["bids"][x]["bidDetail"]["id"])
          this.state.bids.push(result["bids"][x])
        }
      }
      this.setState({ bids: this.state.bids, bidIDs : this.state.bidIDs }) 
    }
    const { error, isLoaded, bids } = this.state;
    var filter = this.props.filters;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return (
      <Container className="fullscreenWithTopBar px-0" fluid>
        <FullPageSpinner />; 
      </Container>
      )
    } else {
      return (
      <Container className="fullscreenWithTopBar px-0" fluid>
        <Container className="pageTitleContainer shadow-sm pb-0" fluid>
          <Container className="px-0 pt-0 pt-lg-1">
            <Row>
              <div className="col-sm-4 col-lg-6">
                <h1 className="px-3 mb-4 pt-4 pageTitle tragedyRegularFont">My Bids</h1>
              </div>
              <div className="col-sm-8 col-lg-6 text-end d-flex justify-content-end align-items-center">
                <div className="bidsSearch w-auto ms-3 me-md-3 me-xl-0">
                  <InputGroup className="py-1">
                    <Form.Control placeholder="Search" className="border-end-0" aria-label="Search" defaultValue={this.props.filters.search} onChange={e => this.updateSearch(e.target.value)} />
                    <InputGroup.Text className="bg-white border-end">
                      <img src={Search} />
                    </InputGroup.Text>
                  </InputGroup>
                </div>
              </div>
            </Row>
          </Container>
        </Container>  
        <Container fluid className="pb-0">
          <Container className="px-0 pt-2">
            <Row className="mx-0">
              <div className="col-12 px-0 mb-0">
                <div className="mb-2 pb-2 pt-4 text-center position-relative d-block d-md-none">
                  <div className="sectionTitle">My Bids</div>
                </div>

                <div className="bidsSearch d-block d-md-none">
                  <InputGroup className="py-1">
                    <Form.Control placeholder="Search" className="border-end-0" defaultValue={this.props.filters.search} onChange={e => this.updateSearch(e.target.value)}/>
                    <InputGroup.Text className="bg-white border-end">
                      <img src={Search} />
                    </InputGroup.Text>
                  </InputGroup>
                </div>

                <Tabs defaultActiveKey="all" className="mx-3 small" activeKey={this.props.tabID} onSelect={(key) => { this.props.setTabID(key); let currentUrlParams = new URLSearchParams(window.location.search); currentUrlParams.set('view', key); let newUrl = window.location.origin + window.location.pathname + '?' + currentUrlParams.toString(); window.history.replaceState({path:newUrl},'',newUrl);}}>
                  <Tab eventKey="all" title="All" className="mx-3">
                    <Row className="pt-4">
                      {this.state.bids.filter(function(bid) {return ( !["Completed","Cancelled","Rejected"].includes(bid.bidDetail.state) && (bid.campaignDetail.title.toLowerCase().includes(filter.search.toLowerCase()) || bid.campaignDetail.brand.name.toLowerCase().includes(filter.search.toLowerCase())) )}).map(bid => <this.BidItem key={bid.bidDetail.id} bid={bid} class={this}/>)}
                      <PageScroller endpoint="influencer/myBids" setState={setState} filter={this.props.filters} pageSize={250} />
                    </Row>
                  </Tab>
                  {(this.state.bids.filter(function(bid) {return bid.bidDetail.state == "Pending" && ( bid.campaignDetail.title.toLowerCase().includes(filter.search.toLowerCase()) || bid.campaignDetail.brand.name.toLowerCase().includes(filter.search.toLowerCase()) )}).length > 0 || this.props.tabID == "pending") && (
                  <Tab eventKey="pending" title={"Pending ("+this.state.bids.filter(function(bid) {return bid.bidDetail.state == "Pending"}).length+")"} className="mx-3">
                    <Row className="pt-4">
                      {this.state.bids.filter(function(bid) {return bid.bidDetail.state == "Pending" && ( bid.campaignDetail.title.toLowerCase().includes(filter.search.toLowerCase()) || bid.campaignDetail.brand.name.toLowerCase().includes(filter.search.toLowerCase()) )}).map(bid => <this.BidItem key={bid.bidDetail.id} bid={bid} class={this}/>)}
                      <PageScroller endpoint="influencer/myBids" setState={setState} filter={this.props.filters} pageSize={250} />
                    </Row>
                  </Tab>
                  )}
                  {(this.state.bids.filter(function(bid) {return bid.bidDetail.state == "Accepted" && ( bid.campaignDetail.title.toLowerCase().includes(filter.search.toLowerCase()) || bid.campaignDetail.brand.name.toLowerCase().includes(filter.search.toLowerCase()) )}).length > 0 || this.props.tabID == "accepted") && (
                  <Tab eventKey="accepted" title={"Accepted ("+this.state.bids.filter(function(bid) {return bid.bidDetail.state == "Accepted"}).length+")"} className="mx-3">
                    <Row className="pt-4">
                      {this.state.bids.filter(function(bid) {return bid.bidDetail.state == "Accepted" && ( bid.campaignDetail.title.toLowerCase().includes(filter.search.toLowerCase()) || bid.campaignDetail.brand.name.toLowerCase().includes(filter.search.toLowerCase()) )}).map(bid => <this.BidItem key={bid.bidDetail.id} bid={bid} class={this}/>)}
                      <PageScroller endpoint="influencer/myBids" setState={setState} filter={this.props.filters} pageSize={250} />
                    </Row>
                  </Tab>
                  )}
                  {(this.state.bids.filter(function(bid) {return bid.bidDetail.state == "In Progress" && ( bid.campaignDetail.title.toLowerCase().includes(filter.search.toLowerCase()) || bid.campaignDetail.brand.name.toLowerCase().includes(filter.search.toLowerCase()) )}).length > 0 || this.props.tabID == "inprogress") && (
                  <Tab eventKey="inprogress" title={"In Progress ("+this.state.bids.filter(function(bid) {return bid.bidDetail.state == "In Progress"}).length+")"} className="mx-3">
                    <Row className="pt-4">
                      {this.state.bids.filter(function(bid) {return bid.bidDetail.state == "In Progress" && ( bid.campaignDetail.title.toLowerCase().includes(filter.search.toLowerCase()) || bid.campaignDetail.brand.name.toLowerCase().includes(filter.search.toLowerCase()) )}).map(bid => <this.BidItem key={bid.bidDetail.id} bid={bid} class={this}/>)}
                      <PageScroller endpoint="influencer/myBids" setState={setState} filter={this.props.filters} pageSize={250} />
                    </Row>
                  </Tab>
                  )}
                  {(this.state.bids.filter(function(bid) {return bid.bidDetail.state == "Completed" && ( bid.campaignDetail.title.toLowerCase().includes(filter.search.toLowerCase()) || bid.campaignDetail.brand.name.toLowerCase().includes(filter.search.toLowerCase()) ) }).length > 0 || this.props.tabID == "completed") && (
                  <Tab eventKey="completed" title={"Completed ("+this.state.bids.filter(function(bid) {return bid.bidDetail.state == "Completed"}).length+")"} className="mx-3">
                    <Row className="pt-4">
                      {this.state.bids.filter(function(bid) {return bid.bidDetail.state == "Completed" && ( bid.campaignDetail.title.toLowerCase().includes(filter.search.toLowerCase()) || bid.campaignDetail.brand.name.toLowerCase().includes(filter.search.toLowerCase()) ) }).map(bid => <this.BidItem key={bid.bidDetail.id} bid={bid} class={this}/>)}
                      <PageScroller endpoint="influencer/myBids" setState={setState} filter={this.props.filters} pageSize={250} />
                    </Row>
                  </Tab>
                  )}
                  {(this.state.bids.filter(function(bid) {return bid.bidDetail.state == "Marked as Complete" && ( bid.campaignDetail.title.toLowerCase().includes(filter.search.toLowerCase()) || bid.campaignDetail.brand.name.toLowerCase().includes(filter.search.toLowerCase()) )}).length > 0 || this.props.tabID == "markedAsComplete") && (
                  <Tab eventKey="markedAsComplete" title={"Marked as Complete ("+this.state.bids.filter(function(bid) {return bid.bidDetail.state == "Marked as Complete"}).length+")"} className="mx-3">
                    <Row className="pt-4">
                      {this.state.bids.filter(function(bid) {return bid.bidDetail.state == "Marked as Complete" && ( bid.campaignDetail.title.toLowerCase().includes(filter.search.toLowerCase()) || bid.campaignDetail.brand.name.toLowerCase().includes(filter.search.toLowerCase()) )}).map(bid => <this.BidItem key={bid.bidDetail.id} bid={bid} class={this}/>)}
                      <PageScroller endpoint="influencer/myBids" setState={setState} filter={this.props.filters} pageSize={250} />
                    </Row>
                  </Tab>
                  )}
                  {(this.state.bids.filter(function(bid) {return bid.bidDetail.state == "Cancelled" && ( bid.campaignDetail.title.toLowerCase().includes(filter.search.toLowerCase()) || bid.campaignDetail.brand.name.toLowerCase().includes(filter.search.toLowerCase()) )}).length > 0 || this.props.tabID == "cancelled") && (
                  <Tab eventKey="cancelled" title={"Cancelled ("+this.state.bids.filter(function(bid) {return bid.bidDetail.state == "Cancelled"}).length+")"} className="mx-3">
                    <Row className="pt-4">
                      {this.state.bids.filter(function(bid) {return bid.bidDetail.state == "Cancelled" && ( bid.campaignDetail.title.toLowerCase().includes(filter.search.toLowerCase()) || bid.campaignDetail.brand.name.toLowerCase().includes(filter.search.toLowerCase()) )}).map(bid => <this.BidItem key={bid.bidDetail.id} bid={bid} class={this}/>)}
                      <PageScroller endpoint="influencer/myBids" setState={setState} filter={this.props.filters} pageSize={250} />
                    </Row>
                  </Tab>
                  )}
                  {(this.state.bids.filter(function(bid) {return bid.bidDetail.state == "Rejected" && ( bid.campaignDetail.title.toLowerCase().includes(filter.search.toLowerCase()) || bid.campaignDetail.brand.name.toLowerCase().includes(filter.search.toLowerCase()) )}).length > 0 || this.props.tabID == "rejected") && (
                  <Tab eventKey="rejected" title={"Declined ("+this.state.bids.filter(function(bid) {return bid.bidDetail.state == "Rejected"}).length+")"} className="mx-3">
                    <Row className="pt-4">
                      {this.state.bids.filter(function(bid) {return bid.bidDetail.state == "Rejected" && ( bid.campaignDetail.title.toLowerCase().includes(filter.search.toLowerCase()) || bid.campaignDetail.brand.name.toLowerCase().includes(filter.search.toLowerCase()) )}).map(bid => <this.BidItem key={bid.bidDetail.id} bid={bid} class={this}/>)}
                      <PageScroller endpoint="influencer/myBids" setState={setState} filter={this.props.filters} pageSize={250} />
                    </Row>
                  </Tab>
                  )}
                </Tabs>

              </div>
            </Row>
          </Container>
        </Container>      
      </Container>
      );
    }
  }
}

export default function BidsList() { 
  let [searchParams] = useSearchParams(); 
  var view = searchParams.get('view');
  if (view == null){
    view = "all";
  }

  const [tabID, setTabID] = useState(view);
  const { providers } = useImageContext();
  const [filteredBids, setFilteredBids] = useState([])
  const [filters, setFilters] = useState({"search":"","showInactive":true});

  return (
    <div className="bg-page BidsList">
      <Topbar />
      <div className="pageName">My Bids</div>
      <MyBidsView providers={providers} filteredBids={filteredBids} setFilteredBids={setFilteredBids} filters={filters} setFilters={setFilters} tabID={tabID} setTabID={setTabID} />
      <Footer />
    </div>
  );
}