import React, { Component } from "react";
import { Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { apiCall } from './../../helpers/api'
import { useSearchParams } from "react-router-dom";
import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import theRoom from './../../images/theRoom.svg';
import logoWhite from './../../images/room-white-logo.svg';
import greenTick from './../../images/green-circle-tick.svg';
import warningAlert from './../../images/warning-alert.svg';
import SignupFooter from "../../components/footer/SignupFooter";

class Verify extends Component {
  constructor(props) {
    super(props);
    if (!this.props.userID || !this.props.code){
        this.state = {
            error: true,
            isLoaded: true,
            verificationStatus: "Invalid ID or Code provided."
        };
    }
    else {
        this.state = {
            error: null,
            isLoaded: false,
            verificationStatus: null
          };
          this.verifyEmail()
    }
  }

  async verifyEmail() {
    var response = await apiCall(`/application/verifyEmail?id=${this.props.userID}&code=${this.props.code}`,{ "method" : "GET" });
    if (response["success"]) {
      this.setState({
        verificationStatus : response["success"]["msg"]
      });
    }
    else {
      this.setState({
        error: true,
        verificationStatus: response["failure"]["error"]
    });
    }
    this.setState({
      isLoaded : true
    });
  }

  render() {
      const { error, isLoaded, verificationStatus } = this.state;
      if (!isLoaded) {
        return <FullPageSpinner />;
      } else {
        return (
          <div>
            <Container fluid className="mainPlansSelection emailVerify px-0">
                <Row className='h-100 mx-0'>
                    <div className='col-md-4 col-lg-5 h-100 d-flex align-items-center justify-content-end'>
                        <img src={theRoom} height="80px" className='me-0 pe-0 me-md-3 me-lg-5 pe-md-3 pe-lg-5 position-fixed' />
                    </div>
                    <div className='col-md-8 col-lg-7 bg-white d-flex flex-column align-items-center justify-content-center'>
                        {!error ? (
                          <>
                        <img src={greenTick} height="40px" className='mb-4 mt-5' />
                        <h4 className="text-navy mb-3 fw-bold">Email Confirmed!</h4>
                        <div className="mb-5">
                            <div className="text-center mb-3">
                                <span>Thanks for confirming your email!</span>
                            </div>
                            <p className="text-center small text-muted px-5">
                                You should receive an email from us in the next few days to confirm if your application has been accepted.
                            </p>
                        </div>
                        </>
                        ) : ( 
                          <>
                        <img src={warningAlert} height="40px" className='mb-4 mt-5' />
                        <h4 className="text-navy mb-3 fw-bold">Couldn't Confirm Email</h4>
                        <div className="mb-5">
                            <div className="text-center mb-3">
                                <span>Something wasn't quite right.</span>
                            </div>
                            <p className="text-center small text-muted px-5">
                              Please try clicking the link in your email again, or copying and pasting the URL into your browser. If you are still facing issues, please contact <address>support@theinfluenceroom.com</address>
                            </p>
                        </div>
                        </>
                      )}
                    </div>
                </Row>
            </Container>
          
            <SignupFooter />
          </div>    
        );
      }
    }
  }

export default function (props) {
    let [searchParams] = useSearchParams();
    const userID = searchParams.get('id');
    const code = searchParams.get('code');
    return <Verify userID={userID} code={code} />;
}
  