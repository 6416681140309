import profile from "../images/blank-profile.webp"
import Compressor from 'compressorjs';
import { apiCall } from "./api";

export async function getImageFromUrl(url,name, defaultType = 'image/jpeg'){
    const response = await fetch(url);
    const data = await response.blob();
    const file = new File([data], name, {
        type: data.type || defaultType,
    });
    return {"url":URL.createObjectURL(file),"data":file}
}

export function getImageUrl(providers,image,args=null,downloadable=false,video=false) {
    if (!image || !image.id){
        return profile
    }
    if (image.source == "local"){

    }
    if (image.source == "cloudinary"){
        var url = `${providers.cloudinary.url}/${video ? 'video' : 'image'}/upload/`;
        if (downloadable) {
            url = `${url}fl_attachment/`
        }
        if (args) {
            args = args.join(",")
            return `${url}q_auto,${video ? '' : 'f_auto,'}${args}/${image.id}`
        }
        return `${url}q_auto${video ? '' : ',f_auto'}/${image.id}`
    }
    if (image.source == "v1Bucket"){
        return `https://theroomdata.s3.amazonaws.com/uploads/${image.id}`
    }
}

export function validateImage(image) {
    const allowedFileTypes = ["image/png","image/jpeg","image/jpg","image/webp","image/heic","video/mp4","video/webm"];
    const maxSize = 50000000; //50MB

    if (allowedFileTypes.includes(image.type)){
        if (image.size <= maxSize){
            return [true, ""]
        }
        return [false, "Content is too large (must be 50MB or below)"]
    }
    return [false, "Content is not one of the following: PNG, JPEG, JPG, HEIC, WEBP, MP4, or WEBM"]
}

export async function compressImage(image,qualityRatio=0.8) {
    const compressableFileTypes = ["image/png","image/jpeg","image/jpg","image/webp","image/heic"]
    function compress(image) {
        return new Promise((resolve, reject) => {
            new Compressor(image, {
                quality: qualityRatio,
                success: resolve,
                error: reject
            });
       });
    }
    if (compressableFileTypes.includes(image.type)){
        var compressedImage = await compress(image)
        return compressedImage
    }
    return image
}

export async function clientUploadImage(image,setAlert=null){
    const formData = new FormData();
    formData.append("file",image.data)
    formData.append("public_id",image.metadata.public_id)
    formData.append("api_key",image.metadata.api_key)
    formData.append("timestamp",image.metadata.timestamp)
    formData.append("signature",image.metadata.signature)
    if (image.metadata.folder != ""){
        formData.append("folder",image.metadata.folder)
    }
    var uploadedImage = await fetch(`${image.metadata.target}/${image.metadata.resource_type}/upload`, {
    method: "POST",
    body: formData
    }).then(async response => {
    var responseJSON = {}
    try {
        let responseJSON = await response.json();
        if (response.ok) {
            return {"id":responseJSON.public_id,"source":image.metadata.source,"name":image.data.name,"type":image.metadata.resource_type};
        }
    }
    catch {
        if (setAlert != null){
            setAlert({"display":"toast","visible":true,"title":"Could not update picture","content":JSON.stringify(responseJSON),"className":"bg-danger text-white"})
        }
        return null
    }});
    return uploadedImage;
}

export async function clientUploadMultipleImages(images,setAlert){
    var failedImages = [];
    var successfulImages = [];
    for (const image of images){
        var uploadedImage = await clientUploadImage(image);
        if (uploadedImage != null){
            successfulImages.push(uploadedImage);
        }
        else {
            failedImages.push(images)
        }
    }
    if (failedImages.length > 0){
        setAlert({"display":"toast","visible":true,"title":"Could not upload all images","content":`Failed to upload ${failedImages.length} images`,"className":"bg-danger text-white"})
        return null
    }
    else {
        return successfulImages
    }
}

export async function updateImage(props,setTarget,loadingTarget,setAlert,folder="profilePictures"){
    loadingTarget(true)
    var file = props.target.files[0];
    var [validationResult, validationReason] = validateImage(file)
    if (validationResult){
        file = await compressImage(file)
        var tempFile = {"url":URL.createObjectURL(file),"data":file,"metadata":null,"type":"image"}
        var response = await apiCall(`getUploadSignature?folder=${folder}&asset_type=${tempFile.type}`,{ "method" : "GET" });
        if (response["success"]) {
            tempFile.metadata = response["success"]["metadata"]
            setTarget(tempFile)
        }
        else {
        setAlert({"display":"toast","visible":true,"title":"Could Not Recognise Image","content":response["failure"]["error"],"className":"bg-danger text-white"})
        }
    }
    else {
        setAlert({"display":"toast","visible":true,"title":"Invalid Image","content":validationReason,"className":"bg-danger text-white"})
    }
    loadingTarget(false)
    return null
}