import React, { useEffect, useState } from "react";
import { Container, Col, Row, Button, Dropdown, DropdownButton, Modal, Badge, Form, Table, InputGroup } from 'react-bootstrap';
import AdminNav from '../../components/adminnav/AdminNav';
import { useImageContext } from "../../hooks/useImageContext";
import { useAuthContext } from "../../hooks/useAuthContext";
import { getImageUrl } from "../../helpers/images";
import { apiCall, PageScroller } from "../../helpers/api";
import { getFilter, setFilter } from './../../helpers/filters'
import AlertModal from "../../components/alerts/alert";
import { getIndustries, getCountries } from "../../helpers/dropdowns";
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import AsyncSelect, { useAsync } from 'react-select/async';

import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import './Brands.css';
import Topbar from "../../components/topbar/Topbar";
import filter from './../../images/filter.svg'
import searchBlue from './../../images/search-blue.svg'
import settings from './../../images/settings.svg';

export default function Brands() {  
  const [isLoaded, setIsLoaded] = useState(true);
  const { providers } = useImageContext();
  const [ activeButton, setActiveButton ] = useState(null);
  const { currentProfile } = useAuthContext();
  const [brands, setBrands] = useState([]);
  const [newBrandData, setNewBrandData] = useState({
    "name":"",
    "website":"",
    "industry":"",
    "contact":"",
    "country":"",
    "email":"",
    "active":true,
    "sendPasswordReset":true,
    "createUser":false,
    "password":"",
    "passwordConfirm":"",
    "firstName":"",
    "lastName":""
  })
  const [showNewBrandModal, setShowNewBrandModal ] = useState(false)
  const [brandFilters, setBrandFilters] = useState({"states":[],"subscriptions":[],"managers":[],"industries":[],"search":""});
  const [showAlert, setAlert] = useState({"display":"toast","visible":false,"title":"","content":""});
  const hideAlert = () => setAlert({"display":"toast","visible":false,"title":"","content":""});
  const [pageStats, setPageStats] = useState({})
  const industries = getIndustries()
  const countries = getCountries()
  const [updateCounter, setUpdateCounter] = useState(0)
  const animatedComponents = makeAnimated();
  const [availableManagers, setAvailableManagers] = useState([])

  useEffect(() => {
    if (getFilter("brand")) {
      setBrandFilters(JSON.parse(getFilter("brand")))
    }
  }, []);

  const loginAsBrand = async (brandID) => {
    var response = await apiCall(`impersonate`,{ "method" : "POST", "data" : {"id":brandID,"type":"brands","returnPage":window.location.pathname+window.location.search} });
    if (response["success"]){
      window.location.href="/Campaigns";
    }
    else {
      setAlert({"display":"toast","visible":true,"title":"Failed to login as brand","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
  };

  const downloadBrands = async () => {
    setActiveButton("downloadBrands")
    var response = await apiCall(`admin/brandListDownload`,{ "method" : "POST", "data" : {"filter": brandFilters}, isFile:true });
    if (response["success"]){
      setAlert({"display":"toast","visible":true,"title":"Influencer List Downloaded","content":"Please check your downloads folder","className":"bg-success text-white"})
    }
    else {
      setAlert({"display":"toast","visible":true,"title":"Failed to download influencer list","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
    setActiveButton(null)
  }

  const disableBrand = async (brandID) => {
    var response = await apiCall(`admin/disableUser`,{ "method" : "POST", "data" : {"id":brandID,"type":"brand"} });
    if (response["success"]){
      setAlert({"display":"toast","visible":true,"title":"Brand disabled","content":"Brand successfully disabled","className":"bg-success text-white"})
      for (var x in brands) {
        if (brands[x]["_id"] === brandID) {
          brands[x]["active"] = false;
          setBrands([...brands])
          break
        }
      }
    }
    else {
      setAlert({"display":"toast","visible":true,"title":"Failed to disable brand","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
  }

  const enableBrand = async (brandID) => {
    var response = await apiCall(`admin/enableUser`,{ "method" : "POST", "data" : {"id":brandID,"type":"brand"} });
    if (response["success"]){
      setAlert({"display":"toast","visible":true,"title":"Brand enabled","content":"Brand successfully enabled","className":"bg-success text-white"})
      for (var x in brands) {
        if (brands[x]["_id"] === brandID) {
          brands[x]["active"] = true;
          setBrands([...brands])
          break
        }
      }
    }
    else {
      setAlert({"display":"toast","visible":true,"title":"Failed to enable brand","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
  }

  function updateNewBrandData(props){
    var tempBrandData = newBrandData;
    if (["active"].includes(props.target.id)){
        if (props.target.value == 1){
          tempBrandData[props.target.id] = true
        }
        else {
          tempBrandData[props.target.id] = false
        }
    }
    else { 
      tempBrandData[props.target.id] = props.target.value
    }
    setNewBrandData(tempBrandData)
    setUpdateCounter(updateCounter+1)
  }

  async function getBrandManagers(managerInput) {
    var response = await apiCall(`admin/brandManagers?search=${managerInput}`,{ "method" : "GET" });
    var tempManagers = structuredClone(availableManagers)
    if (response["success"]){
      var managers = []
      response["success"]["managers"].forEach(manager => {
        managers.push({"label":`${manager.firstName} ${manager.lastName}`,"value":manager._id})
        tempManagers[manager._id] = `${manager.firstName} ${manager.lastName}`
      })
      setAvailableManagers(tempManagers)
      return managers
    }
  }

  async function submitNewBrand(){
    var response = await apiCall('brand',{ "method" : "POST", "data" : newBrandData });
    if (response["success"]){
        setAlert({"display":"toast","visible":true,"title":"Brand Created","content":`${newBrandData.name} Created Successfully`,"className":"bg-success text-white"})
        setShowNewBrandModal(false)
        setNewBrandData({
          "name":"",
          "website":"",
          "industry":"",
          "phoneNumber":"",
          "country":"",
          "email":"",
          "active":true,
          "sendPasswordReset":true,
          "createUser":false,
          "password":"",
          "passwordConfirm":"",
          "firstName":"",
          "lastName":""
        })
    }
    else if (response["failure"]) {
        setAlert({"display":"toast","visible":true,"title":"Could not create brand","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
  }

  const handleFilterUpdate = (value,added,type,delay=0) => {
    var filters = brandFilters;
    if (type == "reset"){
      filters = {"states":[],"subscriptions":[],"managers":[],"industries":[],"search":""}
    }
    else if (type == "states"){
      if (added) { filters.states.push(value) }
      else { filters.states = brandFilters.states.filter(function(state) { return state != value })}
    }
    else if (type == "subscriptions"){
      if (added) { filters.subscriptions.push(value) }
      else { filters.subscriptions = brandFilters.subscriptions.filter(function(subscription) { return subscription != value })}
    }
    else if (type == "managers") {
      filters.managers = value.map(manager => manager.value)
    }
    else if (type == "industries") {
      if (added) { filters.industries.push(value) }
      else { filters.industries = brandFilters.industries.filter(function(industry) { return industry != value })}
    }
    else if (type == "search") {
      filters.search = value.target.value.toLowerCase();
    }
    setBrandFilters(Object.assign({}, brandFilters, filters));
    setFilter(filters,"brand")
  }

  const setState = async (event,result) => { 
    result = await result;
    if (event.pageData.page >= 1) {
        for (var x in result["brands"]) {
            brands.push(result["brands"][x])  
        }
        setBrands([...brands])
    } else {
      setBrands(result["brands"])
    }
    setPageStats(result["_paging"])
  }
  
    return (
      <div className="bg-page brands">
      <Topbar />
      <div className="pageName">Brands</div>
      <Container id="applicant-list-container" className="fullscreenWithTopBar top-0" fluid>
        <AlertModal modal={showAlert} onClose={hideAlert} />
        <Row>
          <AdminNav />
          <div className="col-xl-12 col-xxl-10 px-0">
            <div className="ps-4 py-3 py-xl-4 pe-0 bg-white shadow-sm">
              <div className="d-flex align-items-center justify-content-between mb-lg-4 ms-xl-4">
                <h1 className="pageTitle text-nowrap text-primary tragedyRegularFont fw-700 mb-0 fs-3">Brands</h1>
                <div className="form-group d-flex">
                  {currentProfile.type === "admins" &&
                    <>
                    <Button variant="outline-primary" size="sm" className="text-nowrap me-2 me-md-3" onClick={() => setShowNewBrandModal(true)}>Add <span className='d-none d-sm-inline'>Brand</span></Button>
                    <Button variant="outline-primary" size="sm" disabled={activeButton == "downloadBrands"} className="desktopFlex text-nowrap me-2 me-md-3" onClick={() => downloadBrands()}>Download</Button>
                    </>
                  }
                  <InputGroup size="sm" className="me-3 desktopFlex">
                    <Form.Control placeholder="Search" className="border-end-0 form-control-sm" aria-label="Search" defaultValue={brandFilters.search} onChange={e => handleFilterUpdate(e,true,"search",500)}/>
                    <InputGroup.Text className="bg-white border-end">
                      <a><img src={searchBlue} /></a>
                    </InputGroup.Text>
                  </InputGroup>
                  <div className="bg-gray pb-1 pt-1 ps-2 pe-3 rounded-start text-nowrap pageDataCount">
                    <span className="fw-500 me-1">Total:</span><span> {pageStats.total} Brands</span>
                    </div>
                  </div>
                </div>
                
                {showNewBrandModal && (
                  <Modal show backdrop="static" centered >
                  <Modal.Header>
                    <Modal.Title className="h5">
                      Add New Brand
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="pb-0">
                    <Row>
                      <Col xs={12} sm={12} md={12}>
                        <Form.Group className="mb-3">
                            <Form.Label className="mb-1">Name</Form.Label>
                            <Form.Control type="name" id="name" value={newBrandData.name} onChange={(e) => updateNewBrandData(e)} className="" />
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={12} md={6}>
                        <Form.Group className="mb-3">
                          <label>Primary Industry</label>
                          <Select
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            defaultValue={[{label:newBrandData.industry,value:newBrandData.industry}]}
                            options={industries} 
                            id="industry" 
                            onChange={ (choice) => updateNewBrandData({"target":{"value":choice.value,"id":"industry"}}) } 
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={12} md={6}>
                        <Form.Group className="mb-3">
                          <label>Country</label>
                          <Select
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            defaultValue={[{label:newBrandData.country,value:newBrandData.country}]}
                            options={countries} 
                            id="country" 
                            onChange={ (choice) => updateNewBrandData({"target":{"value":choice.value,"id":"country"}}) } 
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={12} md={12}>
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-1">Website</Form.Label>
                          <Form.Control type="URL" id="website" value={newBrandData.website} onChange={(e) => updateNewBrandData(e)} />
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={12} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-1">Status</Form.Label>
                          <Form.Select id="active" onChange={(e) => updateNewBrandData(e)}>
                            <option selected={newBrandData.active == true} value="1">Active</option>
                            <option selected={newBrandData.active == false} value="0">Inactive</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={12} md={6}>
                        <Form.Group className="mb-3 pt-md-4 mt-md-2">
                          <Form.Label>
                            <input type="checkbox" id="createUser" className="me-1" checked={newBrandData.createUser} onChange={(e) => updateNewBrandData({"target":{"value":e.target.checked,"id":e.target.id}})} /> Create User
                          </Form.Label>
                        </Form.Group>
                      </Col>
                  </Row>
                  {newBrandData.createUser && (
                    <>
                    <Row>
                      <Col xs={12} sm={12} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-1">First Name</Form.Label>
                          <Form.Control type="name" id="firstName" value={newBrandData.firstName} onChange={(e) => updateNewBrandData(e)} />
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={12} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-1">Last Name</Form.Label>
                          <Form.Control type="name" id="lastName" value={newBrandData.lastName} onChange={(e) => updateNewBrandData(e)} />
                          </Form.Group>
                      </Col>
                      <Col xs={12} sm={12} md={12}>
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-1">Email</Form.Label>
                          <Form.Control type="email" id="email" value={newBrandData.email} onChange={(e) => updateNewBrandData(e)} />
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={12} md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-1">Contact Number</Form.Label>
                          <Form.Control type="number" id="phoneNumber" value={newBrandData.phoneNumber} onChange={(e) => updateNewBrandData(e)} />
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={12} md={6}>
                        <Form.Group className="mb-3 pt-md-4 mt-md-2">
                          <Form.Label>
                            <input type="checkbox" className="me-1" id="sendPasswordReset" checked={newBrandData.sendPasswordReset} onChange={(e) => updateNewBrandData({"target":{"value":e.target.checked,"id":e.target.id}})} /> Send Password Reset
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      {!newBrandData.sendPasswordReset && (<>
                          <Col xs={12} sm={12} md={6}>
                              <Form.Group className="mb-3">
                                  <Form.Label className="mb-1">New Password</Form.Label>
                                  <Form.Control type="password" id="password" value={newBrandData.password} onChange={(e) => updateNewBrandData(e)} />
                              </Form.Group>
                          </Col>
                          <Col xs={12} sm={12} md={6}>
                              <Form.Group className="mb-3">
                                  <Form.Label className="mb-1">Confirm Password</Form.Label>
                                  <Form.Control type="password" id="passwordConfirm" value={newBrandData.passwordConfirm} onChange={(e) => updateNewBrandData(e)} />
                              </Form.Group>
                          </Col>
                      </>)}
                    </Row>
                    </>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="outline-secondary" size="sm" onClick={()=>{setShowNewBrandModal(false);  setNewBrandData({"name":"","website":"","industry":"","phoneNumber":"","country":"","email":"","active":true,"sendPasswordReset":true,"createUser":false,"password":"","passwordConfirm":"","firstName":"","lastName":""})}}>Cancel</Button>
                    <Button disabled={(newBrandData.password != newBrandData.passwordConfirm && !newBrandData.sendPasswordReset) || ["name","industry"].filter(function(field) {return newBrandData[field] == ""}).length > 0 || newBrandData.password == "" && !newBrandData.sendPasswordReset} onClick={() => submitNewBrand()} variant="success" size="sm">Submit</Button>
                  </Modal.Footer>
                </Modal>
                )}

                <div className="desktopFlex align-items-center mx-xl-4 filters">
                  <div className="fw-500 me-2 smaller">Filter:</div>
                  <div className="form-group me-2">
                    <DropdownButton size="sm" variant="light" title="Account status">
                      <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                        <input type="checkbox" className="form-check-input me-2 mb-2" checked={brandFilters.states.includes(true)} onChange={(e) => handleFilterUpdate(true,e.target.checked,"states")} />
                        <label title="" className="form-check-label">Active</label>
                      </div>
                      <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                        <input type="checkbox" className="form-check-input me-2 mb-2" checked={brandFilters.states.includes(false)} onChange={(e) => handleFilterUpdate(false,e.target.checked,"states")} />
                        <label title="" className="form-check-label">Disabled</label>
                      </div>
                    </DropdownButton>
                  </div>
                  <div className="form-group me-2">
                    <DropdownButton size="sm" variant="light" title="Subscription type">
                      <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                        <input type="checkbox" className="form-check-input me-2 mb-2" checked={brandFilters.subscriptions.includes("self serve")} onChange={(e) => handleFilterUpdate("self serve",e.target.checked,"subscriptions")} />
                        <label title="" className="form-check-label">Self Serve</label>
                      </div>
                      <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                        <input type="checkbox" className="form-check-input me-2 mb-2" checked={brandFilters.subscriptions.includes("lite")} onChange={(e) => handleFilterUpdate("lite",e.target.checked,"subscriptions")} />
                        <label title="" className="form-check-label">Lite</label>
                      </div>
                      <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                        <input type="checkbox" className="form-check-input me-2 mb-2" checked={brandFilters.subscriptions.includes("pro")} onChange={(e) => handleFilterUpdate("pro",e.target.checked,"subscriptions")} />
                        <label title="" className="form-check-label">Pro</label>
                      </div>
                      <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                        <input type="checkbox" className="form-check-input me-2 mb-2" checked={brandFilters.subscriptions.includes("enterprise")} onChange={(e) => handleFilterUpdate("enterprise",e.target.checked,"subscriptions")} />
                        <label title="" className="form-check-label">Enterprise</label>
                      </div>
                    </DropdownButton>
                  </div>
                  <div className="form-group me-2">
                    <DropdownButton size="sm" variant="light" title="Managed by">
                      <AsyncSelect 
                        cacheOptions
                        defaultOptions
                        placeholder="Manager"
                        closeMenuOnSelect={false}
                        isMulti
                        value={brandFilters.managers.map(manager => ({"value":manager,"label":availableManagers[manager]}))}
                        loadOptions={getBrandManagers}
                        onChange={(choice) => handleFilterUpdate(choice,null,"managers")}
                      />
                    </DropdownButton>
                  </div>
                  <div className="form-group me-2">
                    <DropdownButton size="sm" variant="light" title="Industry">
                      {industries.map(industry => (
                        <div key={industry.value} className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                          <input type="checkbox" className="form-check-input me-2 mb-2" checked={brandFilters.industries.includes(industry.value)} onChange={(e) => handleFilterUpdate(industry.value,e.target.checked,"industries")} />
                          <label title="" className="form-check-label">{industry.label}</label>
                        </div>
                      ))}
                    </DropdownButton>
                  </div>
                  {/* <div className="form-group me-3">
                    <a className="form-control form-control-sm small">
                      More Filters <img src={filter} className="ms-2" width="15px" />
                    </a>
                  </div> */}
                  <a className="text-danger small" onClick={(e) => handleFilterUpdate(null,null,"reset")}><u>Reset</u></a>
                </div>
              </div>

              { !isLoaded ? <FullPageSpinner /> : (        
                <div className="p-4 pt-0 brandsTable mt-2 mt-xl-4 pb-0">
                  <div className="mx-xl-4">
                    <div className="tableHeaderFixedBelt"></div>
                    <Table className="border-transparent">
                      <thead>
                        <tr>
                          <th>Brand</th>
                          <th>Industry</th>
                          <th>Subscription Type</th>
                          <th>Managed By</th>
                          <th>Published Campaigns</th>
                          <th>Contract Ends</th>
                          <th className="text-center">Country</th>
                          <th className="text-nowrap">Log in as brand</th>
                        </tr>
                      </thead>
                      <tbody className="small text-nowrap">
                        {brands.sort((a, b) => a.name > b.name ? 1 : -1).map(brand => (
                        <tr key={brand._id}>
                          <td className="text-nowrap"><div className="d-flex align-items-center"><img src={getImageUrl(providers,brand.profileImage,["w_80","c_scale"])} width="36px" height="36px" className="object-fit-cover rounded me-1 border-gray p-1" /> <div className="text-truncate w-75">{brand.name}</div></div></td>
                          <td>
                            <div className="d-flex text-truncate">
                              <div className="me-3">
                                  <span className="tags text-nowrap">{brand.industry}</span>
                              </div>
                            </div>
                          </td>
                          <td><span className="tags text-nowrap">{brand.subscription.package ? brand.subscription.package : "N/A"}</span></td>
                          <td><span className="tags text-nowrap">{brand.manager ? brand.manager : "N/A"}</span></td>
                          <td><span className="tags text-nowrap">{brand.stats.publishedCampaigns}/{brand.stats.totalCampaigns}</span></td>
                          <td><span className="tags text-nowrap">N/A</span></td>
                          <td className="bg-white text-center"><img src={`https://hatscripts.github.io/circle-flags/flags/${brand.country.toLowerCase()}.svg`} className="border rounded-circle me-2" width="26px" /></td>
                          <td>
                            <div className="d-flex align-items-center justify-content-between">
                              <button type="button" onClick={e => loginAsBrand(brand._id)} className="btn btn-sm btn-outline-primary">Login</button>
                              <Dropdown className="brandAction">
                                <Dropdown.Toggle variant="default" className="border-0">
                                  <img src={settings} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item href={`Brands/Settings?id=${brand._id}`}>Edit</Dropdown.Item>
                                  {currentProfile.type == "admins" && <>{brand.active ? <Dropdown.Item className="dropdown-item" onClick={e => disableBrand(brand._id)}>Disable</Dropdown.Item> : <Dropdown.Item className="dropdown-item" onClick={e => enableBrand(brand._id)}>Enable</Dropdown.Item>}</>}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </td>
                        </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <PageScroller endpoint="brands" setState={setState} filter={brandFilters} pageSize={100} />
                </div>
              ) }
            </div>
          </Row>
        </Container>
      </div>
    );
}