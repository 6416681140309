import { Component } from "react";
import PropTypes from "prop-types";
import { apiCall, PageScroller } from "../../helpers/api";
import { getDifference } from "../../helpers/dates";
import './Diagnosis.css'

export class Diagnosis extends Component {
    static propTypes = {
    }
    constructor(props) {
        super(props);
        this.state = {
            campaignID: "",
            campaignDiagnostics: {"raw":{},"bids":[], "dyzio": {}},
            loadingActions: [],
            errors: null,
            tab: "campaign",
            collection: "account",
            search: '{"email":""}',
            searchData: {"data":[],"fields":[]},
        }
    }

    componentDidMount(){
        if (this.state.campaignID) {
            this.getCampaignDiagnostics()
        }
    }

    async getCampaignDiagnostics(){
        this.state.loadingActions.push(this.state.campaignID)
        this.setState({loadingActions: [...this.state.loadingActions]})
        var response = await apiCall(`diagnostics/campaign?id=${this.state.campaignID}`,{ "method" : "GET" });
        if (response["success"]){
            this.setState({campaignDiagnostics: response["success"]})
        }
        this.state.loadingActions.splice(this.state.loadingActions.indexOf(this.state.campaignID),1)
        this.setState({loadingActions: [...this.state.loadingActions]})
    }

    async syncInfluencer(influencerID){
        if (!this.state.loadingActions.includes(influencerID)){
            this.state.loadingActions.push(influencerID)
            this.setState({loadingActions: [...this.state.loadingActions]})
            var response = await apiCall(`diagnostics/syncInfluencer?id=${influencerID}`,{ "method" : "POST" });
            var tempCampaignDiagnostics = structuredClone(this.state.campaignDiagnostics)
            if (response["success"]){
                for (const bid in tempCampaignDiagnostics.bids){
                    if (tempCampaignDiagnostics.bids[bid]["bidderID"] == influencerID){
                        tempCampaignDiagnostics.bids[bid]["output"] = response["success"]["message"]
                        tempCampaignDiagnostics.bids[bid]["influencer"]["meta"]["lastUpdate"] = response["success"]["data"]["time"]
                    }
                }
            }
            else {
                for (const bid in tempCampaignDiagnostics.bids){
                    if (tempCampaignDiagnostics.bids[bid]["bidderID"] == influencerID){
                        tempCampaignDiagnostics.bids[bid]["output"] = response["failure"]
                    }
                }
            }
            this.state.loadingActions.splice(this.state.loadingActions.indexOf(influencerID),1)
            this.setState({loadingActions: [...this.state.loadingActions], campaignDiagnostics: tempCampaignDiagnostics})
        }
    }

    async syncCampaign(){
        if (!this.state.loadingActions.includes(this.state.campaignID)){
            this.state.loadingActions.push(this.state.campaignID)
            this.setState({loadingActions: [...this.state.loadingActions]})
            var response = await apiCall(`diagnostics/syncCampaign?id=${this.state.campaignID}`,{ "method" : "POST" });
            if (response["success"]){
                this.getCampaignDiagnostics()
            }
            else {
                this.setState({errors: response["failure"]})
            }
            this.state.loadingActions.splice(this.state.loadingActions.indexOf(this.state.campaignID),1)
            this.setState({loadingActions: [...this.state.loadingActions]})
        }
    }

    async searchCollection(){
        if (!this.state.loadingActions.includes(this.state.campaignID)){
            this.state.loadingActions.push(this.state.campaignID)
            this.setState({loadingActions: [...this.state.loadingActions]})
            var response = await apiCall(`diagnostics/collectionSearch`,{ "method" : "POST", "data" : {"collection": this.state.collection, "search": this.state.search} });
            if (response["success"]){
                this.setState({searchData: response["success"]})
            }
            else {
                console.log(response["failure"])
            }
            this.state.loadingActions.splice(this.state.loadingActions.indexOf(this.state.campaignID),1)
            this.setState({loadingActions: [...this.state.loadingActions]})
        }
    }

    render(){
        return (
            <div className="DiagnosticsPage">
                <ul>
                <li className={`DiagnosticsSelection ${this.state.tab == "campaign" ? 'Selected' : ''}`} onClick={() => this.setState({tab: "campaign"})}>Campaign Diagnostics</li>
                <li className={`DiagnosticsSelection ${this.state.tab == "search" ? 'Selected' : ''}`} onClick={() => this.setState({tab: "search"})}>Search Tool</li>
                </ul>
                {this.state.tab == "campaign" &&
                (
                    <div>
                        <h1>Campaign Diagnostics</h1>
                        <div>
                        <input value={this.state.campaignID} onChange={(e) => {this.setState({campaignID: e.target.value})}} />
                        <button disabled={this.state.loadingActions.includes(this.state.campaignID)} onClick={() => this.getCampaignDiagnostics()}>Retrieve</button>
                        <button disabled={this.state.loadingActions.includes(this.state.campaignID)} onClick={() => this.syncCampaign()}>Sync Campaign</button>
                        </div>
                        <div className={"CampaignDiagnostics"}>
                            <div>
                                <h3>Dyzio</h3>
                                {this.state.errors && (
                                    <div>Errors: {JSON.stringify(this.state.errors)}</div>
                                )}
                                <pre>{JSON.stringify(this.state.campaignDiagnostics.dyzio, null, 4)}</pre>
                            </div>
                            <div>
                            <h3>Campaign Bids</h3>
                                <table>
                                    <thead>
                                        <th>ID</th>
                                        <th>Influencer ID</th>
                                        <th>Bid State</th>
                                        <th>Email</th>
                                        <th>Appears on Dyzio Campaign</th>
                                        <th>Last Dyzio Sync</th>
                                        <th>Dyzio ID</th>
                                        <th>Actions</th>
                                        <th>Output</th>
                                    </thead>
                                    <tbody>
                                        {this.state.campaignDiagnostics.bids.map(bid => (
                                            <tr key={bid.id}>
                                                <td>{bid.id}</td>
                                                <td>{bid.bidderID}</td>
                                                <td>{bid.state}</td>
                                                <td>{bid.influencer ? bid.influencer.email : "Unknown"}</td>
                                                <td>{bid.influencer ? bid.influencer.inDyzioCampaign ? "Yes" : "No" : "No"}</td>
                                                <td>{bid.influencer ?  `${getDifference(bid.influencer.meta.lastUpdate).difference} ${getDifference(bid.influencer.meta.lastUpdate).unit} ago` : "Unknown"}</td>
                                                <td>{bid.influencer ? bid.influencer.meta.id : "Unknown"}</td>
                                                <td><button disabled={this.state.loadingActions.includes(bid.bidderID)} onClick={() => this.syncInfluencer(bid.bidderID)}>Sync</button></td>
                                                <td>{bid.output ? JSON.stringify(bid.output, null, 4) : ""}</td>
                                                {/* <td>{JSON.stringify(bid, null, 4)}</td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <h3>Raw</h3>
                        <div>
                            <pre>{JSON.stringify(this.state.campaignDiagnostics.raw, null, 4)}</pre>
                        </div>
                    </div>
                )}
                {this.state.tab == "search" && (
                    <div>
                        <h1>Data Search Tool</h1>
                        <div>
                            <select onChange={(e) => this.setState({collection: e.target.value})} value={this.state.collection}>
                                <option value="account">Accounts</option>
                                <option value="agency">Agency</option>
                                <option value="application">Applications</option>
                                <option value="brand">Brands</option>
                                <option value="campaign">Campaigns</option>
                                <option value="community">Communities</option>
                                <option value="dashboard">Dashboards</option>
                                <option value="influencer">Influencers</option>
                                <option value="profile">Profiles</option>
                                <option value="referral">Referrals</option>
                                <option value="social">Socials</option>
                            </select>
                            <textarea placeholder={"{}"} value={this.state.search} onChange={(e) => this.setState({search: e.target.value})} />
                            <button onClick={() => this.searchCollection()}>Search</button>
                        </div>
                        <div>
                            {this.state.searchData.data.length > 0 && (
                                <table>
                                    <thead>
                                        {this.state.searchData.fields.map(field => (
                                            <th key={field}>{field}</th>
                                        ))}
                                    </thead>
                                    <tbody>
                                        {this.state.searchData.data.map(item => (
                                            <tr key={item._id}>
                                                {this.state.searchData.fields.map(field => (
                                                    <td key={`${field}-${item._id}`}>{JSON.stringify(item[field])}</td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                )}
            </div>

        )
    }
}

export default function DiagnosticsPage() {
    return (
        <>
            <Diagnosis  />
        </>
    )
}