import React, { Component, useState, useEffect } from "react";
import { Container, Col, Row, Dropdown, DropdownButton } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { apiCall, PageScroller } from './../../helpers/api'
import { getFilter, setFilter } from './../../helpers/filters'
import AdminNav from '../../components/adminnav/AdminNav';
import AlertModal from "../../components/alerts/alert";

import settings from './../../images/settings.svg';
import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import './Users.css';
import Topbar from "../../components/topbar/Topbar";

import filter from './../../images/filter.svg'
import searchBlue from './../../images/search-blue.svg'

export default function Users(props) {
    const [showNewUserModal, setNewUserModal] = useState(false);
    const [showDeleteUserModal, setDeleteUserModal] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null)
    const [newUserData, setNewUserData] = useState({
        "firstName":"",
        "lastName":"",
        "email":"",
        "active":true,
        "sendPasswordReset":true,
        "password":"",
        "passwordConfirm":"",
        "isAdmin":false
    })
    const [paging, setPaging] = useState({})
    const [users, setUsers] = useState([])
    const [error, setError] = useState(null)
    const [updateCounter, setUpdateCounter] = useState(0)
    const [showAlert, setAlert] = useState({"display":"toast","visible":false,"title":"","content":""});
    const [userFilters, setUserFilters] = useState({"states":[],"lastLogin":"","admins":[],"search":""});

    useEffect(() => {
        if (getFilter("account")) {
            setUserFilters(JSON.parse(getFilter("account")))
        }
      }, []);

    function updateNewUserData(props){
        var tempUserData = newUserData;
        if (["isAdmin","active"].includes(props.target.id)){
            if (props.target.value == 1){
                tempUserData[props.target.id] = true
            }
            else {
                tempUserData[props.target.id] = false
            }
        }
        else { 
            tempUserData[props.target.id] = props.target.value
        }
        setNewUserData(tempUserData)
        setUpdateCounter(updateCounter+1)
    }

    async function submitNewUser(){
        var response = await apiCall('admin/account',{ "method" : "POST", "data" : newUserData });
        if (response["success"]){
            setAlert({"display":"toast","visible":true,"title":"User Created","content":`User account created for ${newUserData.firstName} ${newUserData.lastName} successfully`,"className":"bg-success text-white"})
            setNewUserModal(false)
            setNewUserData({
                "firstName":"",
                "lastName":"",
                "email":"",
                "active":true,
                "sendPasswordReset":true,
                "password":"",
                "passwordConfirm":"",
                "isAdmin":false
            })
        }
        else if (response["failure"]) {
            setAlert({"display":"toast","visible":true,"title":"Could not create user","content":response["failure"]["error"],"className":"bg-danger text-white"})
        }
    }  
    
    const handleFilterUpdate = (value,added,type) => {
        var filters = userFilters;
        if (type == "reset"){
            filters = ({"states":[],"lastLogin":"","admins":[],"search":""})
        }
        else if (type == "states"){
            if (added) { filters.states.push(value) }
            else { filters.states = userFilters.states.filter(function(state) { return state != value })}
        }
        else if (type == "lastLogin"){
            if (added) { filters.lastLogin = value }
            else { filters.lastLogin = "" }
        }
        else if (type == "admins"){
            if (added) { filters.admins.push(value) }
            else { filters.admins = userFilters.admins.filter(function(admin) { return admin != value })}
        }
        else if (type == "search") {
            filters.search = value.target.value.toLowerCase();
        }
        setUserFilters(Object.assign({}, userFilters, filters));
        setFilter(filters,"account")
    }

    const disableUser = async (user,reverse=false) => {
        var response;
        if (reverse){
            response = await apiCall('admin/enableUser',{ "method" : "POST", "data" : {id: user._id, type: "account"} });
            if (response["success"]){
                setAlert({"display":"toast","visible":true,"title":"User Enabled","content":`${user.firstName} ${user.lastName} enabled successfully`,"className":"bg-success text-white"})
            }
            else if (response["failure"]) {
                setAlert({"display":"toast","visible":true,"title":"Could not enable user","content":response["failure"]["error"],"className":"bg-danger text-white"})
            }
        }
        else {
            response = await apiCall('admin/disableUser',{ "method" : "POST", "data" : {id: user._id, type: "account"} });
            if (response["success"]){
                setAlert({"display":"toast","visible":true,"title":"User Disabled","content":`${user.firstName} ${user.lastName} disabled successfully`,"className":"bg-success text-white"})
            }
            else if (response["failure"]) {
                setAlert({"display":"toast","visible":true,"title":"Could not disable user","content":response["failure"]["error"],"className":"bg-danger text-white"})
            }
        }
        handleFilterUpdate(null,null,null)
    }

    const deleteUser = async (user) => {
        var response = await apiCall(`admin/account?id=${user._id}`,{ "method" : "DELETE" });
        if (response["success"]){
            setAlert({"display":"toast","visible":true,"title":"User Deleted","content":`${user.firstName} ${user.lastName} deleted successfully`,"className":"bg-success text-white"})
        }
        else if (response["failure"]) {
            setAlert({"display":"toast","visible":true,"title":"Could not delete user","content":response["failure"]["error"],"className":"bg-danger text-white"})
        }
        handleFilterUpdate(null,null,null)
        setDeleteUserModal(false)
    }

    const setState = async (event,result) => { 
        result = await result;
        if (event.pageData.page >= 1) {
            for (var x in result["accounts"]) {
                users.push(result["accounts"][x])  
            }
            setUsers(users)
        } else {
            setUsers(result["accounts"])
        }
        setPaging(result["_paging"])
      }

    if (error) {
    return <div>Error: {error.message}</div>;
    } else {
        return (
            <div className="bg-page pendingApplication">
                <Topbar />
                <div className="pageName">Users</div>
                <AlertModal modal={showAlert} onClose={() => setAlert({"display":"toast","visible":false,"title":"","content":""})} />
                <Container id="applicant-list-container" className="fullscreenWithTopBar top-0" fluid>
                    <Row>
                        <AdminNav />
                        <div className="col-xl-12 col-xxl-10 px-0">
                            <div className="ps-4 py-3 py-xl-4 pe-0 bg-white shadow-sm">
                                <div className="d-flex align-items-center justify-content-between mb-lg-4 ms-xl-4">
                                    <h1 className="pageTitle text-nowrap text-primary tragedyRegularFont fw-700 mb-0 fs-3">Users</h1>
                                    <div className="form-group d-flex">
                                        <Button variant="outline-primary" size="sm" className="text-nowrap me-2 me-md-3" onClick={() => setNewUserModal(true)}>Add <span className='d-none d-sm-inline'>User</span></Button>
                                        <InputGroup size="sm" className="me-3 d-none d-md-flex">
                                            <Form.Control placeholder="Search" className="border-end-0 form-control-sm" aria-label="Search" defaultValue={userFilters.search} onChange={e => handleFilterUpdate(e,true,"search")}/>
                                            <InputGroup.Text className="bg-white border-end">
                                                <a><img src={searchBlue} /></a>
                                            </InputGroup.Text>
                                        </InputGroup>
                                        <div className="bg-gray pb-1 pt-1 ps-2 pe-3 rounded-start text-nowrap pageDataCount">
                                            <span className="fw-500 me-1">Total: </span><span>{paging.total} Users</span>
                                        </div>
                                    </div>

                                    {showNewUserModal && (
                                    <Modal show backdrop="static" centered >
                                        <Modal.Header className="pe-4">
                                            <Modal.Title className="h5">
                                            Add New User
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="pb-0">
                                            <Row>
                                                <Col xs={12} sm={12} md={6}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="mb-1">First Name</Form.Label>
                                                        <Form.Control type="name" id="firstName" value={newUserData.firstName} onChange={(e) => updateNewUserData(e)} className="" />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} sm={12} md={6}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="mb-1">Last Name</Form.Label>
                                                        <Form.Control type="name" id="lastName" value={newUserData.lastName} onChange={(e) => updateNewUserData(e)} className="" />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} sm={12} md={12}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="mb-1">Email</Form.Label>
                                                        <Form.Control type="email" id="email" value={newUserData.email} onChange={(e) => updateNewUserData(e)} className="" />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} sm={12} md={6}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="mb-1">Status</Form.Label>
                                                        <Form.Select className="" id="active" onChange={(e) => updateNewUserData(e)}>
                                                            <option selected={newUserData.active == true} value="1">Active</option>
                                                            <option selected={newUserData.active == false} value="0">Inactive</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} sm={12} md={6}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="mb-1">Admin</Form.Label>
                                                        <Form.Select className="" id="isAdmin" onChange={(e) => updateNewUserData(e)}>
                                                            <option selected={newUserData.isAdmin == true} value="1">Yes</option>
                                                            <option selected={newUserData.isAdmin == false} value="0">No</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} sm={12} md={6}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>
                                                            <input type="checkbox" className="me-1" id="sendPasswordReset" checked={newUserData.sendPasswordReset} onChange={(e) => updateNewUserData({"target":{"value":e.target.checked,"id":e.target.id}})} /> Send Password Reset
                                                        </Form.Label>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                {!newUserData.sendPasswordReset && (<>
                                                    <Col xs={12} sm={12} md={6}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label className="mb-1">New Password</Form.Label>
                                                            <Form.Control type="password" id="password" value={newUserData.password} onChange={(e) => updateNewUserData(e)} className="" />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={6}>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label className="mb-1">Confirm Password</Form.Label>
                                                            <Form.Control type="password" id="passwordConfirm" value={newUserData.passwordConfirm} onChange={(e) => updateNewUserData(e)} className="" />
                                                        </Form.Group>
                                                    </Col>
                                                </>)}
                                            </Row>
                                        </Modal.Body>
                                        <Modal.Footer className="border-0 pt-0">
                                            <Button variant="outline-secondary" size="sm" onClick={()=>{setNewUserModal(false); setNewUserData({"firstName":"","lastName":"","email":"","active":true,"sendPasswordReset":true,"password":"","passwordConfirm":"","isAdmin":false})}}>Cancel</Button>
                                            <Button disabled={(newUserData.password != newUserData.passwordConfirm && !newUserData.sendPasswordReset) || ["firstName","lastName","email"].filter(function(field) {return newUserData[field] == ""}).length > 0 || newUserData.password == "" && !newUserData.sendPasswordReset} onClick={() => submitNewUser()} variant="success" size="sm">Submit</Button>
                                        </Modal.Footer>
                                    </Modal>
                                    )}
                                </div>
                                <div className="desktopFlex align-items-center mx-xl-4 filters">
                                    <div className="fw-500 me-2 smaller">Filter:</div>
                                    <div className="form-group me-2">
                                        <DropdownButton size="sm" variant="light" title="Account Status">
                                        <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                                            <input type="checkbox" className="form-check-input me-2 mb-2" checked={userFilters.states.includes(true)} onChange={(e) => handleFilterUpdate(true,e.target.checked,"states")} />
                                            <label title="" className="form-check-label">Active</label>
                                        </div>
                                        <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                                            <input type="checkbox" className="form-check-input me-2 mb-2" checked={userFilters.states.includes(false)} onChange={(e) => handleFilterUpdate(false,e.target.checked,"states")} />
                                            <label title="" className="form-check-label">Disabled</label>
                                        </div>
                                        </DropdownButton>
                                    </div>
                                    <div className="form-group me-2">
                                        <DropdownButton size="sm" variant="light" title="Last Login">
                                            {[{label: "Last Hour", value: 3600}, {label: "Last Day", value: 86400}, {label: "Last Week", value: 604800}, {label: "Last Month", value: 2628000}, {label: "Last Year", value: 31536000}].map(timeDuration => (
                                            <div key={timeDuration.value} className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                                                <input type="radio" className="form-check-input me-2 mb-2" checked={userFilters.lastLogin === timeDuration.value} onChange={(e) => handleFilterUpdate(timeDuration.value,e.target.checked,"lastLogin")} />
                                                <label title="" className="form-check-label">{timeDuration.label}</label>
                                            </div>
                                            ))}
                                        </DropdownButton>
                                    </div>
                                    <div className="form-group me-2">
                                        <DropdownButton size="sm" variant="light" title="Is Admin">
                                        <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                                            <input type="checkbox" className="form-check-input me-2 mb-2" checked={userFilters.admins.includes(true)} onChange={(e) => handleFilterUpdate(true,e.target.checked,"admins")} />
                                            <label title="" className="form-check-label">Yes</label>
                                        </div>
                                        <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                                            <input type="checkbox" className="form-check-input me-2 mb-2" checked={userFilters.admins.includes(false)} onChange={(e) => handleFilterUpdate(false,e.target.checked,"admins")} />
                                            <label title="" className="form-check-label">No</label>
                                        </div>
                                        </DropdownButton>
                                    </div>
                                    <a className="text-danger small" onClick={(e) => handleFilterUpdate(null,null,"reset")}><u>Reset</u></a>
                                </div>
                            </div>
                            {showDeleteUserModal &&
                                <Modal centered show>
                                <Modal.Header className="bg-primary text-white">
                                    <Modal.Title className="h6">Are you sure you want to delete this User?</Modal.Title>
                                </Modal.Header>
                                <Form>
                                    <Modal.Body className="pt-0">
                                    <div className="pt-3">
                                        <p className="fw-bold mb-2">Once this user has been deleted, it will not be possible to recover!</p>
                                    </div>
                                    </Modal.Body>
                                    <Modal.Footer className="p-3 pt-0 border-0">
                                    <Button variant="outline-secondary" size="sm" className="m-0 me-2" onClick={() => setDeleteUserModal(false)}>
                                        Cancel
                                    </Button>
                                    <Button variant="danger" size="sm" className="m-0" onClick={() => deleteUser(userToDelete)}>
                                        Delete User
                                    </Button>
                                    </Modal.Footer>
                                </Form>
                                </Modal>
                            }     
                            <div className="px-4 w-auto mt-3 mt-xl-4 usersTable">
                                <div className="mx-xl-4">
                                    <div className="tableHeaderFixedBelt"></div>
                                    <Table className="w-100 border-transparent">
                                        <thead>
                                            <tr>
                                                <th className="ps-3">Name</th>
                                                <th className="ps-3">Email</th>
                                                <th className="text-center"># Brands</th>
                                                <th className="text-center"># Influencers</th>
                                                <th className="text-center">Active</th>
                                                <th className="text-center">Is Admin</th>
                                                <th className="text-center">Last Login</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-nowrap"> 
                                            {users.map(user => (
                                                <tr key={user._id}>
                                                    <td>{user.firstName} {user.lastName}</td>
                                                    <td>{user.email}</td>
                                                    <td align="center">{typeof(user.brands) == 'number' ? user.brands : "?"}</td>
                                                    <td align="center">{typeof(user.influencers) == 'number' ? user.influencers : "?"}</td>
                                                    <td align="center">{user.active ? "Yes" : "No"}</td>
                                                    <td align="center">{user.admin ? "Yes" : "No"}</td>
                                                    <td align="center">{user.lastLogin ? (new Date(user.lastLogin*1000)).toLocaleDateString('en-GB', { year:"numeric", month:"short", day:"numeric", hour:"2-digit", minute:"2-digit"}) : "Never"}</td>
                                                    <td>
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <Dropdown className="brandAction">
                                                                <Dropdown.Toggle variant="default" className="border-0">
                                                                    <img src={settings} />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href={`Users/Settings?id=${user._id}`} className="dropdown-item">Edit</Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => {setUserToDelete(user);setDeleteUserModal(true);}} className="dropdown-item">Delete</Dropdown.Item>
                                                                    <Dropdown.Item onClick={() => disableUser(user,!user.active)} className="dropdown-item">{user.active ? "Disable" : "Enable"}</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                                <PageScroller endpoint="admin/accounts" setState={setState} filter={userFilters} />
                            {/* <Pagination className="justify-content-end">{items}</Pagination> */}
                            </div>
                        </div>
                    </Row>
                </Container>
                {/* <MyVerticallyCenteredModal show={props.this.showNewUserModal} onHide={() => props.this.setNewUserModal(false)} /> */}
            </div>
        )
    }
}
