import React, { useState, Component } from "react";
import { Container, Row, Col, Tab, Nav, Modal, Form, ToggleButton, ToggleButtonGroup, InputGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { apiCall } from './../../helpers/api'
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import AlertModal from "../../components/alerts/alert";
import { datePickerToEpoch, getDifference } from "../../helpers/dates";
import { getSmallNumber } from "../../helpers/stats";
import { validateImage, getImageUrl, compressImage, getImageFromUrl } from "../../helpers/images";
import { useImageContext } from "../../hooks/useImageContext";

import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import './Campaigns.css';
import NoAccess from "../../images/no-access.png";
import Topbar from "../../components/topbar/Topbar";
import Footer from "../../components/footer/Footer";
import Cross from "../../images/cross.svg";
import Details from './../../images/brand-details-blue.svg';
import { useAuthContext } from "../../hooks/useAuthContext";
import backArrow from './../../images/back-arrow-blue.svg';
import instagram from './../../images/instagram.svg';
import tiktok from './../../images/tiktok.svg';
import youtube from './../../images/youtube.svg';
import facebook from './../../images/facebook.svg';
import twitter from './../../images/twitter.svg';

const socialIcons = {
    "instagram" : instagram,
    "tiktok" : tiktok,
    "youtube" : youtube,
    "facebook" : facebook,
    "twitter" : twitter,
  }

class ImageUploadPreviewComponent extends Component {
 
    constructor(props) {
        super(props)
        this.state = {
            files: this.props.images,
            errors: [],
            draggedFile: null
        }
        this.previewFiles = this.previewFiles.bind(this)
    }
 
    async previewFiles(e) {
        var errors = [];
        var files = e.target.files;
        var fileArray = this.state.files;
        if (files.length + fileArray.length){
            files = Object.entries(files).slice(0,5-fileArray.length).map(file => file[1]);
        }
        for (var file of files) {
            var [validationResult, validationReason] = validateImage(file)
            if (validationResult){
                file = await compressImage(file,0.95)
                var tempFile = {"url":URL.createObjectURL(file),"data":file,"metadata":null,"type":"image","new":true}
                var response = await apiCall(`getUploadSignature?folder=campaignImages&asset_type=${tempFile.type}`,{ "method" : "GET" });
                if (response["success"]) {
                    tempFile.metadata = response["success"]["metadata"]
                    fileArray.push(tempFile);
                }
                else {
                    errors.push(`${file.name} could not be uploaded: ${response["failure"]["error"]}`)
                }
            }
            else {
                errors.push(`${file.name} could not be uploaded: ${validationReason}`)
            }
        }
        this.setState({ files: fileArray, errors: errors })
        this.props.setImages(fileArray)
        this.props.setImagesChanged(true)
    }

    removeFile(targetFile){
        var fileArray = this.state.files.filter(function(file) {return file.url != targetFile.url });
        this.setState({ files: fileArray })
        this.props.setImages(fileArray)
        this.props.setImagesChanged(true)
    }

    setPrimary(targetFile){
        var fileArray = this.state.files.filter(function(file) {return file.url != targetFile.url });
        fileArray.unshift(targetFile);
        this.setState({ files: fileArray })
        this.props.setImages(fileArray)
        this.props.setImagesChanged(true)
    }

    handleDrag(targetFile){
        this.setState({draggedFile: targetFile})
    }
    
    handleDrop(targetFile){
        var newArray = [];
        this.state.files.forEach(file => {
            if (file.url == this.state.draggedFile.url) {
                newArray.push(targetFile)
            }
            else if (file.url == targetFile.url){
                newArray.push(this.state.draggedFile)
            }
            else {
                newArray.push(file)
            }
        })
        this.setState({ files: newArray, draggedFile: null})
    }
 
    render() {
        return (
            <>
            <div className="form-group multi-preview mt-3">
                {this.state.files.length > 0 && (
                    <span key={this.state.files[0].url} className="imageWrapper mb-3" draggable={true} onDragOver={(ev) => ev.preventDefault()} onDragStart={e => this.handleDrag(this.state.files[0])} onDrop={e => this.handleDrop(this.state.files[0])}> 
                        <img height="200px" width="200px" className="me-3 object-fit-cover rounded border" src={this.state.files[0].url} alt="..." />
                        <img className="overlayTopRight" title="Remove this image from the campaign" onClick={e => this.removeFile(this.state.files[0])} src={Cross}/>
                        <span className="overlayBottomRight">Primary</span>
                    </span>
                )}
                {this.state.files.length > 1 && (this.state.files.slice(1).map(file => (
                    <span key={file.url} className="imageWrapper mb-3" draggable={true} onDragOver={(ev) => ev.preventDefault()} onDragStart={e => this.handleDrag(file)} onDrop={e => this.handleDrop(file)}>
                        <img id={file.url}  height="200px" width="200px" className="me-3 object-fit-cover rounded border" src={file.url} alt="..." />
                        <img className="overlayTopRight" title="Remove this image from the campaign" onClick={e => this.removeFile(file)} src={Cross}/>
                        <button className="btn btn-dark btn-sm overlayBottomLeft" title="Set this image as the primary image" onClick={e => this.setPrimary(file)}>Set Primary</button>
                    </span>
                )))}
                {this.state.errors && (<>{this.state.errors.map(error => (<p>{error}</p>))}</>)}
            </div>
            <Form.Group className="mb-3 multiUpload">
                <div className="multiUploadBox">Drag & Drop or <a className="text-primary">Upload</a></div>
                <Form.Control disabled={this.state.files.length >= 5} type="file" onChange={this.previewFiles} multiple accept=".png, .jpg, .jpeg, .webp"/>
                {this.state.files.length >= 5 && (
                    <div className="text-danger mt-3">If you'd like to change any photos, please delete one first</div>
                )}
            </Form.Group>
            </>
        )
    }
}

export function CreateCampaignFunction(props) {
    const [showAlert, setAlert] = useState({"display":"toast","visible":false,"title":"","content":""});
    const hideAlert = () => setAlert({"display":"toast","visible":false,"title":"","content":""});
    const { providers } = useImageContext();

    const [signupData, setSignupData] = useState({
        "territories": [],
        "deliveryAddress" : false,
        "usageRights" : false,
        "email" : false,
        "phoneNumber" : false,
        "ages" : ["Any"],
        "influencerSegments" : ["Any"],
        "socials" : [],
        "event" : false,
        "trackingTags" : [{"tag":"","socials":[]},{"tag":"","socials":[]},{"tag":"","socials":[]},{"tag":"@theinfluenceroomofficial","socials":[]}],
        "tips" : ["Create and share your content on time", "Use the correct tags in all content produced", "Be creative, stay true to yourself & have fun!"],
        "followerCount" : 0,
        "engagementRate" : 0.0,
        "images": [],
        "categories": [],
        "name":"",
        "minimumBudget":0,
        "maximumBudget":1000,
        "currency":"GBP",
        "offering":"",
        "lookingFor":"",
        "communityOnly": false
    }); 

    var brand = null
    var campaign = null
    var cloneCampaign = null
    const [isEvent, setEvent] = useState(false);
    const [ranOnce, setRanOnce] = useState(false)
    const [ranOnceBrand, setRanOnceBrand] = useState(false)
    const [addTermsAndConditions, setTermsAndConditions] = useState(false);
    const [imagesChanged, setImagesChanged] = useState(false);
    const navigate = useNavigate()

    const [categories, setCategories] = useState(() => []);
    const [categoriesValidationError,setCategoriesValidationError] = useState(null);
    const updateCategories = (newValue) => {
        if (newValue.length > 3){
            setCategoriesValidationError("Cannot have more than three categories selected. Please unselect one to choose another.")
        }
        else{
            setCategories(newValue);
            updateSignupDataState(newValue,"categories");
            setCategoriesValidationError(null)
        }
    };

    const [loadingImages, setLoadingImages] = useState(false)
    const [socials, setSocials] = useState(() => []);
    const updateSocials = (newValue) => {
        setSocials(newValue);
        updateSignupDataState(newValue,"socials");
    };

    const [ages, setAges] = useState(() => []);
    const updateAges = (newValue) => {
        if (newValue.slice(-1) == "Any"){
            newValue = ["Any"]
        }
        else if (newValue.includes("Any")){
            newValue = newValue.filter(function(age) { return age !== 'Any' });
        }
        setAges(newValue);
        updateSignupDataState(newValue,"ages");
    };

    const [influencerSegments, setInfluencerSegments] = useState(() => []);
    const updateInfluencerSegments = (newValue) => {
        if (newValue.slice(-1) == "Any"){
            newValue = ["Any"]
        }
        else if (newValue.includes("Any")){
            newValue = newValue.filter(function(segments) { return segments !== 'Any' });
        }
        setInfluencerSegments(newValue);
        updateSignupDataState(newValue,"influencerSegments");
    };

    
    const [campaignType, setCampaignType] = useState(null)
    const [ followerCount, setFollowerCount ] = useState(0)

    const [territories, setTerritories] = useState(() => []);
    const updateTerritories = (newValue) => {
        setTerritories(newValue);
        updateSignupDataState(newValue,"territories");
    };

    const requiredFields = ["name","territories","categories","offering","lookingFor","startDate","endDate","ages","influencerSegments"];
    const [nameCount, setNameCount] = React.useState(0);
    const [offeringCount, setOfferingCount] = React.useState(0);
    const [lookingForCount, setLookingForCount] = React.useState(0);
    const [images,setImages] = useState([])
    const [showOfferingToolTip, toggleOfferingToolTip] = useState(false)
    const [showLookingForToolTip, toggleLookingForToolTip] = useState(false)

    const [trackingTags, setTrackingTags] = useState([{"tag":"","socials":[]},{"tag":"","socials":[]},{"tag":"","socials":[]},{"tag":"@theinfluenceroomofficial","socials":[]}])
    const updateTrackingTags = (tag,position,isSocial=false) => {
        var tempTags = trackingTags;
        while (tempTags.length < position + 1){
            tempTags.push({"tag":"","socials":[]})
        }
        if (!tempTags[position]){
            tempTags[position] = {"tag":"","socials":[]}
        }
        if (isSocial){
            if (tempTags[position].socials.includes(tag)){
                tempTags[position].socials.splice(tempTags[position].socials.indexOf(tag),1)
            }
            else {
                tempTags[position].socials.push(tag)
            }
        }
        else {
            tempTags[position].tag = tag.replaceAll(" ", "")
        }
        setTrackingTags(tempTags)
        updateSignupDataState(tempTags,"trackingTags")
    }

    const [tips, setTips] = useState(["Create and share your content on time", "Use the correct tags in all content produced", "Be creative, stay true to yourself & have fun!"])
    const updateTips = (tip,position) => {
        var tempTips = tips;
        while (tempTips.length < position + 1){
            tempTips.push("")
        }
        tempTips[position] = tip
        setTips(tempTips)
        updateSignupDataState(tempTips,"tips")
    }

    const [tipsCount, setTipsCount] = React.useState([37,44,36,0]);
    const updateTipsCount = (length,position) => {
        var tempTipsCount = tipsCount;
        while (tempTipsCount.length < position + 1){
            tempTipsCount.push("")
        }
        tempTipsCount[position] = length;
        setTipsCount(tempTipsCount)
    }

    if (props.hasOwnProperty("brand")) {
        brand = props.brand
        if (!props.campaign && brand && brand.hasOwnProperty("terms")) {
            if (!ranOnceBrand) {
                setRanOnceBrand(true)
                setTermsAndConditions(props.brand.terms.length > 0);
            }
        }
    }

    if (props.hasOwnProperty("campaign")){
        campaign = props.campaign
        cloneCampaign = props.cloneCampaign
        // This is a bit of a quick and dirty way to get around the fact that we need to re-render certain things
        // but can't set campaign data each time. I'm sure I've missed something and we can just do this a nicer
        // way but at least it works :D
        if (!ranOnce){
            setLoadingImages(true)
            setRanOnce(true)
            setSignupData({
                "name": props.campaign.name,
                "territories": props.campaign.territories,
                "deliveryAddress" : props.campaign.requirements.deliveryAddress,
                "usageRights" : props.campaign.requirements.usageRights,
                "email" : props.campaign.requirements.email,
                "phoneNumber" : props.campaign.requirements.phoneNumber,
                "ages" : props.campaign.criteria.ages,
                "influencerSegments" : props.campaign.criteria.influencerSegments,
                "socials" : props.campaign.criteria.socials,
                "followerCount" : props.campaign.criteria.followerCount,
                "engagementRate" : props.campaign.criteria.engagementRate,
                "event" : props.campaign.event,
                "duration" : props.campaign.eventDetails.duration,
                "location" : props.campaign.eventDetails.location,
                "eventStartDate" : props.campaign.eventDetails.eventStartDate,
                "trackingTags" : props.campaign.trackingTags,
                "tips" : props.campaign.tips,
                "categories" : props.campaign.categories,
                "offering" : props.campaign.offering,
                "lookingFor" : props.campaign.lookingFor,
                "images" : props.campaign.images,
                "startDate" : props.campaign.startDate,
                "endDate" : props.campaign.endDate,
                "terms" : props.campaign.terms,
                "campaignType" : props.campaign.campaignType,
                "currency" : props.campaign.budget.currency,
                "minimumBudget" : props.campaign.budget.minimum,
                "maximumBudget" : props.campaign.budget.maximum,
                "communityOnly" : props.campaign.communityOnly
            })
            setNameCount(props.campaign.name.length)
            setOfferingCount(props.campaign.offering.length)
            setLookingForCount(props.campaign.lookingFor.length)
            setEvent(props.campaign.event);
            setTermsAndConditions(props.campaign.terms.length > 0);
            setTips(props.campaign.tips);
            setCampaignType(props.campaign.campaignType);
            loadExistingImages(props.campaign.images);
            setFollowerCount(props.campaign.criteria.followerCount)
            var roomTagPosition = props.campaign.trackingTags.map(tag => tag.tag).indexOf("@theinfluenceroomofficial")
            if (roomTagPosition > 0){
                if (roomTagPosition != 3){
                    props.campaign.trackingTags.splice(roomTagPosition,1)
                    props.campaign.trackingTags[3] = {"tag":"@theinfluenceroomofficial","socials":[]}
                    for (let index in [0,1,2,3]){
                        console.log(index, props.campaign.trackingTags[index])
                        if (!props.campaign.trackingTags[index]){
                            props.campaign.trackingTags[index] = {"tag":"","socials":[]}
                        }
                    }
                }
                console.log(props.campaign.trackingTags)
                setTrackingTags(props.campaign.trackingTags);
            }
            else if (roomTagPosition == -1){
                setTrackingTags(props.campaign.trackingTags)
            }
            if (props.cloneCampaign) {
                props.campaign.active = false
                props.campaign.archived = false
            }
        }
    }

    async function loadExistingImages(images){
        var newImages = [];
        for (const image of images){
            const file = {"url":getImageUrl(providers,image),"data":image.id,"new":false,"original":image}
            //const file = await getImageFromUrl(getImageUrl(providers,image),image.id)
            newImages.push(file);
        }
        setImages(newImages);
        setLoadingImages(false)
    }

    async function uploadNewImages(images){
        var failedImages = [];
        var campaignImages = [];
        for (const image of images){
            if (image.new) {
                const formData = new FormData();
                formData.append("file",image.data)
                formData.append("public_id",image.metadata.public_id)
                formData.append("api_key",image.metadata.api_key)
                formData.append("timestamp",image.metadata.timestamp)
                formData.append("signature",image.metadata.signature)
                if (image.metadata.folder != ""){
                    formData.append("folder",image.metadata.folder)
                }
                campaignImages.push(await fetch(`${image.metadata.target}/${image.metadata.resource_type}/upload`, {
                    method: "POST",
                    body: formData
                }).then(async response => {
                    try {
                        let responseJSON = await response.json();
                        if (response.ok) {
                            return {"id":responseJSON.public_id,"source":image.metadata.source,"name":image.data.name}
                            }
                        else {
                            failedImages.push(image)
                        }
                    } catch (error) {
                        failedImages.push(image)
                    }
                }))
            }
            else {
                campaignImages.push(image.original)
            }
        }
        return campaignImages;
      }

    async function saveCampaign(event,action="draft") {
        // Validate data
        var valid = true;
        var validityIssues = [];
        if (addTermsAndConditions && (!("terms" in signupData) || signupData["terms"].length == 0)){
            validityIssues.push("Terms have not been defined")
            valid = false;
        }
        if (datePickerToEpoch(signupData.startDate) > datePickerToEpoch(signupData.endDate)){
            validityIssues.push("End date cannot be after start date")
            valid = false;
        }
        if (signupData.trackingTags.length > 0){
            console.log(signupData.trackingTags)
            signupData.trackingTags.filter(function(tag) {return tag.tag.length > 0}).forEach(tag => {
                if (!tag.tag.startsWith("@") && !tag.tag.startsWith("#")){
                    validityIssues.push("Tags should start with '#' or '@'");
                    valid = false;
                }
                if (/^(?:(@|#))([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)$/.test(tag.tag) != true){
                    validityIssues.push("Tags contain invalid characters!");
                    valid = false;
                }
            })
        }
        if ( action == "publish" || (campaign && campaign.active) ){
            requiredFields.forEach(field => {
                if (!(field in signupData)){
                    validityIssues.push(`${field} is missing`)
                    valid = false;
                }
                else if (signupData[field].length == 0){
                    validityIssues.push(`${field} is missing`)
                    valid = false;
                }
            });
            if (campaignType != "sampling" && campaignType != "content"){
                if (!signupData.trackingTags || signupData.trackingTags.length == 0){
                    validityIssues.push("Tracking Tags is missing")
                    valid = false
                }
            }
            if (images.length == 0){
                validityIssues.push("No images chosen")
                valid = false;
            }
            if (signupData.hasOwnProperty("endDate") && !getDifference(datePickerToEpoch(signupData.endDate)).future){
                validityIssues.push("End date cannot be in the past!")
                valid = false;
            }
        }
        if (!valid){
            setAlert({"display":"toast","visible":true,"title":"Form Issues","content":"Outstanding issues: " + validityIssues.join(", "),"className":"bg-danger text-white"})
        }
        if ((action != "publish" && (!campaign || (campaign && !campaign.active))) && signupData.name.length == 0){
            setAlert({"display":"toast","visible":true,"title":"Cannot Save Draft","content":"Please specify a title to save this campaign as a draft","className":"bg-danger text-white"})
            valid = false;
        }
        if (valid){
            if (isEvent && signupData.eventStartDate) {
                signupData.eventStartDate = datePickerToEpoch(signupData.eventStartDate)
            }
            signupData.event = isEvent;
            signupData.followerCount = parseInt(followerCount);
            signupData.trackingTags = signupData.trackingTags.filter(function(tag) {return tag.tag.length > 0});
            signupData.tips = tips.filter(function(tip) {return tip.length > 0});
            if (!addTermsAndConditions) {
                signupData.terms = ""
            }
            if (!campaign || (campaign && !campaign.active)) {
                if (signupData.startDate) {
                    signupData.startDate = datePickerToEpoch(signupData.startDate)
                    signupData.endDate = datePickerToEpoch(signupData.endDate)
                }
                else {
                    signupData.startDate = 0
                    signupData.endDate = 0
                }
                // Upload images first
                if (images.length > 0){
                    signupData.images = await uploadNewImages(images) 
                }
                else {
                    signupData.images = []
                }
            }
            else {
                signupData.startDate = datePickerToEpoch(signupData.startDate)
                signupData.endDate = datePickerToEpoch(signupData.endDate) 
                // Upload images first
                signupData.images = await uploadNewImages(images) 
            }
            if (action == "publish"){
                var response = await apiCall('campaign/publish',{ "method" : "PUT", "data" : {"id":campaign._id} });
                if (response["success"]){
                    return navigate("/Campaigns")
                }
                else if (response["failure"]){
                    setAlert({"display":"toast","visible":true,"title":"Error Publishing Campaign","content":response["failure"]["error"],"className":"bg-danger text-white"})
                  }
            }
            if (action == "draft" || (action == "preview" && (!campaign || cloneCampaign))){
                if (signupData.images.length > 0 || action == "draft"){
                    var response = await apiCall('campaign',{ "method" : "POST", "data" : signupData });
                    if (response["success"]){
                        if (action == "draft"){
                            setAlert({"display":"toast","visible":true,"title":"Campaign Draft Saved","content":"Campaign Saved as Draft","className":"bg-success text-white"})
                            window.location.href=`/Campaign/Create?id=${response["success"]["campaign_id"]}`;
                        }
                        else {
                            return navigate(`/Campaign/Preview?id=${response["success"]["campaign_id"]}`)
                        }
                    }
                    else {
                        setAlert({"display":"toast","visible":true,"title":"Unable to Create Campaign","content":response["failure"]["error"],"className":"bg-danger text-white"})
                    }
                }
                else {
                    setAlert({"display":"toast","visible":true,"title":"Unable to Create Campaign","content":"Could not upload images","className":"bg-danger text-white"})
                }
            }
            if (action == "update" || (action == "preview" && campaign && !cloneCampaign)){
                signupData["id"] = campaign._id
                var response = await apiCall(`campaign`,{ "method" : "PUT", "data" : signupData });
                if (response["success"]){
                    if (action == "update"){
                        setAlert({"display":"toast","visible":true,"title":"Campaign Saved","content":"Campaign Updated","className":"bg-success text-white"})
                    }
                    else {
                        return navigate(`/Campaign/Preview?id=${campaign._id}`)
                    }
                }
                else {
                    setAlert({"display":"toast","visible":true,"title":"Unable to Update Campaign","content":response["failure"]["error"],"className":"bg-danger text-white"})
                }
            }
        }
        event.preventDefault();
    }

    function updateSignupDataState(props, id=null) {
        var tempUserData = Object.assign({},signupData);
        if (id == "followerCount"){
            tempUserData[id] = parseInt(props)
        }
        else if (id == "engagementRate"){
            tempUserData[id] = parseFloat(props)
        }
        else if (id != null){
            tempUserData[id] = props
        }
        else if ( props.target.type == "checkbox" ) {
            tempUserData[props.target.id] = props.target.checked;
        } 
        else {
            tempUserData[props.target.id] = props.target.value;
        }
        setSignupData(tempUserData);
    }

    function calculateMinimumFollowers() {
        if (campaignType == "commercial") {
            updateSignupDataState(50000,"followerCount") //Large
        }
        else if (campaignType == "gifting") {
            updateSignupDataState(10000,"followerCount") //Medium
        }
        else if (campaignType == "content") {
            updateSignupDataState(1000,"followerCount") //Small
        }
        else { //Sampling & Causes
            updateSignupDataState(1,"followerCount") //Micro
        }
    }

    function ToggleButtonGroupCategories() {    
        return (
            <>
            {categoriesValidationError ? (<span className="small text-muted d-block mb-3"><b className="text-danger">{categoriesValidationError}</b></span>) : null}
            <ToggleButtonGroup type="checkbox" value={campaign && categories.length == 0 ? campaign.categories : categories} onChange={updateCategories} id="categories">
                <ToggleButton id="tbg-btn-1" className="me-2 mb-2" value="Activities">Activities</ToggleButton>
                <ToggleButton id="tbg-btn-2" className="me-2 mb-2" value="Arts & Culture">Arts & Culture</ToggleButton>
                <ToggleButton id="tbg-btn-3" className="me-2 mb-2" value="Automotive">Automotive</ToggleButton>
                <ToggleButton id="tbg-btn-4" className="me-2 mb-2" value="Beauty, Grooming & Fragrance">Beauty, Grooming & Fragrance</ToggleButton>
                <ToggleButton id="tbg-btn-5" className="me-2 mb-2" value="Books & Magazines">Books & Magazines</ToggleButton>
                <ToggleButton id="tbg-btn-6" className="me-2 mb-2" value="Charity & Nonprofit">Charity & Nonprofit</ToggleButton>
                <ToggleButton id="tbg-btn-7" className="me-2 mb-2" value="Children & Baby">Children & Baby</ToggleButton>
                <ToggleButton id="tbg-btn-8" className="me-2 mb-2" value="Entertainment & Media">Entertainment & Media</ToggleButton>
                <ToggleButton id="tbg-btn-9" className="me-2 mb-2" value="Environment">Environment</ToggleButton>
                <ToggleButton id="tbg-btn-10" className="me-2 mb-2" value="Fashion">Fashion</ToggleButton>
                <ToggleButton id="tbg-btn-11" className="me-2 mb-2" value="Food & Drink">Food & Drink</ToggleButton>
                <ToggleButton id="tbg-btn-12" className="me-2 mb-2" value="Health, Wellness & Personal Care">Health, Wellness & Personal Care</ToggleButton>
                <ToggleButton id="tbg-btn-13" className="me-2 mb-2" value="Holidays & Travel">Holidays & Travel</ToggleButton>
                <ToggleButton id="tbg-btn-14" className="me-2 mb-2" value="Home & Garden">Home & Garden</ToggleButton>
                <ToggleButton id="tbg-btn-15" className="me-2 mb-2" value="Lifestyle">Lifestyle</ToggleButton>
                <ToggleButton id="tbg-btn-16" className="me-2 mb-2" value="Pets">Pets</ToggleButton>
                <ToggleButton id="tbg-btn-17" className="me-2 mb-2" value="Restaurants, Bars & Clubs">Restaurants, Bars & Clubs</ToggleButton>
                <ToggleButton id="tbg-btn-18" className="me-2 mb-2" value="Sport & Fitness">Sport & Fitness</ToggleButton>
                <ToggleButton id="tbg-btn-19" className="me-2 mb-2" value="Technology, Gaming & Electronics">Technology, Gaming & Electronics</ToggleButton>
                <ToggleButton id="tbg-btn-20" className="me-2 mb-2" value="Tickets">Tickets</ToggleButton>
            </ToggleButtonGroup>
            </>
        );
    }

    function ToggleButtonSocialChannel() {
        return (
          <ToggleButtonGroup type="checkbox" value={campaign && socials.length == 0 ? campaign.criteria.socials : socials} onChange={updateSocials} id="socials">
            <ToggleButton id="socialMedia-1" className="me-2 mb-2" value="Instagram">Instagram</ToggleButton>
            <ToggleButton id="socialMedia-2" className="me-2 mb-2" value="Tiktok">Tiktok</ToggleButton>
            <ToggleButton id="socialMedia-3" className="me-2 mb-2" value="Facebook">Facebook</ToggleButton>
            <ToggleButton id="socialMedia-4" className="me-2 mb-2" value="Youtube">Youtube</ToggleButton>
            <ToggleButton id="socialMedia-5" className="me-2 mb-2" value="Twitter">Twitter</ToggleButton>
          </ToggleButtonGroup>
        );
    }
    
    function ToggleButtonAgeRange() {      
        return (
          <ToggleButtonGroup type="checkbox" value={campaign && ages.length == 0 ? campaign.criteria.ages : ages} onChange={updateAges} id="age">
            <ToggleButton id="ageRange-1" className="me-2 mb-2" value="Any">Any</ToggleButton>
            <ToggleButton id="ageRange-2" className="me-2 mb-2" value="18 - 24">18 - 24</ToggleButton>
            <ToggleButton id="ageRange-3" className="me-2 mb-2" value="25 - 34">25 - 34</ToggleButton>
            <ToggleButton id="ageRange-4" className="me-2 mb-2" value="35 - 44">35 - 44</ToggleButton>
            <ToggleButton id="ageRange-5" className="me-2 mb-2" value="45 - 59">45 - 59</ToggleButton>
            <ToggleButton id="ageRange-6" className="me-2 mb-2" value="60+">60+</ToggleButton>
          </ToggleButtonGroup>
        );
    }

    function ToggleButtonInfluencerSegments() {      
        return (
          <ToggleButtonGroup type="checkbox" value={campaign && influencerSegments.length == 0 ? campaign.criteria.influencerSegments : influencerSegments} onChange={updateInfluencerSegments} id="influencerSegments">
            <ToggleButton key={"Any"} id={`tbg-btn-Any`} className="me-2 mb-2" value={"Any"}>Any</ToggleButton>
            <ToggleButton key={"Digital First"} id={`tbg-btn-Digital-First`} className="me-2 mb-2" value={"Digital First"}>Digital First</ToggleButton>
            <ToggleButton key={"Talent First"} id={`tbg-btn-Talent-First`} className="me-2 mb-2" value={"Talent First"}>Talent First</ToggleButton>
            <ToggleButton key={"Creator"} id={`tbg-btn-Creator`} className="me-2 mb-2" value={"Creator"}>Creator</ToggleButton>
            <ToggleButton key={"Consumer"} id={`tbg-btn-Consumer`} className="me-2 mb-2" value={"Consumer"}>Consumer</ToggleButton>
          </ToggleButtonGroup>
        );
    }

    function ToggleButtonTerritories() {      
        return (
          <ToggleButtonGroup type="checkbox" value={campaign && territories.length == 0 ? campaign.territories : territories} onChange={updateTerritories} id="territories">
            <ToggleButton id="territories-1" className="me-2 mb-2" value="GB">UK</ToggleButton>
            <ToggleButton id="territories-2" className="me-2 mb-2" value="IE">Ireland</ToggleButton>
            <ToggleButton id="territories-3" className="me-2 mb-2" value="US">USA</ToggleButton>
            <ToggleButton id="territories-4" className="me-2 mb-2" value="DE">Germany</ToggleButton>
          </ToggleButtonGroup>
        );
    }

    let [searchParams] = useSearchParams();
    var view = searchParams.get('view');
    if (view == null){
        view = "details";
    }
    const [tabID, setTabID] = useState(view);

    if (!campaignType || campaignType == "contra")
    {
        return (
            <div className="bg-page campaignPage mt-md-3 pt-md-0 mt-lg-4 pt-lg-0 mt-xl-0 pt-xl-4 px-md-5">
                <Container fluid className="d-flex align-items-center">
                    <Row className="d-flex align-items-stretch justify-content-center">
                        <Col xs="12">
                            <h3 className="text-center mb-md-4 pt-md-4 pb-4 pt-5 mt-3 fw-normal">Choose {campaignType == "contra" && "Contra"} Campaign Type</h3>
                        </Col>
                        {campaignType == "contra" ? 
                        <>
                            <Col xs="12" className="pb-4">
                                <a className="pe-3" onClick={e => {updateSignupDataState(null,"campaignType"); updateSignupDataState(0,"followerCount"); setFollowerCount(0); setCampaignType(null)}}><img src={backArrow} /> Back</a> 
                            </Col>
                            <Col className="text-center mb-4">
                                <div onClick={e => {updateSignupDataState("sampling","campaignType"); setCampaignType("sampling")}} className="rounded-xl border bg-white p-3 shadow hover h-100">
                                    <img src="https://images.pexels.com/photos/3761509/pexels-photo-3761509.jpeg?auto=compress&cs=tinysrgb&w=600" height="200px" className="rounded border w-100 object-fit-cover" />
                                    <button className="btn btn-primary rounded-bottom my-3 w-100" value="sampling">Sampling</button>
                                    <div className="mx-4 text-muted">Send samples of your products to people who want to receive them and will give you something in return.</div>
                                </div>
                            </Col>
                            <Col className="text-center mb-4">
                                <div onClick={e => {updateSignupDataState("content","campaignType"); setCampaignType("content")}} className="rounded-xl border bg-white p-3 shadow hover h-100">
                                    <img src="https://images.pexels.com/photos/7669741/pexels-photo-7669741.jpeg?auto=compress&cs=tinysrgb&w=600" height="200px" className="rounded border w-100 object-fit-cover" />
                                    <button className="btn btn-primary rounded-bottom my-3 w-100" value="content">Content</button>
                                    <div className="mx-4 text-muted">Gift products or experiences to creators in exchange for assets they create for you to use in your marketing. Content will be uploaded via the platform directly and not tracked across social media channels.</div>
                                </div>
                            </Col>
                            <Col className="text-center mb-4">
                                <div onClick={e => {updateSignupDataState("gifting","campaignType"); setCampaignType("gifting")}} className="rounded-xl border bg-white p-3 shadow hover h-100">
                                    <img src="https://images.pexels.com/photos/264787/pexels-photo-264787.jpeg?auto=compress&cs=tinysrgb&w=600" height="200px" className="rounded border w-100 object-fit-cover" />
                                    <button className="btn btn-primary rounded-bottom my-3 w-100" value="gifting">Organic Social</button>
                                    <div className="mx-4 text-muted">Exchange products and experiences for authentic social media. These campaigns will be tracked to measure your activity's success.</div>
                                </div>
                            </Col>
                        </>    
                        :
                        <>
                            <Col className="text-center mb-4">
                                <div onClick={e => {updateSignupDataState("contra","campaignType"); setCampaignType("contra")}} className="rounded-xl border bg-white p-3 shadow hover h-100">
                                    <img src="https://images.pexels.com/photos/264787/pexels-photo-264787.jpeg?auto=compress&cs=tinysrgb&w=600" height="200px" className="rounded border w-100 object-fit-cover" />
                                    <button className="btn btn-primary rounded-bottom my-3 w-100" value="gifting">Contra</button>
                                    <div className="mx-4 text-muted">Exchange products or experiences for exposure from advocates.</div>
                                </div>
                            </Col>
                            <Col className="text-center mb-4">
                                <div onClick={e => {updateSignupDataState("commercial","campaignType"); setCampaignType("commercial")}} className="rounded-xl border bg-white p-3 shadow hover h-100">
                                    <img src="https://images.pexels.com/photos/50987/money-card-business-credit-card-50987.jpeg?auto=compress&cs=tinysrgb&w=600" height="200px" className="rounded border w-100 object-fit-cover" />
                                    <button className="btn btn-primary rounded-bottom my-3 w-100" value="commercial">Commercial</button>
                                    <div className="mx-4 text-muted">Paid influencer - unlock influencers who bid to work with you in response to your brief and budget</div>
                                </div>
                            </Col>
                            <Col className="text-center mb-4">
                                <div onClick={e => {updateSignupDataState("cause","campaignType"); setCampaignType("cause")}} className="rounded-xl border bg-white p-3 shadow hover h-100">
                                    <img src="https://images.pexels.com/photos/9826015/pexels-photo-9826015.jpeg?auto=compress&cs=tinysrgb&w=600" height="200px" className="rounded border w-100 object-fit-cover" />
                                    <button className="btn btn-primary rounded-bottom my-3 w-100" value="cause">Cause</button>
                                    <div className="mx-4 text-muted">Find supporters of your cause who champion what you stand for and activate your messaging.</div>
                                </div>
                            </Col>
                        </>
                        }           
                    </Row>
                </Container>
            </div>
        )
    }
    
    return (
        <>
        <AlertModal modal={showAlert} onClose={hideAlert} />
        <Tab.Container activeKey={tabID} onSelect={(key) => setTabID(key)}>
            <Container>
                <Row className="d-flex justify-content-center">
                    <Col sm={12} md={4} lg={4} xl={4} xxl={3}>

                        <div className="d-flex align-items-center justify-content-between mt-4 mb-4 pb-2 pt-4">
                            <h1 className="pageTitle text-navy fw-700" id="details">
                                {campaign && !cloneCampaign ? "Update Campaign" : "Create Campaign"}
                            </h1>
                            {!campaign && <div onClick={e => {updateSignupDataState(null,"campaignType"); updateSignupDataState(0,"followerCount"); setFollowerCount(0); setCampaignType(null)}} className="text-blue mb-1 cursor-pointer">Change Type</div>}
                        </div>

                        <Nav variant="tabs" className="border-0 mb-4">
                            <div className="bg-white rounded-xl createCampaignNav w-100 shadow">
                                <Nav.Link eventKey="details" className="p-3 d-flex align-items-center justify-content-between w-100">
                                    <div className="d-flex align-items-center">
                                        <Nav.Item className="d-flex align-items-center">
                                            <img src={Details} className="me-3 d-none d-md-block" /> <span>Details</span>
                                        </Nav.Item>
                                    </div>
                                    <span className="d-none d-md-block">
                                        <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="#A7A7A7"/>
                                        </svg>
                                    </span>
                                </Nav.Link>
                                <Nav.Link eventKey="offering" className="p-3 d-flex align-items-center justify-content-between w-100">
                                    <div className="d-flex align-items-center">
                                        <Nav.Item className="d-flex align-items-center">
                                            <img src={Details} className="me-3 d-none d-md-block" /> <span>Offering</span>
                                        </Nav.Item>
                                    </div>
                                    <span className="d-none d-md-block">
                                        <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="#A7A7A7"/>
                                        </svg>
                                    </span>
                                </Nav.Link>
                                <Nav.Link eventKey="lookingFor" className="p-3 d-flex align-items-center justify-content-between w-100">
                                    <div className="d-flex align-items-center">
                                        <Nav.Item className="d-flex align-items-center">
                                            <img src={Details} className="me-3 d-none d-md-block" /> <span>Looking for</span>
                                        </Nav.Item>
                                    </div>
                                    <span className="d-none d-md-block">
                                        <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="#A7A7A7"/>
                                        </svg>
                                    </span>
                                </Nav.Link>
                                <Nav.Link eventKey="targeting" className="p-3 d-flex align-items-center justify-content-between w-100">
                                    <div className="d-flex align-items-center">
                                        <Nav.Item className="d-flex align-items-center">
                                            <img src={Details} className="me-3 d-none d-md-block" /> <span>Targeting</span>
                                        </Nav.Item>
                                    </div>
                                    <span className="d-none d-md-block">
                                        <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="#A7A7A7"/>
                                        </svg>
                                    </span>
                                </Nav.Link>
                                <Nav.Link eventKey="customisation" className="p-3 d-flex align-items-center justify-content-between w-100">
                                    <div className="d-flex align-items-center">
                                        <Nav.Item className="d-flex align-items-center">
                                            <img src={Details} className="me-3 d-none d-md-block" /> <span>Customisation</span>
                                        </Nav.Item>
                                    </div>
                                    <span className="d-none d-md-block">
                                        <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="#A7A7A7"/>
                                        </svg>
                                    </span>
                                </Nav.Link>
                            </div>
                        </Nav>

                        <Row>
                            {campaign && !cloneCampaign ? (
                                <>
                                {!campaign.active && <Col sm={12}><button className="btn btn-primary w-100 mb-3" onClick={(event) => {saveCampaign(event,"publish")}}>Publish</button></Col>}
                                <Col xs={6} sm={6} md={12} lg={6} xl={6} className="mb-3"><button className="btn btn-outline-primary success w-100 mb-3" onClick={(event) => {saveCampaign(event,"update")}}>Update</button></Col>
                                <Col xs={6} sm={6} md={12} lg={6} xl={6} className="mb-3"><button className="btn btn-primary w-100" onClick={(event) => {saveCampaign(event,"preview")}}>Save & Preview</button></Col>
                                </>
                            ) : (
                                <>
                                <Col xs={6} sm={6} md={12} lg={6} xl={6} className="mb-3"><button className="btn btn-outline-primary w-100" onClick={(event) => {saveCampaign(event,"draft")}}>Save as Draft</button></Col>
                                <Col xs={6} sm={6} md={12} lg={6} xl={6} className="mb-3"><button className="btn btn-primary w-100" onClick={(event) => {saveCampaign(event,"preview")}}>Save & Preview</button></Col>
                                </>
                            )}
                            <Col sm={12} className="mb-3"><Link to="/Campaigns" className="btn btn-outline-danger w-100">Discard Changes</Link></Col>
                        </Row>
                    </Col>
                    <Col sm={12} md={8} lg={8} xl={8} xxl={7}>
                        <Tab.Content>
                            <Tab.Pane eventKey="details">
                                <div className="px-lg-4 pt-md-4 mt-4 px-md-0">
                                    <div className="p-4 bg-white shadow rounded-xl">
                                        <div className="d-flex align-items-center justify-content-between mb-4">
                                            <h5 className="mb-0">Details</h5>
                                            <div>
                                                <button disabled={!signupData.territories.length || !signupData.name || !images.length || !signupData.categories.length} onClick={() => {setTabID("offering")}} className="btn btn-sm btn-primary">Next</button>
                                            </div>
                                        </div>

                                        <div className="createCampaignForm">

                                            <div className="form-group mb-4">
                                                <label>Territory</label>
                                                <span className="small text-muted d-block mb-3">Please select the territories this campaign will be for</span>

                                                <ToggleButtonTerritories />
                                            </div>

                                            <hr className="my-4" />

                                            <div className="form-group my-4 position-relative">
                                                <label>Title</label>
                                                <input type="text" id="name" maxLength="50" className="form-control pe-5" onChange={e => {updateSignupDataState(e);  setNameCount(e.target.value.length)}} defaultValue={campaign ? campaign.name : null}/>
                                                <span className="position-absolute smaller end-0 top-0 text-muted mt-4 pt-3 me-2">{nameCount}/50</span>
                                            </div>

                                            <hr className="my-4" />

                                            <div className="form-group mb-4">
                                                <label>Images (up to 5)</label>
                                                <span className="small text-muted d-block">Capture people's interest with great quality images that show off what you're offering! For example: product photos, publicity shots, previous events etc.</span>
                                                {loadingImages ? <p>Loading Images...</p> : 
                                                    <ImageUploadPreviewComponent images={images} setImages={setImages} setImagesChanged={setImagesChanged}/>
                                                }
                                                <span className="small text-muted d-block">Please only upload the following formats: <b>PNG</b>, <b>JPEG/JPG</b>, <b>WEBP</b> & <b>HEIC</b>.</span>
                                                <span className="small text-muted d-block"><i>Images should be of high enough quality (500x500 pixels minimum) but no larger than 5mb in size.</i></span>
                                            </div>

                                            <hr className="my-4" />

                                            <div className="form-group">
                                                <label>Categories:</label>
                                                <span className="small text-muted d-block mb-3">Please select up to 3 categories for your campaign</span>                

                                                <ToggleButtonGroupCategories />
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="offering">
                                <Modal centered show={showOfferingToolTip} onHide={() => toggleOfferingToolTip(false)}>
                                    <Modal.Header closeButton className="modal-success">
                                    <Modal.Title className='h6'>Tips for writing a great description</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Footer className="border-0 rounded success">
                                        <Form className='justify-content-between align-items-center w-100 m-0'>
                                            {["gifting","content","sampling"].includes(campaignType) && (
                                                <>
                                                    <p className='m-0 ps-2'>Include details about what you're offering in exchange for content. This might be a product, an experience or campaign involvement. Tell bidders what makes it special and why they'll love it.</p>
                                                    <br />
                                                    <p className='m-0 ps-2'>* Add information about your brand; your values, beliefs and what you stand for - people will bid for love not money when they get what you're all about.</p>
                                                    <p className='m-0 ps-2'>* Explain the product, service or experience that you are offering </p>
                                                    <p className='m-0 ps-2'>* The more you give, the more you get so be generous in the amount or the value of your offer</p>
                                                    <p className='m-0 ps-2'>* Bullet points work well for the offer so you can cover off key points</p>
                                                    <br />
                                                    <h3 className="font-bold">Example description:</h3>
                                                    <br />
                                                    <p className='m-0 ps-2'>DABOO are passionate about engaging young minds and are on a mission to help children thrive as they learn about the world around them. Daboo offers a range of natural world topic, fully sustainable children’s books which are packaged in proven formats to engage in age specific ways. We’re offering:</p>
                                                    <br />
                                                    <p className='m-0 ps-2'>- A box of 10 x pop up books, audio recordings and video games for Elementary aged children</p>
                                                </>
                                            )}
                                            {campaignType == "commercial" && (
                                                <>
                                                    <p className='m-0 ps-2'>Include details about what you’re offering in exchange for content. This might be a product, an experience or campaign involvement. Tell bidders what makes it special and why they’ll love it.</p><br />
                                                    <p className='m-0 ps-2'>* Add information about your brand; your values, beliefs and what you stand for - people will bid for love not money when they get what you’re all about.</p>
                                                    <p className='m-0 ps-2'>* Explain the product, service or experience that you are offering </p>
                                                    <p className='m-0 ps-2'>* The more you give, the more you get so be generous in the amount or the value of your offer</p>
                                                    <p className='m-0 ps-2'>* Detail the range of budget you have available and what the paid requirement is (<i>e.g 6 months supply of skincare and between $1,000 - $20,000 for an event attendance - the more they love your brand, the less they’ll need to be paid!</i>)</p>
                                                    <p className='m-0 ps-2'>* Bullet points work well for the offer so you can cover off key points</p>
                                                    <br />
                                                    <h3 class="font-bold">Example description:</h3>
                                                    <br />
                                                    <p className='m-0 ps-2'>DABOO are passionate about engaging young minds and are on a mission to help children thrive as they learn about the world around them. Daboo offers a range of natural world topic, fully sustainable children’s books which are packaged in proven formats to engage in age specific ways. We’re offering:</p>
                                                    <br />
                                                    <p className='m-0 ps-2'>- A set of audio books</p><p>- $5,000 - $10,000 for you and your child to attend our opening immersive experience on the 25th of March, 2023.</p>
                                                </>
                                            )}
                                        </Form>
                                    </Modal.Footer>
                                </Modal>
                                <div className="px-lg-4 pt-md-4 mt-4 px-md-0">
                                    <div className="p-4 bg-white shadow rounded-xl">
                                        <div className="d-flex align-items-center justify-content-between mb-4">
                                            <h5 className="mb-0">Offering</h5>
                                            <div>
                                                <button onClick={() => {setTabID("details")}} className="btn btn-sm btn-outline-primary me-2">Previous</button>
                                                <button disabled={signupData.offering.length == 0 || !signupData.startDate || !signupData.endDate || (signupData.minimumBudget < 0 || signupData.maximumBudget < 0 || signupData.minimumBudget >= signupData.maximumBudget)} onClick={() => {setTabID("lookingFor")}} className="btn btn-sm btn-primary">Next</button>
                                            </div>
                                        </div>

                                        <div className="createCampaignForm">
                                            <div className="form-group mb-4 position-relative">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <label>What we're offering</label>
                                                    <a className="text-primary small" onClick={e => {e.preventDefault(); toggleOfferingToolTip(true)}}><u>Tips on writing a great description</u></a>
                                                </div>
                                                {campaignType == "gifting" && (
                                                    <span className="small text-muted d-block mb-3">This is value exchange only.<br />Explain what you're offering in exchange for content e.g. company background, product offering, product info, etc.</span>   
                                                )}
                                                {campaignType == "commercial" && (
                                                    <span className="small text-muted d-block mb-3">There is a budget included for this offer.<br />Please include the range of budget available (e.g. £500 - £10,000) as well as the product or experience you are offering. People will bid according to how much they love you and want to work with you.</span>   
                                                )}
                                                {campaignType == "cause" && (
                                                    <span className="small text-muted d-block mb-3">This is a campaign for a cause that is close to both you and your potential influencer's hearts.</span>   
                                                )}
                                                <textarea type="text" rows="6" maxLength="500" className="form-control pe-5" onChange={e => {updateSignupDataState(e); setOfferingCount(e.target.value.length)}} id="offering" defaultValue={campaign ? campaign.offering : null}></textarea>
                                                <span className="position-absolute smaller end-0 bottom-0 text-muted me-2 mb-1">{offeringCount}/500</span>                                
                                            </div>

                                            <hr className="my-4" />

                                            {campaignType == "commercial" && (
                                                <>
                                                <Row>
                                                    <Col xs={12} sm={6}>
                                                        <Form.Label>Campaign Currency</Form.Label>
                                                        <InputGroup className="mb-4">
                                                            <Form.Select className="w-25" id="currency" value={signupData.currency} onChange={event => updateSignupDataState(event)} >
                                                                <option>GBP</option>
                                                                <option>USD</option>
                                                            </Form.Select>
                                                        </InputGroup>  
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={6}>
                                                        <Form.Label>Min. Budget</Form.Label>
                                                        <InputGroup className="mb-4">
                                                            <Form.Control type="text" id="currency" className="w-25" value={signupData.currency == "GBP" ? "£" : "$"} readOnly />
                                                            <Form.Control type="number" id="minimumBudget" className="w-75" value={signupData.minimumBudget} onChange={event => updateSignupDataState(event)} />
                                                        </InputGroup>  
                                                    </Col>
                                                    <Col xs={12} sm={6}>
                                                        <Form.Label>Max. Budget</Form.Label>
                                                        <InputGroup className="mb-4">
                                                            <Form.Control type="text" id="currency" className="w-25" value={signupData.currency == "GBP" ? "£" : "$"} readOnly />
                                                            <Form.Control type="number" id="maximumBudget" className="w-75" value={signupData.maximumBudget} onChange={event => updateSignupDataState(event)} />
                                                        </InputGroup>  
                                                    </Col>
                                                </Row> 
                                                </>
                                            )}

                                            <hr className="mb-4 mt-0" />

                                            <div className="form-group">
                                                <label>Campaign dates:</label>
                                                <span className="small text-muted d-block mb-3">This is the period that your campaign will be live on the platform for users to bid on. By default, the campaign will run for 14 days from the day you publish it unless edited.</span>                

                                                <Row>
                                                    <div className="col-12 col-md-4 form-group mb-4">
                                                        <label className="text-muted">Start date</label>
                                                        <input type="datetime-local" className="form-control" onChange={updateSignupDataState} defaultValue={campaign && campaign.startDate ? new Date(campaign.startDate*1000).toISOString().slice(0,16) : null} id="startDate"/>
                                                    </div>
                                                    <div className="col-12 col-md-4 form-group mb-4">
                                                        <label className="text-muted">End date</label>
                                                        <input type="datetime-local" className="form-control" onChange={updateSignupDataState} defaultValue={campaign && campaign.endDate ? new Date(campaign.endDate*1000).toISOString().slice(0,16) : null} id="endDate"/>
                                                    </div>
                                                </Row>
                                            </div>

                                            <hr className="mb-4 mt-0" />

                                            <div className="form-group mb-4">
                                                <Form.Check inline label="Is this an event?" name="eventCheck" type="checkbox" id="isEvent" defaultChecked={campaign ? campaign.event : false} onChange={() => setEvent((isEvent) => !isEvent)}/>      

                                                <Row className={`mt-4 ${!isEvent ? "hide" : ""}`}>
                                                    <div className="col form-group">
                                                        <label>Event start (optional)</label>
                                                        <input type="datetime-local" className="form-control" onChange={updateSignupDataState} defaultValue={campaign && campaign.eventDetails.eventStartDate ? new Date(campaign.eventDetails.eventStartDate*1000).toISOString().slice(0,16) : null} id="eventStartDate"/>
                                                    </div>
                                                    <div className="col form-group">
                                                        <label>Duration in Days (optional)</label>
                                                        <input type="text" className="form-control" onChange={updateSignupDataState} defaultValue={campaign ? campaign.eventDetails.duration : null} id="duration"/>
                                                    </div>
                                                    <div className="col form-group">
                                                        <label>City (optional)</label>
                                                        <input type="text" className="form-control" onChange={updateSignupDataState} defaultValue={campaign ? campaign.eventDetails.location : null} id="location"/>
                                                    </div>
                                                </Row>
                                            </div>

                                            <hr className="my-4" />

                                            <div className="form-group">
                                                <label>Contact information required?</label>
                                                <span className="small text-muted d-block mb-3">Please state any contact information you require from Bidders for delivery tracking </span> 

                                                <Form.Check inline label="Delivery address" name="contactInfo" type="checkbox" id="deliveryAddress" onChange={updateSignupDataState} defaultChecked={campaign ? campaign.requirements.deliveryAddress : false} />
                                                <Form.Check inline label="Email address" name="contactInfo" type="checkbox" id="email" onChange={updateSignupDataState} defaultChecked={campaign ? campaign.requirements.email : false} />
                                                <Form.Check inline label="Phone number" name="contactInfo" type="checkbox" id="phoneNumber" onChange={updateSignupDataState} defaultChecked={campaign ? campaign.requirements.phoneNumber : false} />
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="lookingFor">
                                <Modal centered show={showLookingForToolTip} onHide={() => toggleLookingForToolTip(false)}>
                                    <Modal.Header closeButton className="modal-success">
                                        <Modal.Title className='h6'>Tips for writing a great description</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Footer className="border-0 rounded success">
                                        <Form className='justify-content-between align-items-center w-100 m-0'>
                                        Example description:
                                        <p className='m-0 ps-2'>* Our recommendation is to keep it broad. This will encourage all types of bids from different categories and areas</p>
                                        <p className='m-0 ps-2'>* You can limit if you want, but expect bids to be lower e.g. Fashion influencers to post 1 grid and 1 story</p>
                                        <br />
                                        <h3 className="font-bold">Example description:</h3>
                                        <br />
                                        <p className='m-0 ps-2'>Creators with a passion for travel who will create authentic content throughout their trip to create awareness of this amazing destination and encourages people to visit.</p>
                                        <br />
                                        <p className='m-0 ps-2'>We'd like to see posts on Instagram and YouTube to highlight unique features of the trip, which include but are not limited to:</p>
                                        <br />
                                        <p className='m-0 ps-2'>- The amazing views from the venue.</p><p>- Subtle shots of branded elements, such as the event logo (not blatant)</p>
                                        <p className='m-0 ps-2'>- Photos of perfectly served food and drink with a focus on the atmosphere and presentation</p>
                                        </Form>
                                    </Modal.Footer>
                                </Modal>
                                <div className="px-lg-4 pt-md-4 mt-4 px-md-0">
                                    <div className="p-4 bg-white shadow rounded-xl">
                                        <div className="d-flex align-items-center justify-content-between mb-4">
                                            <h5 className="mb-0">Looking for</h5>
                                            <div>
                                                <button onClick={() => {setTabID("offering")}} className="btn btn-sm btn-outline-primary me-2">Previous</button>
                                                <button disabled={!signupData.lookingFor || (!signupData.trackingTags.length && !["sampling","content"].includes(campaignType))} onClick={() => {setTabID("targeting")}} className="btn btn-sm btn-primary">Next</button>
                                            </div>
                                        </div>

                                        <div className="createCampaignForm">

                                            <div className="form-group mb-4 position-relative">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <label className="text-nowrap me-5">What we're looking for</label>
                                                    <a className="text-primary small" onClick={e => {e.preventDefault(); toggleLookingForToolTip(true)}}><u>Tips on writing a great description</u></a>
                                                </div>
                                                <span className="small text-muted d-block mb-3">Give details of the ideal personality or desired style of content you're looking for</span>   
                                                
                                                <textarea type="text" rows="6" maxLength="500" className="form-control pe-5" onChange={e => {updateSignupDataState(e); setLookingForCount(e.target.value.length)}} id="lookingFor" defaultValue={campaign ? campaign.lookingFor : null}></textarea>
                                                <span className="position-absolute smaller end-0 bottom-0 text-muted me-2 mb-1">{lookingForCount}/500</span>                                
                                            </div>

                                            <hr className="mb-4 mt-0" />

                                            {!["sampling","content"].includes(campaignType) && (
                                                <div className="form-group">
                                                    <label>Content tracking tags:</label>
                                                    <span className="small text-muted d-block mb-3">Enter up to three @handles and #hashtags which you would like to track for campaign related content</span> 

                                                    <div className="form-check form-switch mb-3">
                                                        <input type="checkbox" checked={trackingTags.map(tag => tag.tag).includes("@theinfluenceroomofficial")} className="form-check-input" id="custom-switch" onChange={e => {updateTrackingTags(e.target.checked ? "@theinfluenceroomofficial" : "",3)}} />
                                                        <label htmlFor="custom-switch" className="form-check-label">Include @theinfluenceroomofficial</label> 
                                                    </div>

                                                    <Row className="gy-4">
                                                        {[0,1,2].map(index => (
                                                            <div key={index} className="col-12 col-md-6 form-group">
                                                                <div className="input-group">
                                                                    <input type="text" id="trackingTag-1" className="form-control" placeholder="#hashtags or @handles to track" value={trackingTags.length >= index + 1 ? trackingTags[index].tag : ""} onChange={e => {updateTrackingTags(e.target.value,index)}} />
                                                                    <Dropdown className="socialDropdown">
                                                                        <Dropdown.Toggle className="btn py-0 pb-1 px-1" />
                                                                        <Dropdown.Menu>
                                                                            <div className={`socialDropdownItem ${trackingTags[index] && trackingTags[index].socials.includes("facebook") ? 'Selected' : ''}`} onClick={() => {updateTrackingTags("facebook",index,true)}}><img src={socialIcons.facebook} width="16px" height="16px" />Facebook</div>
                                                                            <div className={`socialDropdownItem ${trackingTags[index] && trackingTags[index].socials.includes("twitter") ? 'Selected' : ''}`} onClick={() => {updateTrackingTags("twitter",index,true)}}><img src={socialIcons.twitter} width="16px" height="16px" />Twitter/X</div>
                                                                            <div className={`socialDropdownItem ${trackingTags[index] && trackingTags[index].socials.includes("youtube") ? 'Selected' : ''}`} onClick={() => {updateTrackingTags("youtube",index,true)}}><img src={socialIcons.youtube} width="16px" height="16px" />YouTube</div>
                                                                            <div className={`socialDropdownItem ${trackingTags[index] && trackingTags[index].socials.includes("tiktok") ? 'Selected' : ''}`} onClick={() => {updateTrackingTags("tiktok",index,true)}}><img src={socialIcons.tiktok} width="16px" height="16px" />TikTok</div>
                                                                            <div className={`socialDropdownItem ${trackingTags[index] && trackingTags[index].socials.includes("instagram") ? 'Selected' : ''}`} onClick={() => {updateTrackingTags("instagram",index,true)}}><img src={socialIcons.instagram} width="16px" height="16px" />Instagram</div>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        {trackingTags.map(tag => tag.tag).includes("@theinfluenceroomofficial") && 
                                                            <div className="col-12 col-md-6 form-group">
                                                                <input type="text" id="trackingTag-default" className="form-control" value={"@theinfluenceroomofficial"} readOnly />
                                                            </div>
                                                        }
                                                    </Row>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="targeting">
                                <div className="px-lg-4 pt-md-4 mt-4 px-md-0">
                                    <div className="p-4 bg-white shadow rounded-xl">
                                        <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap">
                                            <h5 className="mb-3 mb-md-0">Targeting</h5>
                                            <div className="text-nowrap">
                                                <button onClick={() => {setTabID("lookingFor")}} className="btn btn-sm btn-outline-primary me-2">Previous</button>
                                                <button disabled={(!["sampling","content"].includes(campaignType) && !signupData.socials.length) || !signupData.ages.length || (signupData.influencerSegments ? !signupData.influencerSegments.length : true)} onClick={() => {setTabID("customisation")}} className="btn btn-sm btn-primary">Next</button>
                                            </div>
                                        </div>

                                        <div className="createCampaignForm">
                                            <div className="text-muted mb-3">Any criteria selected will not restrict bids being made but will be shown to users as a guide</div>

                                            {!["sampling","content"].includes(campaignType) && (
                                                <div className="form-group mb-4">
                                                    <label>Social Channel</label>
                                                    <span className="small text-muted d-block mb-3">Please select your preferred social channel that you'd like content creating on:</span>                

                                                    <ToggleButtonSocialChannel />
                                                </div>
                                            )}

                                            <hr className="my-3" />

                                            <div className="form-group mb-4">
                                                <label>Age Range</label>
                                                <span className="small text-muted d-block mb-3">Please select what the preferred Influencer age range your campaign is aimed at</span>                

                                                <ToggleButtonAgeRange />
                                            </div>

                                            <hr className="my-3" />

                                            <div className="form-group mb-4">
                                                <label>Minimum Follower Count</label>
                                                <span className="small text-muted d-block mb-3">Please select what the preferred minimum following count for your bidders should be</span>                

                                                <input type="range" id="followerCount" className="w-50 mx-2" min={1} max={1000000} step={1000} value={signupData.followerCount === 0 ? calculateMinimumFollowers() : signupData.followerCount} onChange={event => {setFollowerCount(event.target.value);updateSignupDataState(event.target.value,"followerCount")}} />  {getSmallNumber(parseInt(signupData.followerCount),0)}
                                            </div>
                                            
                                            <hr className="my-3" />

                                            <div className="form-group mb-4">
                                                <label>Minimum Engagement Rate</label>
                                                <span className="small text-muted d-block mb-3">Please select what the preferred minimum engagement rate for your bidders should be</span>                

                                                <input type="range" id="engagementRate" className="w-50 mx-2" min={0} max={10} step={0.1} value={signupData.engagementRate} onChange={event => updateSignupDataState(event.target.value,"engagementRate")} />  {signupData.engagementRate}%
                                            </div>

                                            <hr className="my-3" />

                                            <div className="form-group">
                                                <Form.Check inline label="Add Terms and Conditions?" name="termsCondition" type="checkbox" id="addTermsAndConditions" defaultChecked={campaign? (campaign && campaign.terms.length > 0 ? true : false) : (brand && brand.terms.length > 0 ? true : false) } onChange={() => setTermsAndConditions((addTermsAndConditions) => !addTermsAndConditions)} />      
                                                <span className="small text-muted d-block">Tick to add custom Terms and Conditions to this campaign </span>
                                            </div>
                                            <div className={`form-group ${!addTermsAndConditions ? "hide" : ""}`}>
                                                <label>Add Terms and Conditions <span className="text-danger">*</span></label>
                                                <textarea type="text" rows="6" className="form-control" id="terms" onChange={updateSignupDataState} defaultValue={campaign && campaign.terms.length > 0 ? campaign.terms : (brand ? brand.terms : null )}></textarea>
                                            </div>

                                            <hr className="my-3" />

                                            <div className="form-group">
                                                <Form.Check inline label="Restrict to community only?" name="communityCampaign" type="checkbox" id="communityOnly" defaultChecked={campaign ? campaign.communityOnly : false} onChange={updateSignupDataState} />      
                                                <span className="small text-muted d-block">Tick to restrict campaign to community members only </span>  
                                            </div>

                                            <hr className="my-3" />
                                            
                                            <div className="form-group mb-4">
                                                <label>Influencer Segments</label>
                                                <span className="small text-muted d-block mb-3">Please select if you want to restrict the campaign to these labelled influencers</span>

                                                <ToggleButtonInfluencerSegments />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>

                            <Tab.Pane eventKey="customisation">
                                <div className="px-lg-4 pt-md-4 mt-4 px-md-0">
                                    <div className="p-4 bg-white shadow-sm rounded-xl">
                                        <div className="d-flex align-items-center justify-content-between mb-4">
                                            <h5 className="mb-0">Customisation</h5>
                                            <div>
                                                <button onClick={() => {setTabID("targeting")}} className="btn btn-sm btn-outline-primary me-2">Previous</button>
                                                {campaign && !cloneCampaign ? (
                                                    <button className="btn btn-sm btn-success me-2" onClick={(event) => {saveCampaign(event,"update")}}>Update</button>
                                                ) :
                                                (
                                                    <>
                                                    <button className="btn btn-sm btn-outline-primary me-2" onClick={(event) => {saveCampaign(event,"draft")}}>Save as draft</button>
                                                    <button className="btn btn-sm btn-success" onClick={(event) => {saveCampaign(event,"preview")}}>Preview</button>
                                                    </>
                                                )}
                                            </div>
                                        </div>

                                        <div className="createCampaignForm">
                                            <div className="form-group">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <label className="text-nowrap me-5">Tips for success</label>
                                                </div>
                                                <span className="small text-muted d-block mb-3">Enter up to four tips which you would like to share with influencers.</span> 
                                                <Row className="gy-4">
                                                    <div className="col-12 col-md-6 form-group position-relative">
                                                        <input type="text" maxLength="60" id="tips-1" className="form-control" placeholder="Add tips here..." value={tips[0] ? tips[0] : ""} onChange={e => {updateTips(e.target.value,0); updateTipsCount(e.target.value.length, 0)}}/>
                                                        <span className="position-absolute smaller end-0 bottom-1 text-muted me-2 mb-1">{tipsCount[0]}/60</span>
                                                    </div>
                                                    <div className="col-12 col-md-6 form-group position-relative">
                                                        <input type="text" maxLength="60" id="tips-2" className="form-control" placeholder="Add tips here..." value={tips[1] ? tips[1] : ""} onChange={e => {updateTips(e.target.value,1); updateTipsCount(e.target.value.length, 1)}}/>
                                                        <span className="position-absolute smaller end-0 bottom-1 text-muted me-2 mb-1">{tipsCount[1]}/60</span>
                                                    </div>
                                                    <div className="col-12 col-md-6 form-group position-relative">
                                                        <input type="text" maxLength="60" id="tips-3" className="form-control" placeholder="Add tips here..." value={tips[2] ? tips[2] : ""} onChange={e => {updateTips(e.target.value,2); updateTipsCount(e.target.value.length, 2)}}/>
                                                        <span className="position-absolute smaller end-0 bottom-1 text-muted me-2 mb-1">{tipsCount[2]}/60</span>
                                                    </div>
                                                    <div className="col-12 col-md-6 form-group position-relative">
                                                        <input type="text" maxLength="60" id="tips-4" className="form-control" placeholder="Add tips here..." value={tips[3] ? tips[3] : ""} onChange={e => {updateTips(e.target.value,3); updateTipsCount(e.target.value.length, 3)}}/>
                                                        <span className="position-absolute smaller end-0 bottom-1 text-muted me-2 mb-1">{tipsCount[3]}/60</span>
                                                    </div>
                                                </Row>
                                            </div>

                                            <hr className="my-4" />
                                            
                                            <div className="form-group">
                                                <label>Usage Rights Required?</label>
                                                <span className="small text-muted d-block mb-3">Ask any potential bidders to confirm they are happy to give you rights to their content created for this campaign.</span> 
                                                    <Form.Check inline label="Usage Rights Required" name="usageRights" type="checkbox" id="usageRights" onChange={updateSignupDataState} defaultChecked={campaign ? campaign.requirements.usageRights : false} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Container>
        </Tab.Container>
    </>
  );
}

class CreateCampaignView extends Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        isLoaded: false,
        entitlements: [],
        brand: null
      };
      if (props.id){
        this.getCampaign(props.id)
      }
      else{
        this.getEntitlements()
      }
      this.getBrand()
    }
  
    componentDidMount(){
      window.scrollTo(0, 0);
    }

    async getEntitlements() {
        var response = await apiCall("brand/entitlements",{ "method" : "GET" });
        if (response["success"]) {
          this.setState({
            entitlements : response["success"]["entitlements"]
          });
        }
        else {
          this.setState({
            error: response["failure"]["error"]
        });
        }
        this.setState({
          isLoaded: true
        });
      }
  
    async getCampaign(id) {
      var response = await apiCall(`campaign?id=${id}`,{ "method" : "GET" });
      if (response["success"]) {
        this.setState({
          campaign : response["success"]["campaign"]
        });
      }
      else {
        this.setState({
            error: response["failure"]["error"]
      });
      }
      this.setState({
        isLoaded: true
      });
    }

    async getBrand() {
        var response = await apiCall(`brand`,{ "method" : "GET" });
        if (response["success"]) {
          this.setState({
            brand : response["success"]["brand"]
          });
        }
        else {
          this.setState({
              error: response["failure"]["error"]
        });
        }
        this.setState({
          isLoaded: true
        });
      }

    render() {
        const { error, isLoaded, campaign, entitlements, brand } = this.state;
        if (error) {
          return <div className="text-danger text-center h-100 d-flex align-items-center justify-content-start px-4 px-md-0 py-5 flex-column fs-5 bg-secondary"><img src={NoAccess} className="noAccessImg my-5 rounded-xl shadow" /><div>Error: {error}</div></div>;
        } else if (!isLoaded) {
            return <FullPageSpinner />;
        } else {
            if (entitlements.length == 0 && !campaign){
                return (
                    <div className="d-flex align-items-center justify-content-center h-100 w-100">You have no campaign entitlements available. Please purchase more or speak with Customer Support to create more campaigns.</div>
                );
            }
            else if (campaign && campaign.active && this.props.data.sessionType != "impersonation" && this.props.currentProfile.type != "admins" ) {
                return (
                    <p>This is a live campaign and so cannot be modified. Please speak with Customer Support if you need to make changes to this campaign after it has been published.</p>
                );
            }
            else {
                return (
                    <>
                    {campaign ? <CreateCampaignFunction campaign={campaign} cloneCampaign={this.props.cloneCampaign} brand={brand} /> : <CreateCampaignFunction brand={brand} /> }
                    </>
                );
            }
        }
    }
}

export default function CreateCampaign() {
    let [searchParams] = useSearchParams();
    var campaignID = null;
    var cloneCampaign = false;
    const navigate = useNavigate()
    const { data, currentProfile } = useAuthContext();

    if (searchParams.get('id')) {
        campaignID = searchParams.get('id');
    }
    else {
        if (searchParams.get('cloneTargetID')) {
            campaignID = searchParams.get('cloneTargetID')
            cloneCampaign = true
        }
    }
  
    return (
      <div className="createCampaign">
        <Topbar/>
        <div className="pageName">My Campaigns</div>
        <Container className="fullscreenWithTopBar px-0" fluid>
                <CreateCampaignView id={campaignID} cloneCampaign={cloneCampaign} navigate={navigate} data={data} currentProfile={currentProfile} />
        </Container>
        <Footer />
      </div>
    );
}