import React from 'react';
import Badge from 'react-bootstrap/Badge';

export function getStatusPill(state,colour=null) {
    switch(state) {
      case 'Accepted':
        return <Badge className='me-2' pill bg={colour ? colour : "success"}>Accepted</Badge>
      case 'Pending':
        return <Badge className='me-2' pill bg={colour ? colour : "light"} text="dark">Pending</Badge>
      case 'Completed':
        return <Badge className='me-2' pill bg={colour ? colour : "completed"}>Completed</Badge>
      case 'Rejected':
        return <Badge className='me-2' pill bg={colour ? colour : "danger"}>Declined</Badge>
      case 'Cancelled':
        return <Badge className='me-2' pill bg={colour ? colour : "cancelled"}>Cancelled</Badge>
      case 'In Progress':
        return <Badge className='me-2' pill bg={colour ? colour : "success"}>In Progress</Badge>
      case 'Marked as Complete':
        return <Badge className='me-2' pill bg={colour ? colour : "success"}>Marked as Complete</Badge>
      case 'Email Verified':
        return <Badge className='me-2' pill bg={colour ? colour : "success"}>Email Verified</Badge>
      default:
        return <Badge className='me-2' pill bg={colour ? colour : "light"} text="dark">{state}</Badge>
    }
  }

export function getRatingPill(rating) {
  switch(rating) {
    case 1:
      return <Badge className='me-2' id="veryBad" pill>1 / 5</Badge>
    case 2:
      return <Badge className='me-2' id="bad" pill>2 / 5</Badge>
    case 3:
      return <Badge className='me-2' id="neutral" pill>3 / 5</Badge>
    case 4:
      return <Badge className='me-2' id="good" pill>4 / 5</Badge>
    case 5:
      return <Badge className='me-2'  id="veryGood" pill>5 / 5</Badge>
    default:
      return <Badge className='me-2' pill bg={"light"} text="dark">Unrated</Badge>
  }
}

export function getSubscriptionBadge(packageName) {
  switch(packageName.toLowerCase()) {
    case 'V1 Legacy':
      return <Badge bg="oldPlatform">{packageName}</Badge>
    case 'Lite':
      return <Badge bg="lite">{packageName}</Badge>
    case 'Pro':
      return <Badge bg="pro">{packageName}</Badge>
    case 'Enterprise':
      return <Badge bg="enterprise">{packageName}</Badge>
    default:
      return <Badge bg="other">{packageName}</Badge>
  }
}

export function getDeliveryStatus(status) {
  return (
    <div className="container">
      <div className="row justify-content-between">
        <div className={`order-tracking completed`}>
          <span className="is-complete"></span>
          <p>Accepted</p>
        </div>
        <div className={`order-tracking ${["sent","received"].includes(status) && "completed"}`}>
          <span className="is-complete"></span>
          <p>Sent</p>
        </div>
        <div className={`order-tracking ${status == "received" && "completed"}`}>
          <span className="is-complete"></span>
          <p>Received</p>
        </div>
      </div>
    </div>
  )
}