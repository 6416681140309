import React, { useEffect, useState } from "react";
import { Container, Col, Row, Form, FloatingLabel, Tab, InputGroup, Table, Nav, Badge, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useImageContext } from "../../hooks/useImageContext";
import { useAuthContext } from "../../hooks/useAuthContext";
import { getImageUrl, updateImage, clientUploadImage } from "../../helpers/images";
import { apiCall, PageScroller } from './../../helpers/api'
import { capitalise } from "../../helpers/format";
import { useSearchParams } from "react-router-dom";
import { getDifference } from "../../helpers/dates";
import { getStatusPill } from "../../helpers/status";
import Topbar from "../../components/topbar/Topbar";
import BrandDetails from './../../images/brand-details-blue.svg';
import Social from './../../images/social.svg';
import CampaignHistory from './../../images/campaign-history.svg';
import blueLeftArrow from './../../images/blue-left-arrow.svg';
import searchBlue from './../../images/search-blue.svg';
import eyeDark from './../../images/eye-dark.svg';
import greenCheck from "./../../images/check-green.svg"
import bidsDark from './../../images/bids-dark.svg';
import AlertModal from "../../components/alerts/alert";
import { getIndustries, getCountries, getSubscriptions } from "../../helpers/dropdowns"
import AsyncSelect, { useAsync } from 'react-select/async';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import './Brands.css';

function ShowCampaignLine(props){
  const campaignStart = getDifference(props.campaign.startDate)
  const campaignEnd = getDifference(props.campaign.endDate)

  return (
    <tr>
      <td>
          <div className="d-flex align-items-center">
              <img src={getImageUrl(props.providers,props.campaign.images[0],["w_100","c_scale"])} width="36px" height="36px" className="object-fit-cover rounded me-2" /> 
              <div className="text-wrap">{props.campaign.name}</div>
          </div>
      </td>
      <td><span className="tags text-nowrap"><img height="14px" src={eyeDark} className="me-1" /> {props.campaign.campaignStats.uniqueViews}</span></td>
      <td><span className="tags text-nowrap"><img height="10px" src={bidsDark} className="me-1 align-baseline" /> {props.campaign.bidStats.total}</span></td>
      <td><span className="tags text-nowrap">{props.campaign.bidStats.total > 0 && props.campaign.campaignStats.uniqueViews > 0 ? (props.campaign.bidStats.total / props.campaign.campaignStats.uniqueViews * 100)+"%" : "0%" }</span></td>
      {props.campaign.active == true ? ( 
      <>
        {campaignStart.future && (
          <td><Badge pill bg="warning" text="dark">Scheduled</Badge></td>
        )}
        {!campaignStart.future && campaignEnd.future && (
          <td><Badge pill bg="success" text="dark">Live</Badge></td>
        )}
        {!campaignEnd.future && (
          <td><Badge pill bg="danger" text="dark">Expired</Badge></td>
        )}
      </>
      ): (
        <>
        <td>{props.campaign.archived ? <Badge pill bg="danger" text="dark">Archived</Badge> : <Badge pill bg="danger" text="dark">Draft</Badge>}</td>
        </>
      )}
      <td>{new Date(props.campaign.startDate*1000).toLocaleString('en-US', { day:"numeric", month:"long", year:"numeric" })}</td>
      <td>{new Date(props.campaign.endDate*1000).toLocaleString('en-US', { day:"numeric", month:"long", year:"numeric" })}</td>
    </tr>
  )
}

export default function BrandSettings() {  
  let [searchParams] = useSearchParams();
  const brandID = searchParams.get('id');
  const [brand, setBrand] = useState(null);
  const [brandCampaigns, setBrandCampaigns] = useState([]);
  const [brandCampaignIDs, setBrandCampaignIDs] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false)
  const { providers } = useImageContext();  
  const { currentProfile } = useAuthContext();
  const [imageUploaded, setImageUploaded] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadingImage, setUploadingImage] = useState(false)
  const [refreshCounter, setRefreshCounter] = useState(0)
  const [bidCriteriaData, setBidCriteriaData] = useState({"genders":[],"socials":[],"ages":{"min":18,"max":100},"followers":0,"engagementRate":0,"interests":[],"femaleFollowers":0,"maleFollowers":0,"countries":[],"followerCountries":[],"followerAges":{"18-24":0,"25-34":0,"35-44":0,"45-59":0,"60+":0},"primaryInterest":[],"minimumAudience":{},"contentRating":0})
  const [showAlert, setAlert] = useState({"display":"toast","visible":false,"title":"","content":""});
  const availableDashboardPages = [{label:"Contract ROI",value:"contract"},{label:"Campaign Results",value:"campaigns"},{label:"Influencer Analysis",value:"influencers"}]
  const [availableManagers, setAvailableManagers] = useState({"":"None"})

  const contractMetrics = [{label:"Savings ROI", value:"savingsRoi"}, {label:"ROI", value:"roi"}, {label:"Content Savings", value:"contentSavings"}, {label:"Cost Per Engagement", value:"costPerEngagement"}, {label:"Cost Per Impressions", value:"costPerImpression"}, {label:"Cost Per Influencer", value:"costPerInfluencer"}, {label:"Cost Per Asset", value:"costPerAsset"}, {label:"Hours Saved", value:"hoursSaved"}, {label:"Cost of Time Saved", value:"fteCost"}, {label:"Savings ROI per Month", value:"roiPerMonth"}, {label:"Hours Saved per Month", value:"contractHoursSavedOverTime"}, {label:"Influencer Fee Savings", value:"influencerSavingsOverTime"}]
  const campaignsMetrics = [{label:"Total Bids", value:"bids"}, {label:"Accepted Influencers", value:"acceptedInfluencers"}, {label:"Created Assets", value:"assets"}, {label:"Story Posts", value:"stories"}, {label:"Video/Static Posts", value:"posts"}, {label:"Total Reach", value:"reach"}, {label:"Total Impressions", value:"impressions"}, {label:"Total Engagement", value:"engagement"}, {label:"Engagement Rate", value:"engagementRate"}, {label:"Post Reach Rate", value:"postReach"}, {label:"Page Post Types", value:"postTypes"}, {label:"Post Frequency", value:"postFrequency"}, {label:"Impressions/Reach Frequency", value:"impressionsReachFrequency"}, {label:"Total Impressions by Campaign", value:"impressionsOverTime"}, {label:"Total Bids by Campaign", value:"bidsOverTime"}]
  const influencersMetrics = [{label:"Influencers Analysis", value:"influencers"}, {label:"Follower Locations", value:"followerLocations"}, {label:"Follower Genders", value:"followerGenders"}, {label:"Hours Saved per Month", value:"influencersHoursSavedOverTime"}, {label:"Follower Ages", value:"followerAges"}, {label:"Returning vs New Influencers", value:"returningInfluencers"}]
  const brandPagesAndMetrics = [{"page": "contract", "metrics": contractMetrics}, {"page": "campaigns", "metrics": campaignsMetrics}, {"page": "influencers", "metrics": influencersMetrics}]
  
  var view = searchParams.get('view');
  if (view == null){
    view = "details";
  }
  const [tabID, setTabID] = useState(view);
  const animatedComponents = makeAnimated();
  // Countries / Industry Options
  const industries = getIndustries()
  const countries = getCountries()
  const { packages, plans } = getSubscriptions(); 

  const changeTab = (tabName) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set('view', tabName);
    setTabID(tabName)
    let newUrl = window.location.origin + window.location.pathname + '?' + currentUrlParams.toString();
    window.history.replaceState({path:newUrl},'',newUrl);
  }

  const getBrand = async (brandID,showLoading=true) => {
    if (showLoading){
      setIsLoaded(false);
    }
    var response = await apiCall(`brand?id=${brandID}`,{ "method" : "GET" });
    if (response["success"]){
      setBrand(response["success"]["brand"]);
      setIsLoaded(true);
    }	
  };

  const getBrandBidCriteria = async (brandID) => {
    var response = await apiCall(`brand/bidCriteria?id=${brandID}`,{ "method" : "GET" });
    if (response["success"]){
      setBidCriteriaData(response["success"]["bidCriteria"]);
    }	
  };

  const loginAsBrand = async (brandID) => {
    var response = await apiCall(`impersonate`,{ "method" : "POST", "data" : {"id":brandID,"type":"brands","returnPage":window.location.pathname+window.location.search} });
    if (response["success"]){
      window.location.href="/Campaigns";
    }
    else {
      setAlert({"display":"toast","visible":true,"title":"Failed to login as brand","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
  };

  function updateBrandData(props,id) {
    var tempBrand = brand;
    if (["country","industry"].includes(id)){
      tempBrand[id] = props.value
    }
    else if (["plan","package","managed"].includes(id)){
      tempBrand.subscription[id] = props.value
    }
    else if (id == "dashboard"){
      tempBrand.dashboard.overrides[props.target.id] = props.target.value
    }
    else if (id == "dashboardPages") {
      tempBrand.dashboard.meta["brandPages"] = props.map(item => item.value);
      brandPagesAndMetrics.map(brandPages => {
        if (!tempBrand.dashboard.meta.brandPages.includes(brandPages.page)) {
          brandPages.metrics.map(metric => {
            if (tempBrand.dashboard.meta.visiblePagesMetrics.includes(metric.value)) {
              tempBrand.dashboard.meta.visiblePagesMetrics.splice(tempBrand.dashboard.meta.visiblePagesMetrics.indexOf(metric.value),1)
            }
          })
        }
      })
    }
    else if (id == "dashboardMetrics") {
      tempBrand.dashboard.meta["visiblePagesMetrics"] = props;
    }
    else if (id == "manager"){
      tempBrand["manager"] = props.value
    }
    else {
      tempBrand[props.target.id] = props.target.value;
    }
    setBrand(tempBrand);
    setRefreshCounter(refreshCounter + 1);
  }

  async function getBrandManagers(managerInput) {
    var response = await apiCall(`admin/brandManagers?search=${managerInput}`,{ "method" : "GET" });
    var tempManagers = structuredClone(availableManagers)
    if (response["success"]){
      var managers = [{"label":`None`,"value":""}]
      response["success"]["managers"].forEach(manager => {
        managers.push({"label":`${manager.firstName} ${manager.lastName}`,"value":manager._id})
        tempManagers[manager._id] = `${manager.firstName} ${manager.lastName}`
      })
      setAvailableManagers(tempManagers)
      return managers
    }
  }

  //returns list of selected brand pages which have at least 1 metric selected
  function checkUnselectedPageMetrics(selectedBrandPages) {
    return selectedBrandPages.filter(selectedbrandPage => 
      (brandPagesAndMetrics.filter(brandPages =>
        {if (brandPages.page.includes(selectedbrandPage)) {
          return (brandPages.metrics.filter(metric => brand.dashboard.meta.visiblePagesMetrics.includes(metric.value)).length >= 1)
        }}
      )).length >= 1
    )
  }

  function updateBidCriteriaData(name, value){
    var tempBidCriteria = structuredClone(bidCriteriaData)
    if (name == "min" || name == "max"){
      tempBidCriteria.ages[name] = Number(value)
    }
    else if (name == "followerCountries"){
      tempBidCriteria[name] = value.map(country => (country.value))
      var tempMinimumAudience = []
      tempBidCriteria[name].forEach(function(country){
        if (Object.hasOwn(tempBidCriteria["minimumAudience"],country)){
          tempMinimumAudience[country] = tempBidCriteria["minimumAudience"][country]
        }
        else {
          tempMinimumAudience[country] = 0
        }
      })
      tempBidCriteria["minimumAudience"] = tempMinimumAudience

    }
    else if (name == "followerAges"){
      tempBidCriteria["followerAges"][value.target.id] = Number(value.target.value)
    }
    else if (["countries","genders","interests","socials","primaryInterest"].includes(name)){
      tempBidCriteria[name] = value
    }
    else if (name == "minimumAudience"){
      tempBidCriteria["minimumAudience"][value.target.id] = Number(value.target.value)
    }
    else {
      tempBidCriteria[name] = Number(value)
    }
    setBidCriteriaData(tempBidCriteria)
  }

  async function submitBidCriteriaUpdates(){
    var response = await apiCall(`brand/bidCriteria?id=${brandID}`,{ "method" : "PUT", "data" : bidCriteriaData });
    if (response["success"]){
        setAlert({"display":"toast","visible":true,"title":"Brand Bid Criteria Updated","content":`${brand.name} Updated Successfully`,"className":"bg-success text-white"})
    }
    else if (response["failure"]) {
        setAlert({"display":"toast","visible":true,"title":"Could not update brand bid criteria","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
  }

  async function submitUpdates(){
    var profileImage = brand.profileImage;
    if (!imageUploaded && selectedImage) {
      var profileImage = await clientUploadImage(selectedImage,setAlert)
      setImageUploaded(true)
    }
    var response = await apiCall(`brand?id=${brandID}`,{ "method" : "PUT", "data" : {"name":brand.name,"country":brand.country,"industry":brand.industry,"website":brand.website,"profileImage":profileImage,"favouriteInfluencers":brand.favouriteInfluencers,"manager":brand.manager,"terms":brand.terms,"subscription":brand.subscription,"maxAcceptedBids":Number(brand.maxAcceptedBids)} });
    if (response["success"]){
        setAlert({"display":"toast","visible":true,"title":"Brand Updated","content":`${brand.name} Updated Successfully`,"className":"bg-success text-white"})
    }
    else if (response["failure"]) {
        setAlert({"display":"toast","visible":true,"title":"Could not update brand","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
  }

  async function createDashboard(updateExisting=false){
    var response = await apiCall(`admin/brandDashboard?id=${brandID}`, { "method" : updateExisting ? "PUT" : "POST", "data" : {"overrides":brand.dashboard.overrides, "brandPages":updateExisting ? checkUnselectedPageMetrics(brand.dashboard.meta.brandPages) : availableDashboardPages.map(item => item.value), "visiblePagesMetrics":updateExisting ? brand.dashboard.meta.visiblePagesMetrics : [...contractMetrics, ...campaignsMetrics, ...influencersMetrics].map(metric => metric.value) }});

    if (response["success"]){
        if (updateExisting){ 
          setAlert({"display":"toast","visible":true,"title":"Brand Dashboard Updated","content":`${brand.name} Dashboard Updated Successfully`,"className":"bg-success text-white"})
        }
        else {
          setAlert({"display":"toast","visible":true,"title":"Brand Dashboard Created","content":`${brand.name} Dashboard Created Successfully`,"className":"bg-success text-white"})
          getBrand(brandID)
        }
    }
    else if (response["failure"]) {
      if (updateExisting){ 
        setAlert({"display":"toast","visible":true,"title":"Could not update brand dashboard","content":response["failure"]["error"],"className":"bg-danger text-white"})
      }
      else {
        setAlert({"display":"toast","visible":true,"title":"Could not create brand dashboard","content":response["failure"]["error"],"className":"bg-danger text-white"})
      }
    }
  }

  async function queueDashboard(){
    var response = await apiCall(`admin/queueBrandDashboard?id=${brandID}`, { "method" : "POST", "data" : {} });
    if (response["success"]){
        setAlert({"display":"toast","visible":true,"title":"Brand Dashboard Queued","content":`${brand.name} Dashboard Queued Successfully`,"className":"bg-success text-white"})
        getBrand(brandID)
    }
    else if (response["failure"]) {
        setAlert({"display":"toast","visible":true,"title":"Could not queue brand dashboard","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
  }

  async function disableBrand(reverse=false){
    var response;
    if (reverse){
        response = await apiCall('admin/enableUser',{ "method" : "POST", "data" : {id: brand._id, type: "brand"} });
        if (response["success"]){
            setAlert({"display":"toast","visible":true,"title":"Brand Enabled","content":`${brand.name} enabled successfully`,"className":"bg-success text-white"})
        }
        else if (response["failure"]) {
            setAlert({"display":"toast","visible":true,"title":"Could not enable brand","content":response["failure"]["error"],"className":"bg-danger text-white"})
        }
    }
    else {
        response = await apiCall('admin/disableUser',{ "method" : "POST", "data" : {id: brand._id, type: "brand"} });
        if (response["success"]){
            setAlert({"display":"toast","visible":true,"title":"Brand Disabled","content":`${brand.name} disabled successfully`,"className":"bg-success text-white"})
        }
        else if (response["failure"]) {
            setAlert({"display":"toast","visible":true,"title":"Could not disable brand","content":response["failure"]["error"],"className":"bg-danger text-white"})
        }
    }
    getBrand(brand._id,false)
  }

  function ToggleButtonGroupMetrics() {    
    return (
      <>
        <ToggleButtonGroup type="checkbox" value={brand.dashboard.meta.visiblePagesMetrics} onChange={(metrics) => updateBrandData(metrics, "dashboardMetrics")} id="metrics">

          {brand.dashboard.meta.brandPages.includes("contract") && (
            <label className="mt-2">Contract ROI</label>
          )}
          {brand.dashboard.meta.brandPages.includes("contract") && (
            contractMetrics.map(metric => (
              <ToggleButton key={metric.label} id={`tbg-btn-${metric.value}`} className="me-2 mb-2" value={metric.value}>{metric.label}</ToggleButton>
            ))
          )}

          {brand.dashboard.meta.brandPages.includes("campaigns") && (
            <label className="mt-2">Campaigns Results</label>
          )}
          {brand.dashboard.meta.brandPages.includes("campaigns") && (
            campaignsMetrics.map(metric => (
              <ToggleButton key={metric.label} id={`tbg-btn-${metric.value}`} className="me-2 mb-2" value={metric.value}>{metric.label}</ToggleButton>
            ))
          )}

          {brand.dashboard.meta.brandPages.includes("influencers") && (
          <label className="mt-2">Influencer Analysis</label>
          )}
          {brand.dashboard.meta.brandPages.includes("influencers") && (
            influencersMetrics.map(metric => (
              <ToggleButton key={metric.label} id={`tbg-btn-${metric.value}`} className="me-2 mb-2" value={metric.value}>{metric.label}</ToggleButton>
            ))
          )}
      </ToggleButtonGroup>
      </>
    );
  }

  async function setState(event,result) { 
    result = await result;
    var tempCampaigns = structuredClone(brandCampaigns)
    var tempIDs = structuredClone(brandCampaignIDs)
    for (var x in result["campaigns"]) {
      if (!brandCampaignIDs.includes(result["campaigns"][x]["_id"])) {
        tempCampaigns.push(result["campaigns"][x])
        tempIDs.push(result["campaigns"][x]["_id"])
      }
    }
    setBrandCampaignIDs(tempIDs)
    setBrandCampaigns(tempCampaigns)
  }

  useEffect(() => {
    getBrandBidCriteria(brandID)
    getBrand(brandID);
  }, []);
  if (!isLoaded) {
    return <FullPageSpinner />;
  }
  else {
    return (
      <div className="bg-page brandSettings">
        <Topbar />
        <div className="pageName">Brand Settings</div>
        <Container className="fullscreenWithTopBar top-0" fluid>
          <Tab.Container id="campaign-tab" activeKey={tabID} onSelect={(key) => changeTab(key)}>
          <Container className="px-0 px-md-3">
            <AlertModal modal={showAlert} onClose={() => setAlert({"display":"toast","visible":false,"title":"","content":""})} />
            <Row className="d-flex justify-content-center mb-4">
              <Col sm={12} md={4} lg={4} xl={3}>
                <div className="pt-lg-4 mb-lg-5 px-3 px-md-0 position-sticky top-0">
                  <div className="d-flex align-items-center justify-content-between mt-4 mb-4 pb-2">
                    <h1 className="h3 text-primary tragedyRegularFont fw-700 mb-0">Brand Settings</h1>
                  </div>

                  <div className="mb-3 pb-2">
                    <div className="bg-light shadow rounded-xl">
                      <div className="p-3">
                        <div className="d-flex align-items-start">
                          <img src={getImageUrl(providers,brand.profileImage,["w_80","c_scale"])} width="50px" height="50px" className="object-fit-cover rounded me-2 border" /> 
                          <div>
                            <div className="fw-bold pt-1">{brand.name}</div>
                            <Badge pill bg="success" text="dark">{brand.manager ? availableManagers.hasOwnProperty(brand.manager) ? availableManagers[brand.manager] : "Unknown" : "N/A"}</Badge>
                          </div>
                        </div>
                      </div>
                      <div className="p-3 border-top small">
                        <div><span className="fw-500">Contract:</span> N/A</div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-3 pb-2">
                    <Nav variant="tabs" className="border-0">
                      <div className="bg-light shadow rounded-xl brandSettingsNav w-100">
                        <Nav.Link eventKey="details" className="p-3 d-flex align-items-center justify-content-between w-100">
                            <span className="d-flex align-items-center">
                              <Nav.Item>
                                <img src={BrandDetails} className="me-2" /> Brand Details
                              </Nav.Item>
                            </span>
                            <span>
                              <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="#A7A7A7"/>
                              </svg>
                            </span>
                        </Nav.Link>
                        <Nav.Link eventKey="bidCriteria" className="p-3 d-flex align-items-center justify-content-between w-100">
                            <span className="d-flex align-items-center">
                              <Nav.Item>
                                <img src={Social} className="me-2" /> Recommended Bid Criteria
                              </Nav.Item>
                            </span>
                            <span>
                              <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="#A7A7A7"/>
                              </svg>
                            </span>
                        </Nav.Link>
                        {brand.associatedAccounts.length > 0 && (
                        <Nav.Link eventKey="associatedAccounts" className="p-3 d-flex align-items-center border-top justify-content-between w-100">
                            <span className="d-flex align-items-center">
                              <Nav.Item>
                                <img src={BrandDetails} className="me-2" /> Associated Accounts
                              </Nav.Item>
                            </span>
                            <span>
                              <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="#A7A7A7"/>
                              </svg>
                            </span>
                        </Nav.Link>
                        )}
                        <Nav.Link eventKey="history" className="p-3 d-flex align-items-center justify-content-between border-top w-100">
                          <span className="d-flex align-items-center">
                            <Nav.Item>
                              <img src={CampaignHistory} className="me-2" /> Campaign History
                            </Nav.Item>
                          </span>
                          <span>
                            <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" fill="#A7A7A7"/>
                            </svg>
                          </span>
                        </Nav.Link>
                        {currentProfile.type == "admins" && (
                        <Nav.Link eventKey="dashboard" className="p-3 d-flex align-items-center justify-content-between border-top w-100">
                          <span className="d-flex align-items-center">
                            <Nav.Item>
                              <img src={CampaignHistory} className="me-2" /> Dashboard
                            </Nav.Item>
                          </span>
                          <span>
                            <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" fill="#A7A7A7"/>
                            </svg>
                          </span>
                        </Nav.Link>
                        )}
                      </div>
                    </Nav>
                  </div>
                    
                  {currentProfile.type == "admins" && 
                    <div className="mb-3 pb-2">
                      <div className="bg-light shadow rounded-xl brandSettingsNav">
                        <div className={`p-3 d-flex align-items-center justify-content-between ${brand.active && "text-danger"}`}>
                            { brand.active ? <a className="dropdown-item text-danger fw-bold" onClick={e => disableBrand()}>Suspend</a> : <a className="dropdown-item text-success fw-bold" onClick={e => disableBrand(true)}>Enable</a> }
                            <span>
                            {brand.active ? (
                              <svg width="18" height="18" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.2179 2.78217C10.9836 1.54781 9.31599 0.846273 7.57266 0.825523L7.57213 0.825H7.5C5.72969 0.825 4.03197 1.52825 2.78006 2.78005L2.78005 2.78006C1.52828 4.03194 0.825 5.72988 0.825 7.5C0.825 9.27012 1.52825 10.968 2.78005 12.2199L2.78006 12.22C4.03194 13.4717 5.72988 14.175 7.5 14.175C9.27012 14.175 10.968 13.4717 12.2199 12.22L12.2199 12.2199C13.4717 10.9681 14.175 9.27012 14.175 7.5V7.49981C14.1731 5.7301 13.4693 4.03351 12.2179 2.78217ZM3.78862 3.57622C4.78153 2.63785 6.0932 2.11189 7.45825 2.10359L7.50006 2.10358C8.71196 2.1032 9.88633 2.51264 10.8339 3.26232L3.26056 10.8356C2.44731 9.8059 2.03617 8.51271 2.10849 7.19817L1.93375 7.18855L2.10849 7.19817C2.18427 5.82029 2.78565 4.52392 3.78861 3.57623L3.78862 3.57622ZM11.2114 11.4239C10.2184 12.3623 8.90674 12.8882 7.54166 12.8965L7.49995 12.8965C6.28804 12.8969 5.11367 12.4875 4.16615 11.7378L11.7394 4.1645C12.5527 5.19421 12.9638 6.48739 12.8915 7.80194C12.8157 9.17982 12.2144 10.4762 11.2114 11.4239L11.2114 11.4239Z" fill="#FF0000" stroke="#FF0000" strokeWidth="0.35"/>
                              </svg>
                            ) : (
                                <img src={greenCheck}/>
                            )}
                            </span>
                          </div>
                      </div>
                    </div>
                  }

                  <div className="mb-2">
                    <div className="bg-light shadow rounded-xl brandSettingsNav">
                      <a href="/Administration/Brands" className="p-3 d-flex align-items-center">
                        <img src={blueLeftArrow} className="me-2" /> Back to Brands
                      </a>
                    </div>
                  </div>

                  <div className="bg-primary shadow rounded-xl brandSettingsNav">
                    <a className="p-3 d-flex align-items-center justify-content-between" onClick={() => loginAsBrand(brand._id)}>
                      <span className="text-white">Login as {brand.name}</span>                     
                      <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="white"/>
                      </svg>
                    </a>
                  </div>
                </div>
              </Col>
              <Col sm={8} md={8} lg={8} xl={9} xxl={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="details">
                    <div className="pb-2 d-block d-md-none">
                      <div className="d-flex align-items-center mb-2 py-2 px-3">
                        <a className="backArrow" onClick={() => setTabID(null)}>
                          <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                          </svg>
                        </a>
                        <div className="sectionTitle w-100 text-center">Brand Details</div>
                      </div>
                    </div>
                    <div className="px-0 pt-lg-4 mb-lg-5 mt-md-4">
                      <div className="p-4 px-0 px-md-3 bg-white shadow rounded-xl">

                        <h4 className="mt-4 mb-2 ms-4 d-none d-md-block">Brand Details</h4>

                      <div className="text-center mb-4">
                        <div className="d-inline-block position-relative">
                          <img src={selectedImage ? selectedImage.url : getImageUrl(providers,brand.profileImage,["w_150","c_scale"])} width="150px" height="150px" className="object-fit-cover rounded m-3 border bg-light p-2" />
                          <label htmlFor="account-upload">
                            <a className="position-absolute end-0 bottom-0">
                              {uploadingImage ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <svg width="36" height="36" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="11.5" cy="11.5" r="11" fill="white" stroke="#E3E3E3"/>
                                <path d="M15.9851 8.90875L14.0912 7.01483C14.0707 6.99506 14.0383 6.99506 14.0179 7.01483L7.74132 13.2916C7.73366 13.2992 7.72864 13.309 7.72688 13.3197L7.72073 13.3128L7 16L9.68722 15.2793L9.68038 15.2724H9.68032C9.69099 15.2707 9.70085 15.2656 9.70844 15.258L15.9852 8.98207C16.0049 8.9616 16.0049 8.92921 15.9852 8.90874L15.9851 8.90875Z" fill="#797979"/>
                                <path d="M7.5 13L10 15.5" stroke="white"/>
                              </svg>}
                            </a>
                          </label>
                          <Form.Control type="file" id="account-upload" className="fileUploadBtn position-absolute end-0 bottom-0" accept=".png, .jpg, .jpeg, .webp" onChange={(e) => {updateImage(e,setSelectedImage,setUploadingImage,setAlert); setImageUploaded(false)}}/>
                        </div>
                      </div>

                      <div className="mx-4">
                        <Row>
                          <Col sm={12} md={6}>
                            <FloatingLabel label="Brand Name" className="mb-3" >
                              <Form.Control type="text" value={brand.name} id="name" className="rounded-0 border-0 border-bottom" onChange={(e) => updateBrandData(e)} />
                            </FloatingLabel>
                          </Col>
                          <Col sm={12} md={6}>
                            <FloatingLabel label="Website" className="mb-3" >
                              <Form.Control type="text" value={brand.website} id="website" className="rounded-0 border-0 border-bottom" onChange={(e) => updateBrandData(e)} />
                            </FloatingLabel>
                          </Col>
                        </Row>

                        <Row>
                          <Col sm={12} md={6}>   
                            <div className="form-group customFloating mb-3">
                              <label>Primary Industry</label>
                                <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    defaultValue={[{label:brand.industry,value:brand.industry}]}
                                    options={industries} 
                                    id="industry" 
                                    onChange={ (choice) => updateBrandData(choice,"industry") } 
                                />
                              </div>
                            </Col>
                            <Col sm={12} md={6}>
                              <div className="form-group customFloating mb-3">
                                <label>Country</label>
                                <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    defaultValue={[{label:brand.country,value:brand.country}]}
                                    options={countries} 
                                    id="country" 
                                    onChange={ (choice) => updateBrandData(choice,"country") } 
                                />
                              </div>
                            </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={6}>
                            <FloatingLabel label="Contract Dates" className="mb-3" >
                              <Form.Control type="text" readOnly={currentProfile.type != "admins"} defaultValue="N/A" className="rounded-0 border-0 border-bottom" />
                            </FloatingLabel>
                          </Col>
                          <Col sm={12} md={6}>
                            <FloatingLabel label="Max Accepted Bids per Campaign" className="mb-3" >
                              <Form.Control type={brand.maxAcceptedBids > 0 ? "number" : "text"} id="maxAcceptedBids" readOnly={currentProfile.type != "admins"} value={brand.maxAcceptedBids > 0 ? brand.maxAcceptedBids : 'Unlimited'} onChange={(e) => updateBrandData(e)} className="rounded-0 border-0 border-bottom" />
                            </FloatingLabel>
                          </Col>
                        </Row>

                        <Row className="mt-4">
                          <h6 className="text-blue">Subscription</h6>
                          <Col sm={12} md={6} lg={4}>
                            <div className="form-group customFloating mb-3">
                                <label>Plan</label>
                                <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    defaultValue={[{label:brand.subscription.plan,value:brand.subscription.plan}]}
                                    options={plans} 
                                    isDisabled={currentProfile.type != "admins"}
                                    id="plan" 
                                    onChange={ (choice) => updateBrandData(choice,"plan") } 
                                />
                            </div>
                          </Col>
                          
                          <Col sm={12} md={6} lg={4}>
                            <div className="form-group customFloating mb-3">
                                <label>Packages</label>
                                <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    defaultValue={[{label:brand.subscription.package,value:brand.subscription.package}]}
                                    options={packages.filter(function(subscriptionPackage) {return brand.subscription.plan ? subscriptionPackage.plan.includes(brand.subscription.plan.toLowerCase()) : null})} 
                                    isDisabled={currentProfile.type != "admins"}
                                    id="package"
                                    onChange={ (choice) => updateBrandData(choice,"package") } 
                                />
                            </div>
                          </Col>

                          <Col sm={12} md={6} lg={4}>
                            <div className="form-group customFloating mb-3">
                                <label>Managed</label>
                                <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    defaultValue={[brand.subscription.managed ? {label:"Yes",value:brand.subscription.managed} : {label:"No",value:brand.subscription.managed}]}
                                    options={[{label:"Yes",value:true},{label:"No",value:false}]} 
                                    isDisabled={currentProfile.type != "admins"}
                                    id="managed" 
                                    onChange={ (choice) => updateBrandData(choice,"managed") } 
                                />
                            </div>
                          </Col>
                        </Row>

                        <Row className="mt-4">
                          <h6 className="text-blue">Managed by</h6>
                          <Col sm={12} md={6}>
                            <AsyncSelect 
                              cacheOptions
                              defaultOptions
                              placeholder="Manager"
                              closeMenuOnSelect={true}
                              value={[{"value":brand.manager,"label":availableManagers[brand.manager]}]}
                              loadOptions={getBrandManagers}
                              onChange={(choice) => updateBrandData(choice,"manager")}
                            />
                          </Col>
                        </Row>

                        <Row className="mt-4">
                          <h6 className="text-blue">Brand Settings</h6>
                          <Col sm={12} md={6}>
                              <label>Add Terms and Conditions</label>
                              <textarea id="terms" rows={6} value={brand.terms} onChange={(e) => updateBrandData(e)} className="form-control rounded-0 border-0 border-bottom" />
                          </Col>
                        </Row>

                        <div className="d-flex align-items-center justify-content-between mt-4 mb-4">
                          {/* <span className="text-muted">Member since November 2019</span> */}
                          <button type="button" onClick={() => submitUpdates()} className="btn btn-primary">Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="bidCriteria">
                    <div className="pb-2 d-block d-md-none">
                      <div className="d-flex align-items-center mb-2 py-2 px-3">
                        <a className="backArrow" onClick={() => setTabID(null)}>
                          <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                          </svg>
                        </a>
                        <div className="sectionTitle w-100 text-center">Recommend Bid Criteria</div>
                      </div>
                    </div>
                    <div className="px-0 pt-lg-4 mb-lg-5 mt-md-4">
                      <div className="p-4 px-0 px-md-3 bg-white shadow rounded-xl">

                        <h4 className="mt-4 mb-2 ms-4 d-none d-md-block">Recommend Bid Criteria</h4>

                        {/* {"genders":[],"socials":[],"minAge":18,"followers":0,"engagementRate":0,"interests":[],"femaleFollowers":0,"maleFollowers":0,"countries":[],"followerCountries":[],"followerAges":{"18-24":0,"25-34":0,"35-44":0,"45-59":0,"60+":0}} */}
                      <div className="mx-4">
                        <Row>
                          <Col sm={12} md={6}>
                            <FloatingLabel label="Minimum Followers" className="mb-3" >
                              <Form.Control type="number" min={0} value={bidCriteriaData.followers} id="followers" className="rounded-0 border-0 border-bottom" onChange={(e) => updateBidCriteriaData("followers",e.target.value)} />
                            </FloatingLabel>
                          </Col>
                          <Col sm={12} md={6}>
                            <FloatingLabel label="Minimum Engagement Rate (%)" className="mb-3" >
                              <Form.Control type="number" min={0} max={100} step={0.1} value={bidCriteriaData.engagementRate} id="engagementRate" className="rounded-0 border-0 border-bottom" onChange={(e) => updateBidCriteriaData("engagementRate",e.target.value)} />
                            </FloatingLabel>
                          </Col>
                          <Col sm={12} md={6}>
                            <FloatingLabel label="Minimum Age" className="mb-3" >
                              <Form.Control type="number" value={bidCriteriaData.ages.min} min={18} max={100} id="min" className="rounded-0 border-0 border-bottom" onChange={(e) => updateBidCriteriaData("min",e.target.value)} />
                            </FloatingLabel>
                          </Col>
                          <Col sm={12} md={6}>
                            <FloatingLabel label="Maximum Age" className="mb-3" >
                              <Form.Control type="number" value={bidCriteriaData.ages.max} min={18} max={100} id="max" className="rounded-0 border-0 border-bottom" onChange={(e) => updateBidCriteriaData("max",e.target.value)} />
                            </FloatingLabel>
                          </Col>
                          <hr />
                          <Col sm={12} className="metricsButton">
                            <label className="mb-2">Primary Interest</label>
                            <ToggleButtonGroup type="checkbox" value={bidCriteriaData.primaryInterest} onChange={(choice) => updateBidCriteriaData("primaryInterest",choice)} id="interests">
                              {industries.map(interest => 
                                <ToggleButton key={interest.value} id={`tbg-btn-${interest.value}`} className="me-2 mb-2 btn-sm" value={interest.value}>{interest.label}</ToggleButton>
                              )}
                            </ToggleButtonGroup>
                          </Col>
                          <Col sm={12} className="metricsButton">
                            <label className="mb-2">Interests</label>
                            <ToggleButtonGroup type="checkbox" value={bidCriteriaData.interests} onChange={(choice) => updateBidCriteriaData("interests",choice)} id="interests">
                              {industries.map(interest => 
                                <ToggleButton key={interest.value} id={`tbg-btn-${interest.value}`} className="me-2 mb-2 btn-sm" value={interest.value}>{interest.label}</ToggleButton>
                              )}
                            </ToggleButtonGroup>
                          </Col>
                          <hr />
                          <Col sm={12} md={6} className="metricsButton">
                            <label className="mb-2">Socials</label>
                            <ToggleButtonGroup type="checkbox" value={bidCriteriaData.socials} onChange={(choice) => updateBidCriteriaData("socials",choice)} id="socials">
                              {[{label:"Facebook",value:"facebook"},{label:"X / Twitter",value:"twitter"},{label:"YouTube",value:"youtube"},{label:"Instagram",value:"instagram"},{label:"TikTok",value:"tiktok"}].map(social => 
                                <ToggleButton key={social.value} id={`tbg-btn-${social.value}`} className="me-2 mb-2 btn-sm" value={social.value}>{social.label}</ToggleButton>
                              )}
                            </ToggleButtonGroup>
                          </Col>
                          <Col sm={12} md={6} className="metricsButton">
                            <label className="mb-2">Genders</label>
                            <ToggleButtonGroup type="checkbox" value={bidCriteriaData.genders} onChange={(choice) => updateBidCriteriaData("genders",choice)} id="genders">
                              {[{label:"Male",value:"male"},{label:"Female",value:"female"},{label:"Other",value:"other"}].map(gender => 
                                <ToggleButton key={gender.value} id={`tbg-btn-${gender.value}`} className="me-2 mb-2 btn-sm" value={gender.value}>{gender.label}</ToggleButton>
                              )}
                            </ToggleButtonGroup>
                          </Col>
                          <hr />
                          <Col sm={4}>
                            <label className="mb-2">Minimum Content Rating</label>
                            <div className="d-flex align-items-center small text-muted mb-3"><input type="range" id="contentRating" className="mx-2" min={0} max={5} step={1} value={bidCriteriaData.contentRating} onChange={(e) => updateBidCriteriaData("contentRating",e.target.value)} />  {parseInt(bidCriteriaData.contentRating)}+</div>
                          </Col>
                          <Col sm={4}>
                            <FloatingLabel label="Male Followers (%)" className="mb-3" >
                              <Form.Control type="number" min={0} max={100} value={bidCriteriaData.maleFollowers} id="maleFollowers" className="rounded-0 border-0 border-bottom" onChange={(e) => updateBidCriteriaData("maleFollowers",e.target.value)} />
                            </FloatingLabel>
                          </Col>
                          <Col sm={4}>
                            <FloatingLabel label="Female Followers (%)" className="mb-3" >
                              <Form.Control type="number" min={0} max={100} value={bidCriteriaData.femaleFollowers} id="femaleFollowers" className="rounded-0 border-0 border-bottom" onChange={(e) => updateBidCriteriaData("femaleFollowers",e.target.value)} />
                            </FloatingLabel>
                          </Col>
                          <hr />
                          <Col sm={12} md={6} className="metricsButton">
                            <label className="mb-2">Countries</label>
                            <ToggleButtonGroup type="checkbox" value={bidCriteriaData.countries} onChange={(choice) => updateBidCriteriaData("countries",choice)} id="countries">
                              {[{label:"United Kingdom",value:"uk"},{label:"United States",value:"us"}].map(country => 
                                <ToggleButton key={country.value} id={`tbg-btn-${country.value}`} className="me-2 mb-2 btn-sm" value={country.value}>{country.label}</ToggleButton>
                              )}
                            </ToggleButtonGroup>
                          </Col>
                          <Col sm={12} md={6}>
                          <label className="mb-2">Follower Countries</label>
                            <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    components={animatedComponents}
                                    defaultValue={[]}
                                    value={countries.filter(function(country) { return bidCriteriaData.followerCountries.includes(country.value) })}
                                    options={countries} 
                                    id="followerCountries" 
                                    onChange={ (choice) => updateBidCriteriaData("followerCountries",choice) } 
                            />
                          </Col>
                          <Col sm={12} md={6}>
                            <label className="mb-2">Follower Ages</label>
                            <div className="d-flex align-items-center small text-muted mb-3"><div>18 - 24 Following</div><input type="range" id="18-24" className="mx-2" min={0} max={100} step={1} value={bidCriteriaData.followerAges["18-24"]} onChange={(e) => updateBidCriteriaData("followerAges",e)} />  {parseInt(bidCriteriaData.followerAges["18-24"])}%</div>
                            <div className="d-flex align-items-center small text-muted mb-3"><div>25 - 34 Following</div><input type="range" id="25-34" className="mx-2" min={0} max={100} step={1} value={bidCriteriaData.followerAges["25-34"]} onChange={(e) => updateBidCriteriaData("followerAges",e)} />  {parseInt(bidCriteriaData.followerAges["25-34"])}%</div>
                            <div className="d-flex align-items-center small text-muted mb-3"><div>35 - 44 Following</div><input type="range" id="35-44" className="mx-2" min={0} max={100} step={1} value={bidCriteriaData.followerAges["35-44"]} onChange={(e) => updateBidCriteriaData("followerAges",e)} />  {parseInt(bidCriteriaData.followerAges["35-44"])}%</div>
                            <div className="d-flex align-items-center small text-muted mb-3"><div>45 - 59 Following</div><input type="range" id="45-59" className="mx-2" min={0} max={100} step={1} value={bidCriteriaData.followerAges["45-59"]} onChange={(e) => updateBidCriteriaData("followerAges",e)} />  {parseInt(bidCriteriaData.followerAges["45-59"])}%</div>
                            <div className="d-flex align-items-center small text-muted mb-3"><div>60+ Following</div><input type="range" id="60+" className="mx-2" min={0} max={100} step={1} value={bidCriteriaData.followerAges["60+"]} onChange={(e) => updateBidCriteriaData("followerAges",e)} />  {parseInt(bidCriteriaData.followerAges["60+"])}%</div>
                          </Col>
                          <Col sm={12} md={6}>
                            <label className="mb-2">Minimum Follower Audience</label>
                            {bidCriteriaData.followerCountries.map(country => (
                              <div key={country} className="d-flex align-items-center small text-muted mb-3"><div>{country}</div><input type="range" className="mx-2" min={0} max={100} step={1} id={country} value={bidCriteriaData.minimumAudience[country] ? bidCriteriaData.minimumAudience[country] : 0} onChange={(e) => updateBidCriteriaData("minimumAudience",e)} />  {parseInt(bidCriteriaData.minimumAudience[country] ? bidCriteriaData.minimumAudience[country] : 0)}%</div>
                            ))}
                          </Col>
                        </Row>

                        <div className="d-flex align-items-center justify-content-between mt-4 mb-4">
                          <button type="button" onClick={() => submitBidCriteriaUpdates()} className="btn btn-primary">Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="associatedAccounts">
                    <div className="pb-2 d-block d-md-none">
                        <div className="d-flex align-items-center mb-2 py-2 px-3">
                          <a className="backArrow" onClick={() => setTabID(null)}>
                            <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                            </svg>
                          </a>
                          <div className="sectionTitle w-100 text-center">Associated Accounts</div>
                        </div>
                      </div>
                      <div className="px-0 pt-lg-4 mb-lg-5 mt-md-4">
                        <div className="p-4 px-0 px-md-3 bg-white shadow rounded-xl">

                          <div className="associatedAccounts">
                            <h4 className="my-4 ms-4 pb-2 d-none d-md-block">Associated Accounts</h4>
                              </div>
                              {brand.associatedAccounts.map(account => (
                                  <p className="ms-4" key={account.email}>{account.name} ({account.email})</p>
                              ))}
                          </div>
                      </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="history">
                    <div className="pb-2 d-block d-md-none">
                      <div className="d-flex align-items-center mb-2 py-2 px-3">
                        <a className="backArrow" onClick={() => setTabID(null)}>
                          <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                          </svg>
                        </a>
                        <div className="sectionTitle w-100 text-center">Campaign History</div>
                      </div>
                    </div>
                    <div className="px-0 pt-lg-4 mb-lg-5 mt-md-4">
                      <div className="p-4 px-0 px-md-3 bg-white shadow rounded-xl">

                          <div className="brandsHistory">

                              <div className="my-4 ms-4 pb-2 d-none d-md-block">
                                  <h4>Campaign History</h4>
                                {/* Search campaigns not working so has been removed as this page will require filtering in future <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< */}
                                <InputGroup className="me-4 w-auto hide">
                                    <Form.Control placeholder="Search" className="border-end-0 form-control-sm" aria-label="Search" />
                                    <InputGroup.Text className="bg-white border-end">
                                        <a><img src={searchBlue} /></a>
                                    </InputGroup.Text>
                                </InputGroup>
                            </div>

                            <div className="mx-4">
                                <Row>
                                    <Col sm={12} md={12} lg={4} className="mb-3 mb-md-4">
                                        <div className="border rounded p-3 d-flex align-items-center justify-content-between">
                                            <div>
                                                <div className="fw-500">Total campaigns paid for</div>
                                                {/* <span>on Jan-Dec 2022 </span> */}
                                            </div>
                                            <h1 className="mb-0 text-blue fw-bold">{brand.credits.length + brand.stats.totalCampaigns}</h1>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={12} lg={4} className="mb-3 mb-md-4">
                                        <div className="border rounded p-3 d-flex align-items-center justify-content-between">
                                            <div>
                                                <div className="fw-500">Total campaigns published</div>
                                                {/* <span>on Jan-Dec 2022 </span> */}
                                            </div>
                                            <h1 className="mb-0 text-blue fw-bold">{brand.stats.publishedCampaigns}</h1>
                                        </div>
                                    </Col>
                                    <Col sm={12} md={12} lg={4} className="mb-3 mb-md-4">
                                        <div className="border rounded p-3 d-flex align-items-center justify-content-between">
                                            <div>
                                                <div className="fw-500">Remaining campaign credits</div>
                                                {/* <span> to use before 31st Dec 2022 </span> */}
                                            </div>
                                            <h1 className="mb-0 text-blue fw-bold">{brand.credits.length}</h1>
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={4} md={4} lg={2} className="mb-3 mb-md-4">
                                        <div className="border rounded py-3 h-100 d-flex align-items-center flex-column justify-content-center">
                                            <h4 className="mb-0 text-blue fw-bold">{brand.stats.totalCampaigns}</h4>
                                            <div className="text-muted small text-center">Created</div>
                                        </div>
                                    </Col>
                                    <Col xs={4} md={4} lg={2} className="mb-3 mb-md-4">
                                        <div className="border rounded py-3 h-100 d-flex align-items-center flex-column justify-content-center">
                                            <h4 className="mb-0 fw-bold">{brand.stats.draftCampaigns}</h4>
                                            <div className="text-muted small text-center">Draft</div>
                                        </div>
                                    </Col>
                                    <Col xs={4} md={4} lg={2} className="mb-3 mb-md-4">
                                        <div className="border rounded py-3 h-100 d-flex align-items-center flex-column justify-content-center">
                                            <h4 className="mb-0 fw-bold">{brand.stats.scheduledCampaigns}</h4>
                                            <div className="text-muted small text-center">Scheduled</div>
                                        </div>
                                    </Col>
                                    <Col xs={4} md={4} lg={2} className="mb-3 mb-md-4">
                                        <div className="border rounded py-3 h-100 d-flex align-items-center flex-column justify-content-center">
                                            <h4 className="mb-0 fw-bold">{brand.stats.liveCampaigns}</h4>
                                            <div className="text-muted small text-center">Live</div>
                                        </div>
                                    </Col>

                                    <Col xs={4} md={4} lg={2} className="mb-3 mb-md-4">
                                        <div className="border rounded py-3 h-100 d-flex align-items-center flex-column justify-content-center">
                                            <h4 className="mb-0 fw-bold">{brand.stats.expiredCampaigns}</h4>
                                            <div className="text-muted small text-center">Expired</div>
                                        </div>
                                    </Col>
                                    <Col xs={4} md={4} lg={2} className="mb-3 mb-md-4">
                                        <div className="border rounded py-3 h-100 d-flex align-items-center flex-column justify-content-center">
                                            <h4 className="mb-0 fw-bold">{brand.stats.archivedCampaigns}</h4>
                                            <div className="text-muted small text-center">Archived</div>
                                        </div>
                                    </Col>
                                </Row>
                                
                                <h5 className="mb-4 mt-5">History</h5>
                                <Table className="border-transparent">
                                    <thead className="small">
                                        <tr>
                                        <th>Campaign</th>
                                        <th>Views</th>
                                        <th>Bids</th>
                                        <th>Views &gt; Bids %</th>
                                        <th>Status</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        </tr>
                                    </thead>
                                    <tbody className="small text-nowrap">
                                        {brandCampaigns.map(campaign => (
                                          <ShowCampaignLine key={campaign._id} campaign={campaign} providers={providers} />
                                        ))}
                                    </tbody>
                                </Table>
                                <PageScroller endpoint={`brandCampaigns?id=${brandID}`} pageSize={100} setState={setState} />

                            </div>
                            
                        </div>

                    </div>
                  </div>
                  </Tab.Pane>

                  {currentProfile.type == "admins" && (
                  <Tab.Pane eventKey="dashboard">
                    <div className="pb-2 d-block d-md-none">
                        <div className="d-flex align-items-center mb-2 py-2 px-3">
                          <a className="backArrow" onClick={() => setTabID(null)}>
                            <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                            </svg>
                          </a>
                          <div className="sectionTitle w-100 text-center">Dashboard Settings</div>
                        </div>
                      </div>
                      <div className="px-0 pt-lg-4 mb-lg-5 mt-md-4">
                        <div className="p-4 px-3 bg-white shadow-sm rounded-xl border">

                          <div className="associatedAccounts d-flex justify-content-between align-items-center m-4 pb-2 d-flex justify-content-between align-items-center me-4">
                            <h4 className="mb-0 d-none d-md-block">Dashboard Settings</h4>
                            <a href={`/Dashboard?brandID=${brandID}`} className="btn btn-sm btn-outline-primary mb-4 mb-md-0" target="_blank">View Dashboard</a>
                          </div>
                          <div className="mx-4">
                            <Row>
                              <Col sm={12} md={12} lg={6} className="mb-3 mb-md-4">
                                  <div className="border rounded p-3 d-flex align-items-center justify-content-between">
                                      <div>
                                          <div className="fw-500">Current Status</div>
                                      </div>
                                      <h3 className="mb-0 text-success fw-bold">{brand.dashboard.existing ? capitalise(brand.dashboard.meta.state) : "Not Created"}</h3>
                                  </div>
                              </Col>
                              <Col sm={12} md={12} lg={6} className="mb-3 mb-md-4">
                                  <div className="border rounded p-3 d-flex align-items-center justify-content-between">
                                      <div>
                                          <div className="fw-500">Next Update</div>
                                      </div>
                                      <h3 className="mb-0 text-success fw-bold">{brand.dashboard.existing ? (getDifference(brand.dashboard.meta.nextUpdate).future ? `${getDifference(brand.dashboard.meta.nextUpdate).difference} ${getDifference(brand.dashboard.meta.nextUpdate).unit}` : "Now") : "Never"}</h3>
                                  </div>
                              </Col>
                            </Row>
                            <h6 className="text-blue mb-3 mt-2">Brand Values</h6>
                            <Row className="mb-3">
                              <Col sm={12} md={6}>
                                {brand.dashboard.overrides.contractCost == brand.dashboard.defaults.contractCost ? getStatusPill("default") : getStatusPill("custom","cancelled")}
                                <FloatingLabel label="Contract Cost" className="mb-3">
                                <Form.Control type="text" value={brand.dashboard.overrides.contractCost} id="contractCost" className="rounded-0 border-0 border-bottom" onChange={(e) => updateBrandData(e,"dashboard")} /> 
                                </FloatingLabel>  
                              </Col>
                              <Col sm={12} md={6}>
                                {brand.dashboard.overrides.staticPostCost == brand.dashboard.defaults.staticPostCost ? getStatusPill("default") : getStatusPill("custom","cancelled")}
                                <FloatingLabel label="Static Post Cost" className="mb-3" >
                                  <Form.Control type="text" value={brand.dashboard.overrides.staticPostCost} id="staticPostCost" className="rounded-0 border-0 border-bottom" onChange={(e) => updateBrandData(e,"dashboard")} /> 
                                </FloatingLabel>
                              </Col>
                              <Col sm={12} md={6}>
                                {brand.dashboard.overrides.storyPostCost == brand.dashboard.defaults.storyPostCost ? getStatusPill("default") : getStatusPill("custom","cancelled")}
                                <FloatingLabel label="Story Post Cost" className="mb-3" >
                                  <Form.Control type="text" value={brand.dashboard.overrides.storyPostCost} id="storyPostCost" className="rounded-0 border-0 border-bottom" onChange={(e) => updateBrandData(e,"dashboard")} /> 
                                </FloatingLabel>
                              </Col>
                              <Col sm={12} md={6}>
                                {brand.dashboard.overrides.influencerHoursSaved == brand.dashboard.defaults.influencerHoursSaved ? getStatusPill("default") : getStatusPill("custom","cancelled")}
                                <FloatingLabel label="Average Hours Saved per Influencer" className="mb-3" >
                                  <Form.Control type="text" value={brand.dashboard.overrides.influencerHoursSaved} id="influencerHoursSaved" className="rounded-0 border-0 border-bottom" onChange={(e) => updateBrandData(e,"dashboard")} /> 
                                </FloatingLabel>
                              </Col>
                              {brand.dashboard.existing && 
                                <>
                                  <Col sm={12} md={12}>
                                    <div className="form-group customFloating mb-3">
                                      <label>Available Pages</label>
                                      <Select
                                          closeMenuOnSelect={false}
                                          components={animatedComponents}
                                          isMulti
                                          defaultValue={brand.dashboard.meta.brandPages.map(brandPage => ({"label":availableDashboardPages.filter(page => page.value == brandPage)[0].label,"value":brandPage}))}
                                          options={availableDashboardPages} 
                                          id="dashboardPages" 
                                          onChange={ (choice) => updateBrandData(choice,"dashboardPages") } 
                                      />
                                    </div>
                                  </Col>
                                  <Col sm={12} md={12} className="form-group metricsButton">
                                    <label className="mt-3">Viewable Metrics</label>
                                    <ToggleButtonGroupMetrics/>
                                  </Col>
                                </>
                              } 
                            </Row>
                            <div className="d-flex align-items-center justify-content-between mb-4">
                              {brand.dashboard.existing ? 
                              <>
                                <button type="button" onClick={() => createDashboard(true)} className="btn btn-primary">Update Dashboard</button>
                                <button type="button" disabled={!getDifference(brand.dashboard.meta.nextUpdate).future || brand.dashboard.meta.state == "processing"} onClick={() => queueDashboard()} className="btn btn-primary">Queue Now</button>
                              </>
                              : 
                                <button type="button" onClick={() => createDashboard()} className="btn btn-primary">Add Dashboard</button>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                  </Tab.Pane>
                  )}
                </Tab.Content>
              </Col>
            </Row>
          </Container>
          </Tab.Container>
        </Container>
      </div>
    );
  }
}