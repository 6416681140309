import React, { useEffect, useState } from "react";
import { Container, Col, Row, Form, FloatingLabel, Tab, Table, Nav, Badge, ToggleButton, ToggleButtonGroup, Modal, Button } from 'react-bootstrap';
import { useImageContext } from "../../hooks/useImageContext";
import { useAuthContext } from "../../hooks/useAuthContext";
import { getImageUrl, updateImage, clientUploadImage } from "../../helpers/images";
import { apiCall, PageScroller } from './../../helpers/api'
import { useSearchParams, Link } from "react-router-dom";
import AlertModal from "../../components/alerts/alert";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { getIndustries, getCountries, getTerritories } from "../../helpers/dropdowns"
import { getStatusPill } from "../../helpers/status"

import './Influencers.css';
import Topbar from "../../components/topbar/Topbar";
import BrandDetails from './../../images/brand-details-blue.svg';
import CampaignHistory from './../../images/campaign-history.svg';
import blueLeftArrow from './../../images/blue-left-arrow.svg';
import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import greenCheck from "./../../images/check-green.svg"

export default function InfluencerSettings() {  
  let [searchParams] = useSearchParams();
  const { providers } = useImageContext();  
  const { currentProfile } = useAuthContext();
  const influencerID = searchParams.get('id');
  const [influencer, setInfluencer] = useState([]);
  const [bids, setBids] = useState([]);
  const [bidIDs, setBidIDs] = useState([]);
  const [bidStats, setBidStats] = useState({"total":0,"subtotals":{}});
  const [profileAnswers, setProfileAnswers] = useState([]);
  const [profileAnswerIDs, setProfileAnswerIDs] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false)
  const [specifyGender, setSpecifyGender] = useState(false);
  const [imageUploaded, setImageUploaded] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadingImage, setUploadingImage] = useState(false)
  const [refreshCounter, setRefreshCounter] = useState(0)
  const [newBidStatus, setNewBidStatus] = useState("");
  const [linkedAccounts, setLinkedAccounts] = useState([]);
  const [showAccountsPopup, setShowAccountsPopup] = useState(false);
  const [showSharedInfluencersPopup, setShowSharedInfluencersPopup] = useState(false);
  const [emailTargetUser, setEmailTargetUser] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [changeMade, setChangeMade] = useState(false);
  const [editStatusModal, setEditStatusModal] = useState({"visible":false,"bidID":null,"campaignID":null});
  const [showAlert, setAlert] = useState({"display":"toast","visible":false,"title":"","content":""});
  var view = searchParams.get('view');
  if (view == null){
    view = "details";
  }
  const [tabID, setTabID] = useState(view);

  const animatedComponents = makeAnimated();
  const territories = getTerritories()
  const interests = getIndustries()
  const countries = getCountries()

  const getInfluencer = async (influencerID,showLoading=true) => {
    if (showLoading){
      setIsLoaded(false);
    }
    var response = await apiCall(`influencer?id=${influencerID}&getSocials=true`,{ "method" : "GET" });
    if (response["success"]){
        if (!["Male","Female","Prefer not to say"].includes(response["success"]["influencer"]["gender"])){
          response["success"]["influencer"]["specificGender"] = response["success"]["influencer"]["gender"];
          response["success"]["influencer"]["gender"] = "Prefer to self-describe (please specify)";
          setSpecifyGender(true)
        }
        else {
          response["success"]["influencer"]["specificGender"] = "";
        }
        response["success"]["influencer"]["primaryInterest"] = response["success"]["influencer"]["interests"][0];
        response["success"]["influencer"]["interests"] = response["success"]["influencer"]["interests"].slice(1);
        setInfluencer(response["success"]["influencer"]);
        setIsLoaded(true);
    }	
  };

  const disableInfluencer = async (influencerID) => {
    var response = await apiCall(`admin/disableUser`,{ "method" : "POST", "data" : {"id":influencerID,"type":"influencer"} });
    if (response["success"]){
      setAlert({"display":"toast","visible":true,"title":"Influencer disabled","content":"Influencer successfully disabled","className":"bg-success text-white"})
      getInfluencer(influencerID,false);
    }
    else {
      setAlert({"display":"toast","visible":true,"title":"Failed to disable influencer","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
  }

  const enableInfluencer = async (influencerID) => {
    var response = await apiCall(`admin/enableUser`,{ "method" : "POST", "data" : {"id":influencerID,"type":"influencer"} });
    if (response["success"]){
      setAlert({"display":"toast","visible":true,"title":"Influencer enabled","content":"Influencer successfully enabled","className":"bg-success text-white"})
      getInfluencer(influencerID,false);
    }
    else {
      setAlert({"display":"toast","visible":true,"title":"Failed to enable influencer","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
  }

  const loginAsInfluencer = async (influencerID) => {
    var response = await apiCall(`impersonate`,{ "method" : "POST", "data" : {"id":influencerID,"type":"influencers","returnPage":window.location.pathname+window.location.search} });
    if (response["success"]){
      window.location.href="/Home";
    }
    else {
      setAlert({"display":"toast","visible":true,"title":"Failed to login as influencer","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
  };

  function updateInfluencerData(props,id) {
    var tempInfluencer = influencer;
    if (["primaryInterest","country"].includes(id)){
      tempInfluencer[id] = props.value
    }
    else if (id == "territories"){
      tempInfluencer["territories"] = props
    }
    else if (id == "segments"){
      tempInfluencer["segments"] = props
    }
    else if (id == "interests"){
      tempInfluencer["interests"] = props
    }
    else if (props.target.id == "gender" || props.target.id == "specificGender" ){
      if (["Male","Female","Prefer not to say"].includes(props.target.value)){
        setSpecifyGender(false)
        tempInfluencer["specificGender"] = "";
      }
      else {
        setSpecifyGender(true)
      }
      tempInfluencer[props.target.id] = props.target.value
    }
    else if (props.target.id.includes("address_")){
        tempInfluencer.address[props.target.id.split("address_")[1]] = props.target.value
    }
    else if (props.target.id.includes("Socials")) {
      if (props.target.id.includes("Follower")) {
        var platform = props.target.id.slice(0,-15)
        if (tempInfluencer["socials"][platform]) {
          try {
            var existingFollowers = tempInfluencer["socials"][platform]["data"]["followers"]
          }
          catch (error) {
            if (error.message == "Cannot read properties of undefined (reading 'followers')") {
              tempInfluencer["socials"][platform]["data"] = {"followers":0,"engagementRate":0.0}
            }
          }
          if (props.target.value.length === 0) {
            tempInfluencer["socials"][platform]["data"]["followers"] = ""
          }
          else {
          tempInfluencer["socials"][platform]["data"]["followers"] = parseInt(props.target.value);
          }
        }
      }
      else {
        var platform = props.target.id.slice(0,-7)

        if (tempInfluencer["socials"][platform]) {
          tempInfluencer["socials"][platform]["handle"] = props.target.value.trim();
          if (props.target.value.length === 0) {
            delete tempInfluencer["socials"][platform]
          }
        }
        else {
          tempInfluencer["socials"][platform] = {data : {}, handle : props.target.value, name: platform};
        }
      }
    }
    else {
      tempInfluencer[props.target.id] = props.target.value;
    }
    setInfluencer(tempInfluencer);
    setRefreshCounter(refreshCounter + 1);
    setChangeMade(true)
  }

  async function updateBidStatus(){
    setIsSubmitting(true)
    var response = await apiCall(`campaign/bid/update?id=${editStatusModal.campaignID}`,{ "method" : "PUT", "data" : { "bidID":editStatusModal.bidID, "status":newBidStatus } });
    if (response["success"]){
        setAlert({"display":"toast","visible":true,"title":"Bid Status Updated","content":`${influencer.name}'s bid status updated successfully`,"className":"bg-success text-white"})
        var newBids = [];
        bids.forEach(bid => {
          if (bid.bidDetail.id == editStatusModal.bidID){
            bid.bidDetail.state = newBidStatus;
          }
          newBids.push(bid)
        })
        setBids(newBids)
        setNewBidStatus("")
        setEditStatusModal({"visible":false,"bidID":null,"campaignID":null})
    }
    else if (response["failure"]) {
        setAlert({"display":"toast","visible":true,"title":"Could not update bid status","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
    setIsSubmitting(false)
  }

  async function submitUpdates(ignoreInfluencer=false){
    setIsSubmitting(true)
    var profileImage = influencer.profileImage;
    if (!imageUploaded && selectedImage) {
      var profileImage = await clientUploadImage(selectedImage,setAlert)
      setImageUploaded(true)
    }

    var response = await apiCall(`influencer?id=${influencerID}`,{ "method" : "PUT", "data" : {"name":influencer.name,"country":influencer.country,"primaryInterest":influencer.primaryInterest,"interests":influencer.interests,"profileImage":profileImage,"address":influencer.address,"phoneNumber":influencer.phoneNumber,"dob":influencer.dob,"gender":specifyGender ? influencer.specificGender : influencer.gender,"territories":influencer.territories,"segments":influencer.segments,"influencerType":influencer.influencerType,"email":influencer.email, "socials":influencer.socials,"targetUser":ignoreInfluencer ? "ignore" : emailTargetUser}});
    if (response["success"]){
        setAlert({"display":"toast","visible":true,"title":"Influencer Updated","content":`${influencer.name} Updated Successfully`,"className":"bg-success text-white"})
        getInfluencer(influencerID)
        setChangeMade(false)
    }
    else if (response["failure"]) {
      if (response["failure"]["error"] === "Multiple accounts found") {
        setLinkedAccounts(response["failure"]["accounts"])
        setShowAccountsPopup(true)
        setEmailTargetUser(response["failure"]["accounts"][0]["id"])
      }
      else if (response["failure"]["error"] === "Linked account associated with multiple influencers") {
        setLinkedAccounts(response["failure"]["account"])
        setShowSharedInfluencersPopup(true)
        setEmailTargetUser(response["failure"]["account"]["id"])
      }
      else {
        setAlert({"display":"toast","visible":true,"title":"Could not update influencer","content":response["failure"]["error"],"className":"bg-danger text-white"})
      }
    }
    setIsSubmitting(false)
  }

  async function setState(event,result) { 
    result = await result;
    var tempBids = structuredClone(bids)
    var tempIDs = structuredClone(bidIDs)
    setBidStats(result["_paging"])
    for (var x in result["bids"]) {
      if (!tempIDs.includes(result["bids"][x]["bidDetail"]["id"])) {
        tempBids.push(result["bids"][x])
        tempIDs.push(result["bids"][x]["bidDetail"]["id"])
      }
    }
    setBidIDs(tempIDs)
    setBids(tempBids)
  }

  async function profilingState(event,result) { 
    result = await result;
    var tempAnswers = structuredClone(profileAnswers)
    var tempIDs = structuredClone(profileAnswerIDs)
    for (var x in result["answers"]) {
      if (!tempIDs.includes(result["answers"][x]["id"])) {
        tempAnswers.push(result["answers"][x])
        tempIDs.push(result["answers"][x]["id"])
      }
    }
    setProfileAnswerIDs(tempIDs)
    setProfileAnswers(tempAnswers)
  }

  function ToggleButtonGroupInterests() {    
    return (
        <>
        <ToggleButtonGroup type="checkbox" value={influencer.interests} onChange={(choice) => updateInfluencerData(choice,"interests")} id="otherInterests">
            {interests.map(interest => 
              <ToggleButton key={interest.value} id={`tbg-btn-${interest.value}`} className="me-2 mb-2 btn-sm" value={interest.value}>{interest.label}</ToggleButton>
            )}
        </ToggleButtonGroup>
        </>
    );
  }

  function ToggleButtonGroupTerritories() {    
    return (
        <>
        <ToggleButtonGroup type="checkbox" value={influencer.territories} onChange={(choice) => updateInfluencerData(choice,"territories")} id="territories">
            {territories.map(territory => 
              <ToggleButton key={territory.value} id={`tbg-btn-${territory.value}`} className="me-2 mb-2 btn-sm" value={territory.value}>{territory.label}</ToggleButton>
            )}
        </ToggleButtonGroup>
        </>
    );
  }

  function ToggleButtonGroupSegments() {    
    return (
        <>
        <ToggleButtonGroup type="checkbox" value={influencer.segments} onChange={(choice) => updateInfluencerData(choice,"segments")} id="segments">
          <ToggleButton key={"Digital First"} id={`tbg-btn-Digital-First`} className="me-2 mb-2 btn-sm" value={"Digital First"}>Digital First</ToggleButton>
          <ToggleButton key={"Talent First"} id={`tbg-btn-Talent-First`} className="me-2 mb-2 btn-sm" value={"Talent First"}>Talent First</ToggleButton>
          <ToggleButton key={"Creator"} id={`tbg-btn-Creator`} className="me-2 mb-2 btn-sm" value={"Creator"}>Creator</ToggleButton>
          <ToggleButton key={"Consumer"} id={`tbg-btn-Consumer`} className="me-2 mb-2 btn-sm" value={"Consumer"}>Consumer</ToggleButton>
        </ToggleButtonGroup>
        </>
    );
  }

  useEffect(() => {
    getInfluencer(influencerID);
  }, []);

  if (isLoaded) {
    return (
      <div className="bg-page influencerSettings">
        <Topbar />
        <div className="pageName">Influencers</div>
        <Container className="fullscreenWithTopBar px-0" fluid>
        <AlertModal modal={showAlert} onClose={() => setAlert({"display":"toast","visible":false,"title":"","content":""})} />
        {showAccountsPopup && (
          <Modal show centered>
            <Modal.Header>
              <Modal.Title>Multiple associated accounts found</Modal.Title>
            </Modal.Header>
    
            <Modal.Body className="pb-0">
              <p>Pick which account's email address to update:</p>
              <Row>
                <Col>
                    <Form.Group className="mb-3">
                        <Form.Select id="targetUser" onChange={e => setEmailTargetUser(e.target.value)} >
                          {linkedAccounts.map(account => <option key={account.id} value={account.id}> {`${account.name} (${account.email})`} </option>)}
                        </Form.Select>
                    </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
    
            <Modal.Footer>
              <Button variant="secondary" onClick={()=>{submitUpdates(true); setShowAccountsPopup(false); setEmailTargetUser("");}}>Don't Update Accounts</Button>
              <Button variant="primary" disabled={isSubmitting} type="submit" onClick={() => {submitUpdates(); setShowAccountsPopup(false); setEmailTargetUser("");}}>
              {isSubmitting ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Updating...</> : "Update"}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {showSharedInfluencersPopup && (
            <Modal show centered>
              <Modal.Header>
                <Modal.Title>Do you want to update this shared account</Modal.Title>
              </Modal.Header>
      
              <Modal.Body className="pb-0">
                <p>An account associated with this influencer is also associated with other influencers. Do you want to update them?</p>
                <Row>
                  <Col>
                    <div>{linkedAccounts.name} ({linkedAccounts.email})</div>
                  </Col>
                </Row>
              </Modal.Body>
      
              <Modal.Footer>
                <Button variant="secondary" type="submit" onClick={() => {setShowSharedInfluencersPopup(false); submitUpdates(true); setEmailTargetUser("");}}>Ignore</Button>
                <Button variant="primary" type="submit" onClick={() => {submitUpdates(); setShowSharedInfluencersPopup(false); setEmailTargetUser("");}}>Update</Button>
              </Modal.Footer>
            </Modal>
          )}
        <Tab.Container id="campaign-tab" activeKey={tabID} onSelect={(key) => setTabID(key)}>
          <Container className="px-0 px-md-3">
            <Row className="d-flex justify-content-center mb-4">
              <Col sm={12} md={4} lg={4} xl={3} xxl={3}>
                <div className="pt-lg-4 mb-lg-5 px-3 px-md-0">
                  <div className="d-flex align-items-center justify-content-between mt-4 mb-4 mb-3 pb-2">
                    <h1 className="h3 tragedyRegularFont text-primary fw-700 mb-0">Influencer Settings</h1>
                  </div>

                  <div className="mb-3 pb-2">
                    <div className="bg-light shadow rounded-xl">
                        <div className="p-3">
                          <div className="d-flex align-items-start">
                            <img src={getImageUrl(providers,influencer.profileImage,["w_50","c_scale"])} width="50px" height="50px" className="object-fit-cover rounded me-2 border" /> 
                            <div>
                              <div className="fw-bold pt-1">{influencer.name}</div>
                              {influencer.active ? <Badge pill bg="success" text="dark">Active</Badge> : <Badge pill bg="danger" text="dark">Disabled</Badge>}
                            </div>
                          </div>
                        </div>
                      <div className="p-3 border-top smaller">
                          <div className="d-flex flex-column gap-3">
                            <div>
                                <div className="mb-1 tagsTitle">Primary Category:</div>
                                <span className="tags text-nowrap">{influencer.primaryInterest}</span>
                            </div>
                            {influencer.interests.length > 1 && (
                            <div>
                                <div className="mb-1 tagsTitle">Interests:</div>
                                <span className="tags me-1 text-nowrap">{influencer.interests[1]}</span>
                                {influencer.interests.length > 2 && (
                                    <span className="tags fw-bold cursor-pointer">+{influencer.interests.length - 2}</span>
                                )}
                            </div>
                            )}
                          </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-4 pb-2">
                    <Nav variant="tabs" className="border-0">
                      <div className="bg-light shadow rounded-xl brandSettingsNav w-100">
                        <Nav.Link eventKey="details" className="p-3 d-flex align-items-center justify-content-between w-100">
                            <span className="d-flex align-items-center">
                              <Nav.Item>
                                <img src={BrandDetails} className="me-2" /> Influencer Details
                              </Nav.Item>
                            </span>
                            <span>
                              <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="#143232"/>
                              </svg>
                            </span>
                        </Nav.Link>
                        <Nav.Link eventKey="socialMediaAccounts" className="p-3 d-flex align-items-center justify-content-between w-100">
                            <span className="d-flex align-items-center">
                              <Nav.Item>
                                <img src={BrandDetails} className="me-2" /> Social Media Accounts
                              </Nav.Item>
                            </span>
                            <span>
                              <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="#143232"/>
                              </svg>
                            </span>
                        </Nav.Link>
                        {influencer.associatedAccounts.length > 0 && (
                        <Nav.Link eventKey="associatedAccounts" className="p-3 d-flex align-items-center border-top justify-content-between w-100">
                            <span className="d-flex align-items-center">
                              <Nav.Item>
                                <img src={BrandDetails} className="me-2" /> Associated Accounts
                              </Nav.Item>
                            </span>
                            <span>
                              <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="#143232"/>
                              </svg>
                            </span>
                        </Nav.Link>
                        )}
                        <Nav.Link eventKey="history" className="p-3 d-flex align-items-center justify-content-between border-top w-100">
                          <span className="d-flex align-items-center">
                            <Nav.Item>
                              <img src={CampaignHistory} className="me-2" /> Bid History
                            </Nav.Item>
                          </span>
                          <span>
                            <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" fill="#143232"/>
                            </svg>
                          </span>
                        </Nav.Link>
                        {currentProfile.type == "admins" && 
                          <Nav.Link eventKey="access" className="p-3 d-flex align-items-center justify-content-between border-top w-100">
                            <span className="d-flex align-items-center">
                              <Nav.Item>
                                <img src={CampaignHistory} className="me-2" /> Manage Access
                              </Nav.Item>
                            </span>
                            <span>
                              <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" fill="#143232"/>
                              </svg>
                            </span>
                          </Nav.Link>
                        }
                        {currentProfile.type == "admins" && 
                          <Nav.Link eventKey="answers" className="p-3 d-flex align-items-center justify-content-between border-top w-100">
                            <span className="d-flex align-items-center">
                              <Nav.Item>
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-lg me-2" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M4.475 5.458c-.284 0-.514-.237-.47-.517C4.28 3.24 5.576 2 7.825 2c2.25 0 3.767 1.36 3.767 3.215 0 1.344-.665 2.288-1.79 2.973-1.1.659-1.414 1.118-1.414 2.01v.03a.5.5 0 0 1-.5.5h-.77a.5.5 0 0 1-.5-.495l-.003-.2c-.043-1.221.477-2.001 1.645-2.712 1.03-.632 1.397-1.135 1.397-2.028 0-.979-.758-1.698-1.926-1.698-1.009 0-1.71.529-1.938 1.402-.066.254-.278.461-.54.461h-.777ZM7.496 14c.622 0 1.095-.474 1.095-1.09 0-.618-.473-1.092-1.095-1.092-.606 0-1.087.474-1.087 1.091S6.89 14 7.496 14"/>
                              </svg> Profiling Answers
                              </Nav.Item>
                            </span>
                            <span>
                              <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" fill="#143232"/>
                              </svg>
                            </span>
                          </Nav.Link>
                        }
                      </div>
                    </Nav>
                  </div>
                  {currentProfile.type == "admins" && 
                    <div className="mb-3 pb-2">
                      <div className="bg-light shadow rounded-xl influencerSettingsNav">
                        <div className={`p-3 d-flex align-items-center justify-content-between ${influencer.active && "text-danger"}`}>
                          { influencer.active ? <a className="dropdown-item text-danger fw-bold" onClick={e => disableInfluencer(influencer._id)}>Disable</a> : <a className="dropdown-item text-success fw-bold" onClick={e => enableInfluencer(influencer._id)}>Enable</a> }
                          <span>
                          {influencer.active ? (
                            <svg width="18" height="18" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12.2179 2.78217C10.9836 1.54781 9.31599 0.846273 7.57266 0.825523L7.57213 0.825H7.5C5.72969 0.825 4.03197 1.52825 2.78006 2.78005L2.78005 2.78006C1.52828 4.03194 0.825 5.72988 0.825 7.5C0.825 9.27012 1.52825 10.968 2.78005 12.2199L2.78006 12.22C4.03194 13.4717 5.72988 14.175 7.5 14.175C9.27012 14.175 10.968 13.4717 12.2199 12.22L12.2199 12.2199C13.4717 10.9681 14.175 9.27012 14.175 7.5V7.49981C14.1731 5.7301 13.4693 4.03351 12.2179 2.78217ZM3.78862 3.57622C4.78153 2.63785 6.0932 2.11189 7.45825 2.10359L7.50006 2.10358C8.71196 2.1032 9.88633 2.51264 10.8339 3.26232L3.26056 10.8356C2.44731 9.8059 2.03617 8.51271 2.10849 7.19817L1.93375 7.18855L2.10849 7.19817C2.18427 5.82029 2.78565 4.52392 3.78861 3.57623L3.78862 3.57622ZM11.2114 11.4239C10.2184 12.3623 8.90674 12.8882 7.54166 12.8965L7.49995 12.8965C6.28804 12.8969 5.11367 12.4875 4.16615 11.7378L11.7394 4.1645C12.5527 5.19421 12.9638 6.48739 12.8915 7.80194C12.8157 9.17982 12.2144 10.4762 11.2114 11.4239L11.2114 11.4239Z" fill="#FF0000" stroke="#FF0000" strokeWidth="0.35"/>
                            </svg>
                          ) : (
                              <img src={greenCheck}/>
                          )}
                          </span>
                        </div>
                      </div>
                    </div>
                  }

                  <div className="mb-2">
                    <div className="bg-light shadow rounded-xl influencerSettingsNav">
                      <a href="/Administration/Influencers" className="p-3 d-flex align-items-center">
                        <img src={blueLeftArrow} className="me-2" /> Back to Influencers
                      </a>
                    </div>
                  </div>

                  <div className="bg-primary shadow rounded-xl influencerSettingsNav">
                    <a className="p-3 d-flex align-items-center justify-content-between" onClick={() => loginAsInfluencer(influencer._id)}>
                      <span className="text-white">Login as {influencer.name}</span>                     
                      <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="white"/>
                      </svg>
                    </a>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={8} lg={8} xl={9} xxl={8}>
                <Tab.Content>
                  <Tab.Pane eventKey="details">
                    <div className="pb-2 d-block d-md-none">
                      <div className="d-flex align-items-center mb-2 py-2 px-3">
                        <a className="backArrow" onClick={() => setTabID(null)}>
                          <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                          </svg>
                        </a>
                        <div className="sectionTitle w-100 text-center">Influencer Details</div>
                      </div>
                    </div>
                    <div className="px-0 pt-lg-4 mb-lg-5 mt-md-4">
                      <div className="p-4 px-0 px-md-3 bg-white shadow rounded-xl">

                        <h4 className="mt-4 mb-2 ms-4 d-none d-md-block">Influencer Details</h4>

                        <div className="text-center mb-4">
                          <div className="d-inline-block position-relative">
                            <img src={selectedImage ? selectedImage.url : getImageUrl(providers,influencer.profileImage,["w_200","c_scale"])} width="150px" height="150px" className="object-fit-cover rounded m-3 border bg-light p-2" />
                            <label htmlFor="account-upload">
                              <a className="position-absolute end-0 bottom-0">
                                {uploadingImage ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <svg width="36" height="36" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="11.5" cy="11.5" r="11" fill="white" stroke="#E3E3E3"/>
                                  <path d="M15.9851 8.90875L14.0912 7.01483C14.0707 6.99506 14.0383 6.99506 14.0179 7.01483L7.74132 13.2916C7.73366 13.2992 7.72864 13.309 7.72688 13.3197L7.72073 13.3128L7 16L9.68722 15.2793L9.68038 15.2724H9.68032C9.69099 15.2707 9.70085 15.2656 9.70844 15.258L15.9852 8.98207C16.0049 8.9616 16.0049 8.92921 15.9852 8.90874L15.9851 8.90875Z" fill="#797979"/>
                                  <path d="M7.5 13L10 15.5" stroke="white"/>
                                </svg>}
                              </a>
                            </label>
                            <Form.Control type="file" id="account-upload" className="fileUploadBtn position-absolute end-0 bottom-0" accept=".png, .jpg, .jpeg, .webp" onChange={(e) => {updateImage(e,setSelectedImage,setUploadingImage,setAlert); setImageUploaded(false)}}/>
                          </div>
                        </div>

                        <div className="mx-4">
                          <Row>
                            <Col xs={12} sm={6}>
                              <FloatingLabel label="Name:" className="mb-3" >
                                  <Form.Control type="text" id="name" value={influencer.name} onChange={(e) => updateInfluencerData(e)} className="rounded-0 border-0 border-bottom" />
                              </FloatingLabel>                        
                            </Col>
                            <Col xs={12} sm={6}>
                                <FloatingLabel label="Date of birth:" className="mb-3" >
                                    <Form.Control type="date" id="dob" value={influencer.dob} readOnly={currentProfile.type != "admins"} onChange={(e) => updateInfluencerData(e)} className="rounded-0 border-0 border-bottom" />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} sm={6}>
                              <FloatingLabel controlId="floatingSelect" label="Gender" className="mb-3">
                                <Form.Select className="mb-4 z-index-9 border-0 border-bottom" id="gender" value={influencer.gender} onChange={(e) => updateInfluencerData(e)} >
                                    <option>Male</option>
                                    <option>Female</option>
                                    <option>Prefer not to say</option>
                                    <option>Prefer to self-describe (please specify)</option>
                                </Form.Select>
                              </FloatingLabel>
                            </Col>
                            {specifyGender && (
                            <Col xs={12} sm={6}>
                                <FloatingLabel label="Specify Gender:" className="mb-3" >
                                    <Form.Control type="text" id="specificGender" value={influencer.specificGender} onChange={(e) => updateInfluencerData(e)} className="rounded-0 border-0 border-bottom" />
                                </FloatingLabel>
                            </Col>
                            )}
                            <Col xs={12}>
                                <FloatingLabel label="Email:" className="mb-3" >
                                    <Form.Control type="text" id="email" value={influencer.email} onChange={(e) => updateInfluencerData(e)} className="rounded-0 border-0 border-bottom" />
                                </FloatingLabel>
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={12} sm={6}>
                                <FloatingLabel label="Address Line 1:" className="mb-3" >
                                    <Form.Control type="text" value={influencer.address.address1} id="address_address1" onChange={(e) => updateInfluencerData(e)} className="rounded-0 border-0 border-bottom" />
                                </FloatingLabel>                             
                            </Col>
                            <Col xs={12} sm={6}>
                                <FloatingLabel label="Address Line 2:" className="mb-3" >
                                    <Form.Control type="text" value={influencer.address.address2} id="address_address2" onChange={(e) => updateInfluencerData(e)} className="rounded-0 border-0 border-bottom" />
                                </FloatingLabel>                             
                            </Col>
                            <Col xs={12} sm={6}>
                                <FloatingLabel label="City:" className="mb-3" >
                                    <Form.Control type="text" value={influencer.address.city} id="address_city" onChange={(e) => updateInfluencerData(e)} className="rounded-0 border-0 border-bottom" />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} sm={6}>
                                <FloatingLabel label="Postal Code:" className="mb-3" >
                                    <Form.Control type="text" value={influencer.address.postcode} id="address_postcode" onChange={(e) => updateInfluencerData(e)} className="rounded-0 border-0 border-bottom" />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} sm={6}>
                                <FloatingLabel label="State / Province / Region:" className="mb-3" >
                                    <Form.Control type="text" value={influencer.address.region} id="address_region" onChange={(e) => updateInfluencerData(e)} className="rounded-0 border-0 border-bottom" />
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} sm={6}>
                                <FloatingLabel label="Contact Number:" className="mb-3" >
                                    <Form.Control type="text" value={influencer.phoneNumber} id="phoneNumber" onChange={(e) => updateInfluencerData(e)} className="rounded-0 border-0 border-bottom" />
                                </FloatingLabel>
                            </Col>
                          </Row>

                          <div className="form-group customFloating mb-3">
                            <label>Country</label>
                            <Select
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                              defaultValue={countries.filter(function(country) {return country.value == influencer.country})}
                              options={countries} 
                              id="countries" 
                              onChange={ (choice) => updateInfluencerData(choice,"country") } 
                            /> 
                          </div>         

                          <div className="form-group customFloating mb-3">
                            <label>Primary Category</label>
                            <Select
                              closeMenuOnSelect={true}
                              components={animatedComponents}
                              defaultValue={[{label:influencer.primaryInterest,value:influencer.primaryInterest}]}
                              options={interests} 
                              id="primaryInterest" 
                              onChange={ (choice) => updateInfluencerData(choice,"primaryInterest") } 
                            />
                          </div>

                          <span className="createCampaign">
                              <label className="mb-2">Other Interests</label>
                              <ToggleButtonGroupInterests />
                          </span>
                          <div className="d-flex align-items-center justify-content-between mt-3 mb-4">
                          {/* <span className="text-muted">Member since November 2019</span> */}
                            <button type="button" disabled={!changeMade} onClick={() => submitUpdates()} className="btn btn-primary">Save</button>
                          </div>
                        </div>

                    </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="socialMediaAccounts">
                    <div className="pb-2 d-block d-md-none">
                      <div className="d-flex align-items-center mb-2 py-2 px-3">
                        <a className="backArrow" onClick={() => setTabID(null)}>
                          <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                          </svg>
                        </a>
                        <div className="sectionTitle w-100 text-center">Social Media Account Details</div>
                      </div>
                    </div>
                    <div className="px-0 pt-lg-4 mb-lg-5 mt-md-4">
                      <div className="p-4 px-0 px-md-3 bg-white shadow rounded-xl">
                        <h4 className="mt-4 mb-2 ms-4 d-none d-md-block">Social Media Account Details</h4>
                        <div className="mx-4">
                          <Row>
                            <Col xs={6}>
                                <FloatingLabel label="Facebook:" className="mb-3" >
                                    <Form.Control type="text" id="facebookSocials" value={influencer.socials.hasOwnProperty("facebook") ? influencer.socials.facebook.handle : ""} readOnly={currentProfile.type != "admins"} onChange={(e) => updateInfluencerData(e)} className="rounded-0 border-0 border-bottom" />
                                </FloatingLabel>
                            </Col>
                            <Col xs={6}>
                                <FloatingLabel label="Facebook Followers:" className="mb-3" >
                                    <Form.Control type="number" id="facebookFollowerSocials" value={influencer.socials.hasOwnProperty("facebook") && influencer.socials.facebook.hasOwnProperty("data") ? influencer.socials.facebook.data.followers : ""} min={0} readOnly={currentProfile.type != "admins" || (influencer.socials.hasOwnProperty("facebook") ? !influencer.socials.facebook.handle : true)} onChange={(e) => updateInfluencerData(e)} className="rounded-0 border-0 border-bottom" />
                                </FloatingLabel>
                            </Col>
                            <Col xs={6}>
                              <FloatingLabel label="Instagram:" className="mb-3" >
                                  <Form.Control type="text" id="instagramSocials" value={influencer.socials.hasOwnProperty("instagram") ? influencer.socials.instagram.handle : ""} readOnly={currentProfile.type != "admins"} onChange={(e) => updateInfluencerData(e)} className="rounded-0 border-0 border-bottom" />
                              </FloatingLabel>                        
                            </Col>
                            <Col xs={6}>
                                <FloatingLabel label="Instagram Followers:" className="mb-3" >
                                    <Form.Control type="number" id="instagramFollowerSocials" value={influencer.socials.hasOwnProperty("instagram") && influencer.socials.instagram.hasOwnProperty("data") ? influencer.socials.instagram.data.followers : ""} min={0} readOnly={currentProfile.type != "admins" || (influencer.socials.hasOwnProperty("instagram") ? !influencer.socials.instagram.handle : true)} onChange={(e) => updateInfluencerData(e)} className="rounded-0 border-0 border-bottom" />
                                </FloatingLabel>
                            </Col>
                            <Col xs={6}>
                                <FloatingLabel label="TikTok:" className="mb-3" >
                                    <Form.Control type="text" id="tiktokSocials" value={influencer.socials.hasOwnProperty("tiktok") ? influencer.socials.tiktok.handle : ""} readOnly={currentProfile.type != "admins"} onChange={(e) => updateInfluencerData(e)} className="rounded-0 border-0 border-bottom" />
                                </FloatingLabel>
                            </Col>
                            <Col xs={6}>
                                <FloatingLabel label="TikTok Followers:" className="mb-3" >
                                    <Form.Control type="number" id="tiktokFollowerSocials" value={influencer.socials.hasOwnProperty("tiktok") && influencer.socials.tiktok.hasOwnProperty("data") ? influencer.socials.tiktok.data.followers : ""} min={0} readOnly={currentProfile.type != "admins" || (influencer.socials.hasOwnProperty("tiktok") ? !influencer.socials.tiktok.handle : true)} onChange={(e) => updateInfluencerData(e)} className="rounded-0 border-0 border-bottom" />
                                </FloatingLabel>
                            </Col>
                            <Col xs={6}>
                                <FloatingLabel label="Twitter:" className="mb-3" >
                                    <Form.Control type="text" id="twitterSocials" value={influencer.socials.hasOwnProperty("twitter") ? influencer.socials.twitter.handle : ""} readOnly={currentProfile.type != "admins"} onChange={(e) => updateInfluencerData(e)} className="rounded-0 border-0 border-bottom" />
                                </FloatingLabel>
                            </Col>
                            <Col xs={6}>
                                <FloatingLabel label="Twitter Followers:" className="mb-3" >
                                    <Form.Control type="number" id="twitterFollowerSocials" value={influencer.socials.hasOwnProperty("twitter") && influencer.socials.twitter.hasOwnProperty("data") ? influencer.socials.twitter.data.followers : ""} min={0} readOnly={currentProfile.type != "admins" || (influencer.socials.hasOwnProperty("twitter") ? !influencer.socials.twitter.handle : true)} onChange={(e) => updateInfluencerData(e)} className="rounded-0 border-0 border-bottom" />
                                </FloatingLabel>
                            </Col>
                            <Col xs={6}>
                                <FloatingLabel label="YouTube:" className="mb-3" >
                                    <Form.Control type="text" id="youtubeSocials" value={influencer.socials.hasOwnProperty("youtube") ? influencer.socials.youtube.handle : ""} readOnly={currentProfile.type != "admins"} onChange={(e) => updateInfluencerData(e)} className="rounded-0 border-0 border-bottom" />
                                </FloatingLabel>
                            </Col>
                            <Col xs={6}>
                                <FloatingLabel label="YouTube Followers:" className="mb-3" >
                                    <Form.Control type="number" id="youtubeFollowerSocials" value={influencer.socials.hasOwnProperty("youtube") && influencer.socials.youtube.hasOwnProperty("data") ? influencer.socials.youtube.data.followers : ""} min={0} readOnly={currentProfile.type != "admins" || (influencer.socials.hasOwnProperty("youtube") ? !influencer.socials.youtube.handle : true)} onChange={(e) => updateInfluencerData(e)} className="rounded-0 border-0 border-bottom" />
                                </FloatingLabel>
                            </Col>
                          </Row>   
                          {currentProfile.type == "admins" &&
                            <div className="d-flex align-items-center justify-content-between mt-3 mb-4">   
                            <Button variant="primary" disabled={isSubmitting} type="submit" onClick={() => submitUpdates()}>
                            {isSubmitting ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving...</> : "Save"}
                            </Button>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="associatedAccounts">
                  <div className="pb-2 d-block d-md-none">
                        <div className="d-flex align-items-center mb-2 py-2 px-3">
                          <a className="backArrow" onClick={() => setTabID(null)}>
                            <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                            </svg>
                          </a>
                          <div className="sectionTitle w-100 text-center">Associated Accounts</div>
                        </div>
                      </div>
                      <div className="px-0 pt-lg-4 mb-lg-5 mt-md-4">
                        <div className="p-4 px-0 px-md-3 bg-white shadow rounded-xl">
                            <div className="associatedAccounts">
                                <div className="d-flex align-items-center justify-content-between my-4 ms-4 pb-2  d-none d-md-block">
                                  <h4>Associated Accounts</h4>
                                </div>
                                {influencer.associatedAccounts.map(account => (
                                    <p className="ms-4" key={account.email}>{account.name} ({account.email})</p>
                                ))}
                            </div>
                          </div>
                      </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="history">
                    <div className="pb-2 d-block d-md-none">
                        <div className="d-flex align-items-center mb-2 py-2 px-3">
                          <a className="backArrow" onClick={() => setTabID(null)}>
                            <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                            </svg>
                          </a>
                          <div className="sectionTitle w-100 text-center">Bid History</div>
                        </div>
                      </div>
                      <div className="px-0 pt-lg-4 mb-lg-5 mt-md-4">
                        <div className="p-4 px-0 px-md-3 bg-white shadow rounded-xl">

                            <div className="influencerHistory">
                              <div className="d-flex align-items-center justify-content-between my-4 ms-4 pb-2 d-none d-md-block">
                                <h4>Bid History</h4>
                            </div>

                            {editStatusModal.visible && (
                              <Modal show backdrop="static" centered >
                              <Modal.Header className="border-0 bg-success text-white">
                                <Modal.Title className="h6">
                                  Update Bid Status
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body className="pb-0">
                                <Row>
                                  <Col>
                                      <Form.Group className="mb-3">
                                          <Form.Select id="status" value={newBidStatus} onChange={(e) => setNewBidStatus(e.target.value)} >
                                            <option>Pending</option>
                                            <option>Accepted</option>
                                            <option>In Progress</option>
                                            <option>Marked as Complete</option>
                                            <option>Completed</option>
                                            <option>Cancelled</option>
                                            <option>Declined</option>
                                          </Form.Select>
                                      </Form.Group>
                                  </Col>
                                </Row>
                              </Modal.Body>
                              <Modal.Footer className="border-0 pt-0">
                                <Button variant="outline-secondary" size="sm" onClick={()=>{setNewBidStatus(""); setEditStatusModal({"visible":false,"bidID":null,"campaignID":null})}}>Cancel</Button>
                                <Button disabled={newBidStatus == ""} onClick={() => updateBidStatus()} variant="success" size="sm">{isSubmitting ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Updating</> : "Update"}</Button>
                              </Modal.Footer>
                            </Modal>
                            )}

                            <div className="mx-4">
                                <Row className="d-flex justify-content-center mb-4">
                                    <Col className="mb-4">
                                        <div className="border rounded p-3 text-center h-100">
                                            <h4 className="mb-0 text-blue fw-bold">{bidStats.total}</h4>
                                            <div>Bids</div>
                                        </div>
                                    </Col>
                                    <Col className="mb-4">
                                        <div className="border rounded p-3 text-center h-100">
                                            <h4 className="mb-0 fw-bold">{bidStats.subtotals.Pending ? bidStats.subtotals.Pending : 0}</h4>
                                            <div>Pending</div>
                                        </div>
                                    </Col>
                                    <Col className="mb-4">
                                        <div className="border rounded p-3 text-center h-100">
                                            <h4 className="mb-0 fw-bold">{bidStats.subtotals.Accepted ? bidStats.subtotals.Accepted : 0}</h4>
                                            <div>Accepted</div>
                                        </div>
                                    </Col>
                                    <Col className="mb-4">
                                        <div className="border rounded p-3 text-center h-100">
                                            <h4 className="mb-0 fw-bold">{bidStats.subtotals.Completed ? bidStats.subtotals.Completed : 0}</h4>
                                            <div>Completed</div>
                                        </div>
                                    </Col>
                                    <Col className="mb-4">
                                        <div className="border rounded p-3 text-center h-100">
                                            <h4 className="mb-0 fw-bold">{bidStats.subtotals.Rejected ? bidStats.subtotals.Rejected : 0}</h4>
                                            <div>Declined</div>
                                        </div>
                                    </Col>
                                </Row>
                                
                                <h5 className="mb-2 mb-md-4 mt-2 mt-md-5">History</h5>
                                <Table className="border-transparent">
                                    <thead className="small">
                                        <tr>
                                            <th>Brand</th>
                                            <th>Campaign</th>
                                            <th>Status</th>
                                            <th>Last Date to Post by</th>
                                            <th>Bid Date</th>
                                        </tr>
                                    </thead>
                                    <tbody className="small">
                                      {bids.map(bid => (
                                      <tr key={bid.bidDetail.id}>
                                        <td>
                                          <div className="d-flex align-items-center text-wrap">
                                            <img src={getImageUrl(providers,bid.campaignDetail.brand.profileImage,["w_50","c_scale"])} width="36px" height="36px" className="object-fit-cover rounded me-3" /> 
                                            <div>{bid.campaignDetail.brand.name}</div>
                                          </div>
                                        </td>
                                        
                                        <td>
                                          <div className="d-flex align-items-center">
                                            <img src={getImageUrl(providers,bid.campaignDetail.image,["w_50","c_scale"])} width="36px" height="36px" className="object-fit-cover rounded me-3" />  
                                            <a className="w-75 d-block text-black fw-500">{bid.campaignDetail.title}</a>
                                          </div>
                                        </td>

                                        <td className="text-nowrap">{getStatusPill(bid.bidDetail.state)} {currentProfile.type == "admins" && <button className="border-0 bg-white text-primary small pe-1 mt-1 d-block fw-bold" onClick={() => {setNewBidStatus(bid.bidDetail.state); setEditStatusModal({"visible":true,"bidID":bid.bidDetail.id,"campaignID":bid.campaignDetail.id})}}>Change</button>} </td>

                                        <td className="text-nowrap">{bid.bidDetail.dueDate ? new Date(bid.bidDetail.dueDate*1000).toLocaleDateString('en-GB', { year:"numeric", month:"long", day:"numeric"}) : "N/A"}</td>

                                        <td className="text-nowrap">{new Date(bid.bidDetail.time*1000).toLocaleDateString('en-GB', { year:"numeric", month:"long", day:"numeric"})}</td>
                                      </tr>
                                      ))}
                                    </tbody>
                                </Table>
                                <PageScroller endpoint={`influencer?id=${influencerID}&getBids=true`} pageSize={100} setState={setState} />

                            </div>
                            
                        </div>

                    </div>
                  </div>
                  </Tab.Pane>
                  
                  <Tab.Pane eventKey="access">
                    <div className="pb-2 d-block d-md-none">
                      <div className="d-flex align-items-center mb-2 py-2 px-3">
                        <a className="backArrow" onClick={() => setTabID(null)}>
                          <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                          </svg>
                        </a>
                        <div className="sectionTitle w-100 text-center">Manage Access</div>
                      </div>
                    </div>
                    <div className="px-0 pt-lg-4 mb-lg-5 mt-md-4">
                      <div className="p-4 px-0 px-md-3 bg-white shadow rounded-xl">

                        <h4 className="mt-4 mb-4 ms-4">Manage Access</h4>

                        <div className="mx-4">
                          <Row>
                            <Col xs={12} sm={6}>
                              <span className="createCampaign">
                                  <label className="mb-2">Territories</label>
                                  <ToggleButtonGroupTerritories />
                              </span>
                            </Col>
                            <Col xs={12} sm={6}>
                              <FloatingLabel controlId="floatingSelect" label="Role" className="mb-3">
                                <Form.Select className="mb-4 z-index-9 border-0 rounded-0 border-bottom" id="influencerType" value={influencer.influencerType} onChange={(e) => updateInfluencerData(e)} >
                                  <option value="micro">Micro (&lt;10k followers)</option>
                                  <option value="medium">Medium (&lt;150k Followers)</option>
                                  <option value="large">Large (&gt;150k Followers)</option>
                                </Form.Select>
                              </FloatingLabel>
                            </Col>
                            <Col xs={12} sm={6}>
                              <span className="createCampaign">
                                  <label className="mb-2">Influencer Segments</label>
                                  <ToggleButtonGroupSegments />
                              </span>
                            </Col>
                          </Row>    

                          <div className="d-flex align-items-center justify-content-between mt-3 mb-4">
                            <button type="button" onClick={() => submitUpdates()} className="btn btn-primary">Save Changes</button>
                          </div>
                        </div>

                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="answers">
                    <div className="pb-2 d-block d-md-none">
                      <div className="d-flex align-items-center mb-2 py-2 px-3">
                        <a className="backArrow" onClick={() => setTabID(null)}>
                          <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                          </svg>
                        </a>
                        <div className="sectionTitle w-100 text-center">Profiling Answers</div>
                      </div>
                    </div>
                    <div className="px-0 pt-lg-4 mb-lg-5 mt-md-4">
                      <div className="p-4 px-0 px-md-3 bg-white shadow rounded-xl">

                        <h4 className="mt-4 mb-4 ms-4">Profiling Answers</h4>

                        <div className="mx-4">
                          <Table className="border-transparent">
                            <thead>
                              <tr>
                                <th>Question</th>
                                <th className="text-nowrap"><span className="d-none d-md-inline">Question</span> Type</th>
                                <th className="text-nowrap">Answer</th>
                              </tr>
                            </thead>
                            <tbody>
                              {profileAnswers.map((answer) => (
                                <tr key={answer.id}>
                                   <td>
                                      {answer.question}
                                    </td>
                                    <td className="text-nowrap">
                                      {answer.questionType}
                                    </td>
                                    <td className="text-nowrap">
                                      {!Array.isArray(answer.answer) ? typeof(answer.answer) === "boolean" ? answer.answer ? "Yes" : "No" : answer.answer : answer.answer.map((item, index) => index !== answer.answer.length-1 ? `${item}, ` : item )}
                                    </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          <PageScroller endpoint={`profile/influencerAnswers?influencerID=${influencerID}`} pageSize={100} setState={profilingState} />
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Container>
        </Tab.Container>
        </Container>
      </div>
    );
  }
  else {
    return ( 
      <div className="bg-page influencerSettings">
        <Topbar />
        <Container className="fullscreenWithTopBar px-0" fluid>
          <FullPageSpinner />; 
        </Container>
      </div>
     )
  }
}