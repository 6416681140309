import React, { Component, useState } from "react";
import { Button, Form, Modal, FloatingLabel } from 'react-bootstrap';
import { apiCall } from '../../helpers/api';
import { Link } from "react-router-dom";
import { propTypes } from "react-bootstrap/esm/Image";

export class LockedModal extends Component{
  static props = {
    onClose: propTypes.func
  }
  constructor(props) {
    super(props)
    this.state = {
        overdueCampaigns: []
    }
}
  async getOverdueCampaigns() {
    var result = await apiCall("influencer/overDue",{ "method" : "GET" });
    if (result["success"]){
      this.setState({overdueCampaigns: result["success"]["campaigns"]})
    }
  }

  componentDidMount() {
    this.getOverdueCampaigns()
  }

  render() {
      return (
        <Modal show onHide={() => this.props.onClose(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Your ability to bid has been suspended</Modal.Title>
          </Modal.Header>
          <Form>
            <Modal.Body>
              <div>Unfortunately you are late on a number of active bids. Because of this, your ability to bid on new campaigns has been suspended. In order to bid again, please review the below campaigns and provide content in order to restore your bidding ability.</div>
              <hr />
              <div>The following bids are overdue:</div>
              {this.state.overdueCampaigns.map(campaign => (
                <div key={campaign.id}><Link to={`/MyBid?id=${campaign.id}`}>{campaign.name}</Link></div>
              ))}
              
            </Modal.Body>
          </Form>
        </Modal>
        )
    }
}