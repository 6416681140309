// Eventually we use this to hook into the settings in the DB, rather than hardcoding it here

export function getIndustries(){

    const industries = [
        { value: 'Activities', label: 'Activities' },
        { value: 'Arts & Culture', label: 'Arts & Culture' },
        { value: 'Automotive', label: 'Automotive' },
        { value: 'Beauty, Grooming & Fragrance', label: 'Beauty, Grooming & Fragrance' },
        { value: 'Books & Magazines', label: 'Books & Magazines' },
        { value: 'Charity & Nonprofit', label: 'Charity & Nonprofit' },
        { value: 'Children & Baby', label: 'Children & Baby' },
        { value: 'Entertainment & Media', label: 'Entertainment & Media' },
        { value: 'Environment', label: 'Environment' },
        { value: 'Fashion', label: 'Fashion' },
        { value: 'Food & Drink', label: 'Food & Drink' },
        { value: 'Health, Wellness & Personal Care', label: 'Health, Wellness & Personal Care' },
        { value: 'Holidays & Travel', label: 'Holidays & Travel' },
        { value: 'Home & Garden', label: 'Home & Garden' },
        { value: 'Lifestyle', label: 'Lifestyle' },
        { value: 'Pets', label: 'Pets' },
        { value: 'Restaurants, Bars & Clubs', label: 'Restaurants, Bars & Clubs' },
        { value: 'Sport & Fitness', label: 'Sport & Fitness' },
        { value: 'Technology, Gaming & Electronics', label: 'Technology, Gaming & Electronics' },
        { value: 'Tickets', label: 'Tickets' }
    ]
    return industries
}

export function getPermissions(){

    const permissions = [
        { value: 'brand.read', label: 'View Brand', context: ['brands'] },
        { value: 'brand.update', label: 'Update Brand', context: ['brands'] },
        { value: 'campaign.list', label: 'View Campaigns', context: ['brands','influencers'] },
        { value: 'brand.campaign.list', label: 'View Brand Campaigns', context: ['brands'] },
        { value: 'campaign.create', label: 'Create Campaigns', context: ['brands'] },
        { value: 'campaign.archive', label: 'Archive Campaigns', context: ['brands'] },
        { value: 'campaign.delete', label: 'Delete Campaigns', context: ['brands'] },
        { value: 'campaign.bid', label: 'Bid on Campaigns', context: ['influencers'] },
        { value: 'conversations.read', label: 'Read Messages', context: ['brands','influencers'] },
        { value: 'conversations.update', label: 'Send Messages', context: ['brands','influencers'] },
        { value: 'influencer.read', label: 'View Influencer', context: ['influencers'] },
        { value: 'influencer.update', label: 'Update Influencer', context: ['influencers'] },
        { value: 'notifications.update', label: 'Update Notifications', context: ['brands','influencers'] }
    ]
    return permissions
}

export function getTerritories(){

    const permissions = [
        { value: 'GB', label: 'United Kingdom' },
        { value: 'IE', label: 'Ireland' },
        { value: 'US', label: 'United States' },
        { value: 'DE', label: 'Germany' },
    ]
    return permissions
}

export function getSubscriptions(){

    const subscriptionOptions = [
        { value: 'lite', label: 'Lite', plan: 'subscription', managed: false},
        { value: 'pro', label: 'Pro', plan: 'subscription', managed: false},
        { value: 'enterprise', label: 'Enterprise', plan: 'subscription', managed: true},
        { value: 'self-serve', label: 'Self-Serve', plan: 'lifetime', managed: false},
        { value: 'managed', label: 'Managed', plan: 'lifetime', managed: true},
        { value: 'V1 Legacy', label: 'V1 Legacy', plan: 'legacy', managed: true}
    ]

    const subscriptionPlans = [
        { value: 'subscription', label: 'Subscription'},
        { value: 'legacy', label: 'Legacy'},
        { value: 'lifetime', label: 'Lifetime'},
    ]
    return {packages: subscriptionOptions, plans: subscriptionPlans}
}


export function getSupportOptions(context,multipleContext=false){

    const supportOptions = [
        { value: 'My Bids', label: 'My Bids', context: ["influencer"] },
        { value: 'My Campaigns', label: 'My Campaigns', context: ["brand"] },
        { value: 'Payment Unsuccessful', label: 'Payment Unsuccessful', context: ["brand","agency"] },
        { value: 'Discuss Plans', label: 'Discuss Plans', context: ["brand","agency"] },
        { value: 'A Bidder', label: 'A Bidder', context: ["brand"] },
        { value: 'A Brand', label: 'A Brand', context: ["influencer"] },
        { value: 'Delete My Account', label: 'Delete My Account', context: ["influencer","brand","agency","user"] },
        { value: 'No Associated Profiles', label: 'No Associated Profiles', context: ["user"] },
        { value: 'Other', label: 'Other', context: ["influencer","brand","agency","user"] }
    ]
    if (multipleContext) {
        return supportOptions.filter(function(option) {return context.some(function(contextItem) {return option.context.includes(contextItem)})})
    }
    return supportOptions.filter(function(option) {return option.context.includes(context)})
}

export function generateDropdown(list){
    var dropdownList = [];
    list.forEach(element => {
        dropdownList.push({value:element,label:element})
    });
    return dropdownList;
}

export function getStates(){
    const states = [
        {value: "Alabama", label: "Alabama"},
        {value: "Alaska", label: "Alaska"},
        {value: "Arizona", label: "Arizona"},
        {value: "Arkansas", label: "Arkansas"},
        {value: "California", label: "California"},
        {value: "Colorado", label: "Colorado"},
        {value: "Connecticut", label: "Connecticut"},
        {value: "Delaware", label: "Delaware"},
        {value: "Florida", label: "Florida"},
        {value: "Georgia", label: "Georgia"},
        {value: "Hawaii", label: "Hawaii"},
        {value: "Idaho", label: "Idaho"},
        {value: "Illinois", label: "Illinois"},
        {value: "Indiana", label: "Indiana"},
        {value: "Iowa", label: "Iowa"},
        {value: "Kansas", label: "Kansas"},
        {value: "Kentucky", label: "Kentucky"},
        {value: "Louisiana", label: "Louisiana"},
        {value: "Maine", label: "Maine"},
        {value: "Maryland", label: "Maryland"},
        {value: "Massachusetts", label: "Massachusetts"},
        {value: "Michigan", label: "Michigan"},
        {value: "Minnesota", label: "Minnesota"},
        {value: "Mississippi", label: "Mississippi"},
        {value: "Missouri", label: "Missouri"},
        {value: "Montana", label: "Montana"},
        {value: "Nebraska", label: "Nebraska"},
        {value: "Nevada", label: "Nevada"},
        {value: "New Hampshire", label: "New Hampshire"},
        {value: "New Jersey", label: "New Jersey"},
        {value: "New Mexico", label: "New Mexico"},
        {value: "New York", label: "New York"},
        {value: "North Carolina", label: "North Carolina"},
        {value: "North Dakota", label: "North Dakota"},
        {value: "Ohio", label: "Ohio"},
        {value: "Oklahoma", label: "Oklahoma"},
        {value: "Oregon", label: "Oregon"},
        {value: "Pennsylvania", label: "Pennsylvania"},
        {value: "Rhode Island", label: "Rhode Island"},
        {value: "South Carolina", label: "South Carolina"},
        {value: "South Dakota", label: "South Dakota"},
        {value: "Tennessee", label: "Tennessee"},
        {value: "Texas", label: "Texas"},
        {value: "Utah", label: "Utah"},
        {value: "Vermont", label: "Vermont"},
        {value: "Virginia", label: "Virginia"},
        {value: "Washington", label: "Washington"},
        {value: "Washington DC", label: "Washington DC"},
        {value: "West Virginia", label: "West Virginia"},
        {value: "Wisconsin", label: "Wisconsin"},
        {value: "Wyoming", label: "Wyoming"}
    ]
    
    return states
}

export function getCountries(){
    const countries = [
        {value: "AF", label: "Afghanistan"},
        {value: "AL", label: "Albania"},
        {value: "DZ", label: "Algeria"},
        {value: "AS", label: "American Samoa"},
        {value: "AD", label: "Andorra"},
        {value: "AO", label: "Angola"},
        {value: "AI", label: "Anguilla"},
        {value: "AQ", label: "Antarctica"},
        {value: "AG", label: "Antigua and Barbuda"},
        {value: "AR", label: "Argentina"},
        {value: "AM", label: "Armenia"},
        {value: "AW", label: "Aruba"},
        {value: "AU", label: "Australia"},
        {value: "AT", label: "Austria"},
        {value: "AZ", label: "Azerbaijan"},
        {value: "BS", label: "Bahamas"},
        {value: "BH", label: "Bahrain"},
        {value: "BD", label: "Bangladesh"},
        {value: "BB", label: "Barbados"},
        {value: "BY", label: "Belarus"},
        {value: "BE", label: "Belgium"},
        {value: "BZ", label: "Belize"},
        {value: "BJ", label: "Benin"},
        {value: "BM", label: "Bermuda"},
        {value: "BT", label: "Bhutan"},
        {value: "BO", label: "Bolivia, Plurinational State of"},
        {value: "BQ", label: "Bonaire, Sint Eustatius and Saba"},
        {value: "BA", label: "Bosnia and Herzegovina"},
        {value: "BW", label: "Botswana"},
        {value: "BV", label: "Bouvet Island"},
        {value: "BR", label: "Brazil"},
        {value: "IO", label: "British Indian Ocean Territory"},
        {value: "BN", label: "Brunei Darussalam"},
        {value: "BG", label: "Bulgaria"},
        {value: "BF", label: "Burkina Faso"},
        {value: "BI", label: "Burundi"},
        {value: "KH", label: "Cambodia"},
        {value: "CM", label: "Cameroon"},
        {value: "CA", label: "Canada"},
        {value: "CV", label: "Cape Verde"},
        {value: "KY", label: "Cayman Islands"},
        {value: "CF", label: "Central African Republic"},
        {value: "TD", label: "Chad"},
        {value: "CL", label: "Chile"},
        {value: "CN", label: "China"},
        {value: "CX", label: "Christmas Island"},
        {value: "CC", label: "Cocos (Keeling) Islands"},
        {value: "CO", label: "Colombia"},
        {value: "KM", label: "Comoros"},
        {value: "CG", label: "Congo"},
        {value: "CD", label: "Congo, the Democratic Republic of the"},
        {value: "CK", label: "Cook Islands"},
        {value: "CR", label: "Costa Rica"},
        {value: "CI", label: "Cote d'Ivoire"},
        {value: "HR", label: "Croatia"},
        {value: "CU", label: "Cuba"},
        {value: "CW", label: "Cura\u00e7ao"},
        {value: "CY", label: "Cyprus"},
        {value: "CZ", label: "Czech Republic"},
        {value: "DK", label: "Denmark"},
        {value: "DJ", label: "Djibouti"},
        {value: "DM", label: "Dominica"},
        {value: "DO", label: "Dominican Republic"},
        {value: "EC", label: "Ecuador"},
        {value: "EG", label: "Egypt"},
        {value: "SV", label: "El Salvador"},
        {value: "GQ", label: "Equatorial Guinea"},
        {value: "ER", label: "Eritrea"},
        {value: "EE", label: "Estonia"},
        {value: "ET", label: "Ethiopia"},
        {value: "FK", label: "Falkland Islands (Malvinas)"},
        {value: "FO", label: "Faroe Islands"},
        {value: "FJ", label: "Fiji"},
        {value: "FI", label: "Finland"},
        {value: "FR", label: "France"},
        {value: "GF", label: "French Guiana"},
        {value: "PF", label: "French Polynesia"},
        {value: "TF", label: "French Southern Territories"},
        {value: "GA", label: "Gabon"},
        {value: "GM", label: "Gambia"},
        {value: "GE", label: "Georgia"},
        {value: "DE", label: "Germany"},
        {value: "GH", label: "Ghana"},
        {value: "GI", label: "Gibraltar"},
        {value: "GR", label: "Greece"},
        {value: "GL", label: "Greenland"},
        {value: "GD", label: "Grenada"},
        {value: "GP", label: "Guadeloupe"},
        {value: "GU", label: "Guam"},
        {value: "GT", label: "Guatemala"},
        {value: "GG", label: "Guernsey"},
        {value: "GN", label: "Guinea"},
        {value: "GW", label: "Guinea-Bissau"},
        {value: "GY", label: "Guyana"},
        {value: "HT", label: "Haiti"},
        {value: "HM", label: "Heard Island and McDonald Islands"},
        {value: "VA", label: "Holy See (Vatican City State)"},
        {value: "HN", label: "Honduras"},
        {value: "HK", label: "Hong Kong"},
        {value: "HU", label: "Hungary"},
        {value: "IS", label: "Iceland"},
        {value: "IN", label: "India"},
        {value: "ID", label: "Indonesia"},
        {value: "IR", label: "Iran, Islamic Republic of"},
        {value: "IQ", label: "Iraq"},
        {value: "IE", label: "Ireland"},
        {value: "IM", label: "Isle of Man"},
        {value: "IL", label: "Israel"},
        {value: "IT", label: "Italy"},
        {value: "JM", label: "Jamaica"},
        {value: "JP", label: "Japan"},
        {value: "JE", label: "Jersey"},
        {value: "JO", label: "Jordan"},
        {value: "KZ", label: "Kazakhstan"},
        {value: "KE", label: "Kenya"},
        {value: "KI", label: "Kiribati"},
        {value: "KP", label: "Korea, Democratic People's Republic of"},
        {value: "KR", label: "Korea, Republic of"},
        {value: "KW", label: "Kuwait"},
        {value: "KG", label: "Kyrgyzstan"},
        {value: "LA", label: "Lao People's Democratic Republic"},
        {value: "LV", label: "Latvia"},
        {value: "LB", label: "Lebanon"},
        {value: "LS", label: "Lesotho"},
        {value: "LR", label: "Liberia"},
        {value: "LY", label: "Libya"},
        {value: "LI", label: "Liechtenstein"},
        {value: "LT", label: "Lithuania"},
        {value: "LU", label: "Luxembourg"},
        {value: "MO", label: "Macao"},
        {value: "MK", label: "Macedonia, the Former Yugoslav Republic of"},
        {value: "MG", label: "Madagascar"},
        {value: "MW", label: "Malawi"},
        {value: "MY", label: "Malaysia"},
        {value: "MV", label: "Maldives"},
        {value: "ML", label: "Mali"},
        {value: "MT", label: "Malta"},
        {value: "MH", label: "Marshall Islands"},
        {value: "MQ", label: "Martinique"},
        {value: "MR", label: "Mauritania"},
        {value: "MU", label: "Mauritius"},
        {value: "YT", label: "Mayotte"},
        {value: "MX", label: "Mexico"},
        {value: "FM", label: "Micronesia, Federated States of"},
        {value: "MD", label: "Moldova, Republic of"},
        {value: "MC", label: "Monaco"},
        {value: "MN", label: "Mongolia"},
        {value: "ME", label: "Montenegro"},
        {value: "MS", label: "Montserrat"},
        {value: "MA", label: "Morocco"},
        {value: "MZ", label: "Mozambique"},
        {value: "MM", label: "Myanmar"},
        {value: "NA", label: "Namibia"},
        {value: "NR", label: "Nauru"},
        {value: "NP", label: "Nepal"},
        {value: "NL", label: "Netherlands"},
        {value: "NC", label: "New Caledonia"},
        {value: "NZ", label: "New Zealand"},
        {value: "NI", label: "Nicaragua"},
        {value: "NE", label: "Niger"},
        {value: "NG", label: "Nigeria"},
        {value: "NU", label: "Niue"},
        {value: "NF", label: "Norfolk Island"},
        {value: "MP", label: "Northern Mariana Islands"},
        {value: "NO", label: "Norway"},
        {value: "OM", label: "Oman"},
        {value: "PK", label: "Pakistan"},
        {value: "PW", label: "Palau"},
        {value: "PS", label: "Palestine, State of"},
        {value: "PA", label: "Panama"},
        {value: "PG", label: "Papua New Guinea"},
        {value: "PY", label: "Paraguay"},
        {value: "PE", label: "Peru"},
        {value: "PH", label: "Philippines"},
        {value: "PN", label: "Pitcairn"},
        {value: "PL", label: "Poland"},
        {value: "PT", label: "Portugal"},
        {value: "PR", label: "Puerto Rico"},
        {value: "QA", label: "Qatar"},
        {value: "RE", label: "Réunion"},
        {value: "RO", label: "Romania"},
        {value: "RU", label: "Russian Federation"},
        {value: "RW", label: "Rwanda"},
        {value: "BL", label: "Saint Barthélemy"},
        {value: "SH", label: "Saint Helena, Ascension and Tristan da Cunha"},
        {value: "KN", label: "Saint Kitts and Nevis"},
        {value: "LC", label: "Saint Lucia"},
        {value: "MF", label: "Saint Martin (French part)"},
        {value: "PM", label: "Saint Pierre and Miquelon"},
        {value: "VC", label: "Saint Vincent and the Grenadines"},
        {value: "WS", label: "Samoa"},
        {value: "SM", label: "San Marino"},
        {value: "ST", label: "Sao Tome and Principe"},
        {value: "SA", label: "Saudi Arabia"},
        {value: "SN", label: "Senegal"},
        {value: "RS", label: "Serbia"},
        {value: "SC", label: "Seychelles"},
        {value: "SL", label: "Sierra Leone"},
        {value: "SG", label: "Singapore"},
        {value: "SX", label: "Sint Maarten (Dutch part)"},
        {value: "SK", label: "Slovakia"},
        {value: "SI", label: "Slovenia"},
        {value: "SB", label: "Solomon Islands"},
        {value: "SO", label: "Somalia"},
        {value: "ZA", label: "South Africa"},
        {value: "GS", label: "South Georgia and the South Sandwich Islands"},
        {value: "SS", label: "South Sudan"},
        {value: "ES", label: "Spain"},
        {value: "LK", label: "Sri Lanka"},
        {value: "SD", label: "Sudan"},
        {value: "SR", label: "Suriname"},
        {value: "SJ", label: "Svalbard and Jan Mayen"},
        {value: "SZ", label: "Swaziland"},
        {value: "SE", label: "Sweden"},
        {value: "CH", label: "Switzerland"},
        {value: "SY", label: "Syrian Arab Republic"},
        {value: "TW", label: "Taiwan, Province of China"},
        {value: "TJ", label: "Tajikistan"},
        {value: "TZ", label: "Tanzania, United Republic of"},
        {value: "TH", label: "Thailand"},
        {value: "TL", label: "Timor-Leste"},
        {value: "TG", label: "Togo"},
        {value: "TK", label: "Tokelau"},
        {value: "TO", label: "Tonga"},
        {value: "TT", label: "Trinidad and Tobago"},
        {value: "TN", label: "Tunisia"},
        {value: "TR", label: "Turkey"},
        {value: "TM", label: "Turkmenistan"},
        {value: "TC", label: "Turks and Caicos Islands"},
        {value: "TV", label: "Tuvalu"},
        {value: "UG", label: "Uganda"},
        {value: "UA", label: "Ukraine"},
        {value: "AE", label: "United Arab Emirates"},
        {value: "GB", label: "United Kingdom"},
        {value: "US", label: "United States"},
        {value: "UM", label: "United States Minor Outlying Islands"},
        {value: "UY", label: "Uruguay"},
        {value: "UZ", label: "Uzbekistan"},
        {value: "VU", label: "Vanuatu"},
        {value: "VE", label: "Venezuela, Bolivarian Republic of"},
        {value: "VN", label: "Viet Nam"},
        {value: "VG", label: "Virgin Islands, British"},
        {value: "VI", label: "Virgin Islands, U.S."},
        {value: "WF", label: "Wallis and Futuna"},
        {value: "EH", label: "Western Sahara"},
        {value: "YE", label: "Yemen"},
        {value: "ZM", label: "Zambia"},
        {value: "ZW", label: "Zimbabwe"}
    ]

    return countries
}

export function getCounties(){
    const counties = [
        {value: "Aberdeenshire", label: "Aberdeenshire"},
        {value: "Angus", label: "Angus"},
        {value: "Antrim", label: "Antrim"},
        {value: "Argyll and Bute", label: "Argyll and Bute"},
        {value: "Armagh", label: "Armagh"},
        {value: "Ayrshire", label: "Ayrshire"},
        {value: "Banffshire", label: "Banffshire"},
        {value: "Bath and North East Somerset", label: "Bath and North East Somerset"},
        {value: "Bedfordshire", label: "Bedfordshire"},
        {value: "Berkshire", label: "Berkshire"},
        {value: "Berwickshire", label: "Berwickshire"},
        {value: "Blaenau Gwent", label: "Blaenau Gwent"},
        {value: "Borders", label: "Borders"},
        {value: "Bridgend", label: "Bridgend"},
        {value: "Bristol", label: "Bristol"},
        {value: "Buckinghamshire", label: "Buckinghamshire"},
        {value: "Caerphilly", label: "Caerphilly"},
        {value: "Caithness", label: "Caithness"},
        {value: "Cambridgeshire", label: "Cambridgeshire"},
        {value: "Cardiff", label: "Cardiff"},
        {value: "Carmarthenshire", label: "Carmarthenshire"},
        {value: "Ceredigion", label: "Ceredigion"},
        {value: "Cheshire", label: "Cheshire"},
        {value: "Clackmannanshire", label: "Clackmannanshire"},
        {value: "Conwy", label: "Conwy"},
        {value: "Cornwall", label: "Cornwall"},
        {value: "County Durham", label: "County Durham"},
        {value: "Cumbria", label: "Cumbria"},
        {value: "Denbighshire", label: "Denbighshire"},
        {value: "Derbyshire", label: "Derbyshire"},
        {value: "Devon", label: "Devon"},
        {value: "Dorset", label: "Dorset"},
        {value: "Down", label: "Down"},
        {value: "Dumfries and Galloway", label: "Dumfries and Galloway"},
        {value: "Dunbartonshire", label: "Dunbartonshire"},
        {value: "East Ayrshire", label: "East Ayrshire"},
        {value: "East Dunbartonshire", label: "East Dunbartonshire"},
        {value: "East Lothian", label: "East Lothian"},
        {value: "East Renfrewshire", label: "East Renfrewshire"},
        {value: "East Riding of Yorkshire", label: "East Riding of Yorkshire"},
        {value: "East Sussex", label: "East Sussex"},
        {value: "Essex", label: "Essex"},
        {value: "Fermanagh", label: "Fermanagh"},
        {value: "Fife", label: "Fife"},
        {value: "Flintshire", label: "Flintshire"},
        {value: "Gloucestershire", label: "Gloucestershire"},
        {value: "Greater London", label: "Greater London"},
        {value: "Greater Manchester", label: "Greater Manchester"},
        {value: "Gwynedd", label: "Gwynedd"},
        {value: "Hampshire", label: "Hampshire"},
        {value: "Herefordshire", label: "Herefordshire"},
        {value: "Hertfordshire", label: "Hertfordshire"},
        {value: "Highland", label: "Highland"},
        {value: "Inverclyde", label: "Inverclyde"},
        {value: "Isle of Anglesey", label: "Isle of Anglesey"},
        {value: "Isle of Wight", label: "Isle of Wight"},
        {value: "Isles of Scilly", label: "Isles of Scilly"},
        {value: "Kent", label: "Kent"},
        {value: "Kincardineshire", label: "Kincardineshire"},
        {value: "Lancashire", label: "Lancashire"},
        {value: "Leicestershire", label: "Leicestershire"},
        {value: "Lincolnshire", label: "Lincolnshire"},
        {value: "Londonderry", label: "Londonderry"},
        {value: "Merseyside", label: "Merseyside"},
        {value: "Merthyr Tydfil", label: "Merthyr Tydfil"},
        {value: "Midlothian", label: "Midlothian"},
        {value: "Monmouthshire", label: "Monmouthshire"},
        {value: "Moray", label: "Moray"},
        {value: "Neath Port Talbot", label: "Neath Port Talbot"},
        {value: "Newport", label: "Newport"},
        {value: "Norfolk", label: "Norfolk"},
        {value: "North Ayrshire", label: "North Ayrshire"},
        {value: "North Lanarkshire", label: "North Lanarkshire"},
        {value: "North Somerset", label: "North Somerset"},
        {value: "North Yorkshire", label: "North Yorkshire"},
        {value: "Northamptonshire", label: "Northamptonshire"},
        {value: "Northumberland", label: "Northumberland"},
        {value: "Nottinghamshire", label: "Nottinghamshire"},
        {value: "Orkney", label: "Orkney"},
        {value: "Oxfordshire", label: "Oxfordshire"},
        {value: "Pembrokeshire", label: "Pembrokeshire"},
        {value: "Perth and KInross", label: "Perth and KInross"},
        {value: "Powys", label: "Powys"},
        {value: "Renfrewshire", label: "Renfrewshire"},
        {value: "Rhondda Cynon Taff", label: "Rhondda Cynon Taff"},
        {value: "Rutland", label: "Rutland"},
        {value: "Shetland", label: "Shetland"},
        {value: "Shropshire", label: "Shropshire"},
        {value: "Somerset", label: "Somerset"},
        {value: "South Ayrshire", label: "South Ayrshire"},
        {value: "South Gloucestershire", label: "South Gloucestershire"},
        {value: "South Lanarkshire", label: "South Lanarkshire"},
        {value: "South Yorkshire", label: "South Yorkshire"},
        {value: "Staffordshire", label: "Staffordshire"},
        {value: "Stirlingshire", label: "Stirlingshire"},
        {value: "Suffolk", label: "Suffolk"},
        {value: "Surrey", label: "Surrey"},
        {value: "Swansea", label: "Swansea"},
        {value: "Torfaen", label: "Torfaen"},
        {value: "Tyne and Wear", label: "Tyne and Wear"},
        {value: "Tyrone", label: "Tyrone"},
        {value: "Vale of Glamorgan", label: "Vale of Glamorgan"},
        {value: "Warwickshire", label: "Warwickshire"},
        {value: "West Dunbartonshire", label: "West Dunbartonshire"},
        {value: "West Lothian", label: "West Lothian"},
        {value: "West Midlands", label: "West Midlands"},
        {value: "West Sussex", label: "West Sussex"},
        {value: "West Yorkshire", label: "West Yorkshire"},
        {value: "Western Isles", label: "Western Isles"},
        {value: "Wiltshire", label: "Wiltshire"},
        {value: "Worcestershire", label: "Worcestershire"},
        {value: "Wrexham", label: "Wrexham"}
    ]

    return counties
}