import React, { useEffect, useState } from "react";
import { Container, Col, Row, Form, FloatingLabel, Tab, InputGroup, Modal, Button, Nav, Badge, Dropdown } from 'react-bootstrap';
import { useImageContext } from "../../hooks/useImageContext";
import { getImageUrl, updateImage, clientUploadImage } from "../../helpers/images";
import { apiCall, PageScroller } from "../../helpers/api";
import { getFilter, setFilter } from './../../helpers/filters'
import { useSearchParams } from "react-router-dom";
import { getDifference, relativeTimeFormat } from "../../helpers/dates";
import { Link } from "react-router-dom";
import Topbar from "../../components/topbar/Topbar";
import BrandDetails from './../../images/brand-details-blue.svg';
import CampaignHistory from './../../images/campaign-history.svg';
import blueLeftArrow from './../../images/blue-left-arrow.svg';
import searchBlue from './../../images/search-blue.svg';
import eyeDark from './../../images/eye-dark.svg';
import bidsDark from './../../images/bids-dark.svg';
import settings from './../../images/settings.svg';
import greenCheck from "./../../images/check-green.svg"

import AlertModal from "../../components/alerts/alert";
import { getIndustries, getCountries, getPermissions } from "../../helpers/dropdowns"
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import './Users.css';

export default function UserSettings() {  
  const animatedComponents = makeAnimated();
  let [searchParams] = useSearchParams();
  const userID = searchParams.get('id');
  const [user, setUser] = useState(null);
  const [brands, setBrands] = useState([]);
  const [originalBrands, setOriginalBrands] = useState([])
  const [allBrands, setAllBrands] = useState([])
  const [brandFilters, setBrandFilters] = useState({"states":[],"subscriptions":[],"managers":[],"industries":[],"search":""});
  const [influencerFilters, setInfluencerFilters] = useState({"states":[],"lastLogin":"","primaryInterests":[],"interests":[],"genders":[],"ages":[],"socials":[],"referred":[],"followers":0,"engagementRate":0,"search":"","countries":[],"counties":[],"statesUS":[],"cities":[],"ratings":[]});
  const [influencers, setInfluencers] = useState([]);
  const [originalInfluencers, setOriginalInfluencers] = useState([])
  const [allInfluencers, setAllInfluencers] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const { providers } = useImageContext();  
  const [imageUploaded, setImageUploaded] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadingImage, setUploadingImage] = useState(false)
  const [refreshCounter, setRefreshCounter] = useState(0)
  const [editMode, setEditMode] = useState(null);
  const [manualPassword, setManualPassword] = useState(false);
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [passwordDetails, setPasswordDetails] = useState({"new":"","confirm":""});
  const [permissionModal, setPermissionModal] = useState({"visible":false,"type":null,"target":null,"value":null})
  const permissions = getPermissions()
  const [linkedInfluencers, setLinkedInfluencers] = useState([]);
  const [showAccountsPopup, setShowAccountsPopup] = useState(false);
  const [showSharedAccountsPopup, setShowSharedAccountsPopup] = useState(false);
  const [emailTargetUser, setEmailTargetUser] = useState("");
  const [changeMade, setChangeMade] = useState(false);

  const [showAlert, setAlert] = useState({"display":"toast","visible":false,"title":"","content":""});
  const hideAlert = () => setAlert({"display":"toast","visible":false,"title":"","content":""});
  var view = searchParams.get('view');
  if (view == null){
    view = "details";
  }
  const [tabID, setTabID] = useState(view);

  const getUser = async (userID,showLoading=true) => {
    if (showLoading){
      setIsLoaded(false);
    }
    var response = await apiCall(`admin/account?id=${userID}&getBrands=true&getInfluencers=true`,{ "method" : "GET" });
    if (response["success"]){
      setUser(response["success"]["account"]);
      setBrands(response["success"]["brands"]);
      setOriginalBrands(structuredClone(response["success"]["brands"]));
      setInfluencers(response["success"]["influencers"]);
      setOriginalInfluencers(structuredClone(response["success"]["influencers"]));
      setIsLoaded(true);	
      }
  };

  function updateUserData(props,id) {
    var tempUser = user;
    if (id == "password"){
      var tempPassword = passwordDetails;
      tempPassword[props.target.id] = props.target.value;
      setPasswordDetails(tempPassword)
    }
    else if (id == "active" || id == "admin"){
      tempUser[id] = props.target.value == "Yes"
    }
    else {
      tempUser[props.target.id] = props.target.value;
    }
    setUser(tempUser);
    setRefreshCounter(refreshCounter + 1);
    setChangeMade(true)
  }

  const updateAllBrands = async (event,result) => { 
    result = await result;
    if (event.pageData.page >= 1) {
        for (var x in result["brands"]) {
            allBrands.push(result["brands"][x])  
        }
        setAllBrands([...allBrands])
    } else {
      setAllBrands(result["brands"])
    }
  }

  const updateAllInfluencers = async (event,result) => { 
    result = await result;
    if (event.pageData.page >= 1) {
        for (var x in result["influencers"]) {
          allInfluencers.push(result["influencers"][x])  
        }
        setAllInfluencers([...allInfluencers])
    } else {
      setAllInfluencers(result["influencers"])
    }
  }

  function resetBrands(){
    setBrands(structuredClone(originalBrands))
  }

  function resetInfluencers(){
    setInfluencers(structuredClone(originalInfluencers))
  }

  function updateBrand(brand,add,autoCommit) {
    var tempBrands = brands
    if (add){
      if (!brand.hasOwnProperty("permissions")){
        brand.permissions = ["All"]
      }
      tempBrands.push(brand)
      setBrands(tempBrands)
    }
    else {
      tempBrands = brands.filter(existingBrand => brand._id !== existingBrand._id)
      setBrands(tempBrands)
    }
    if (autoCommit){
      submitUpdates("brands",tempBrands)
    }
  }

  function updateInfluencers(influencer,add,autoCommit) {
    var tempInfluencers = influencers
    if (add){
      if (!influencer.hasOwnProperty("permissions")){
        influencer.permissions = ["All"]
      }
      tempInfluencers.push(influencer)
      setInfluencers(tempInfluencers)
    }
    else {
      tempInfluencers = influencers.filter(existingInfluencer => influencer._id !== existingInfluencer._id)
      setInfluencers(tempInfluencers)
    }
    if (autoCommit){
      submitUpdates("influencers",tempInfluencers)
    }
  }

  const updateSearch = (value,type,delay=0) => {
    if (type == "brand"){
      var filters = brandFilters;
      filters.search = value.target.value.toLowerCase();
      setBrandFilters(Object.assign({}, brandFilters, filters));
      setFilter(filters,"brand")
    }
    else if (type == "influencer"){
      var filters = influencerFilters;
      filters.search = value.target.value.toLowerCase();
      setInfluencerFilters(Object.assign({}, influencerFilters, filters));
      setFilter(filters,"influencer")
    }
  }

  async function submitUpdates(type,updatedValue=null,ignoreInfluencer=false){
    if (type == "brands"){
      setOriginalBrands(structuredClone(brands))
      var tempBrands = updatedValue ? updatedValue : brands;
      var brandsToSend = []
      tempBrands.forEach(brand => {
        brandsToSend.push({"id":brand._id,"permissions":brand.permissions})
      })
      var response = await apiCall(`admin/accountBrands?id=${userID}`,{ "method" : "PUT", "data" : {"brands":brandsToSend}});
      if (response["success"]){
          setAlert({"display":"toast","visible":true,"title":"Brands Updated","content":`Brand associations updated successfully`,"className":"bg-success text-white"})
      }
      else if (response["failure"]) {
          setAlert({"display":"toast","visible":true,"title":"Could not update brands","content":response["failure"]["error"],"className":"bg-danger text-white"})
      }
    }
    if (type == "influencers"){
      setOriginalInfluencers(structuredClone(influencers))
      var tempInfluencers = updatedValue ? updatedValue : influencers;
      var influencersToSend = []
      tempInfluencers.forEach(influencer => {
        influencersToSend.push({"id":influencer._id,"permissions":influencer.permissions})
      })
      var response = await apiCall(`admin/accountInfluencers?id=${userID}`,{ "method" : "PUT", "data" : {"influencers":influencersToSend}});
      if (response["success"]){
          setAlert({"display":"toast","visible":true,"title":"Influencers Updated","content":`Influencer associations updated successfully`,"className":"bg-success text-white"})
      }
      else if (response["failure"]) {
          setAlert({"display":"toast","visible":true,"title":"Could not update influencers","content":response["failure"]["error"],"className":"bg-danger text-white"})
      }
    }
    if (type == "account"){
      var profileImage = user.profileImage;
      if (!imageUploaded && selectedImage) {
        var profileImage = await clientUploadImage(selectedImage,setAlert)
        setImageUploaded(true)
      }
      var response = await apiCall(`admin/account?id=${userID}`,{ "method" : "PUT", "data" : {"firstName":user.firstName,"lastName":user.lastName,"email":user.email,"phoneNumber":user.phoneNumber,"active":user.active,"profileImage":profileImage,"admin":user.admin,"targetUser":ignoreInfluencer ? "ignore" : emailTargetUser} });
      if (response["success"]){
          setAlert({"display":"toast","visible":true,"title":"Account Updated","content":`${user.firstName} Updated Successfully`,"className":"bg-success text-white"})
          setChangeMade(false)
      }
      else if (response["failure"]) {
        if (response["failure"]["error"] === "Multiple influencers found") {
          setLinkedInfluencers(response["failure"]["influencers"])
          setShowAccountsPopup(true)
          setEmailTargetUser(response["failure"]["influencers"][0]["id"])
        }
        else if (response["failure"]["error"] === "Influencer associated to multiple accounts") {
          setLinkedInfluencers(response["failure"]["influencer"])
          setShowSharedAccountsPopup(true)
          setEmailTargetUser(response["failure"]["influencer"]["id"])
        }
        else {
          setAlert({"display":"toast","visible":true,"title":"Could not update account","content":response["failure"]["error"],"className":"bg-danger text-white"})
        }
      }
    }
    if (type == "password"){
      var response = await apiCall(`admin/accountPassword?id=${userID}`,{ "method" : "PUT", "data" : {"confirm":passwordDetails.confirm,"new":passwordDetails.new,"sendPasswordReset":false} });
      if (response["success"]){
          setAlert({"display":"toast","visible":true,"title":"Account Password Updated","content":`Account password updated successfully`,"className":"bg-success text-white"})
          setShowPasswordReset(false);
          setManualPassword(false);
          setPasswordDetails({new:"",confirm:""});
      }
      else if (response["failure"]) {
          setAlert({"display":"toast","visible":true,"title":"Could not update account password","content":response["failure"]["error"],"className":"bg-danger text-white"})
      }
    }
  }

  function updatePermissions(id,value,commit=false){
    if (commit){
      if (permissionModal.type == "brands"){
        var tempBrands = brands
        tempBrands.forEach(brand => {
          if (brand._id == permissionModal.target._id){
            brand.permissions = permissionModal.value
          }
        })
        setBrands(tempBrands)
        submitUpdates("brands")
        setPermissionModal({"visible":false,"type":null,"target":null,"value":null})
        return
      }
      else if (permissionModal.type == "influencers"){
        var tempInfluencers = influencers
        tempInfluencers.forEach(influencer => {
          if (influencer._id == permissionModal.target._id){
            influencer.permissions = permissionModal.value
          }
        })
        setInfluencers(tempInfluencers)
        submitUpdates("influencers")
        setPermissionModal({"visible":false,"type":null,"target":null,"value":null})
        return
      }
    }
    var tempPermission = permissionModal
    if (id == "owner"){
      if (value){
        tempPermission.value = ["All"]
      }
      else {
        tempPermission.value = []
      }
    }
    else {
      tempPermission.value = value.map(item => item.value);
    }
    setPermissionModal(tempPermission)
    setRefreshCounter(refreshCounter + 1)
  }

  function editPermissions(member,type){
    setPermissionModal({"visible":true,"type":type,"target":member,"value":member.permissions})
  }

  async function sendPasswordResetLink(){
    var response = await apiCall(`admin/accountPassword?id=${userID}`,{ "method" : "PUT", "data" : {"confirm":"","new":"","sendPasswordReset":true} });
      if (response["success"]){
          setAlert({"display":"toast","visible":true,"title":"Account Password Link Created","content":`Password reset link created successfully`,"className":"bg-success text-white"})
          setShowPasswordReset(false);
          setManualPassword(false);
          setPasswordDetails({new:"",confirm:""});
      }
      else if (response["failure"]) {
          setAlert({"display":"toast","visible":true,"title":"Could not create password reset link","content":response["failure"]["error"],"className":"bg-danger text-white"})
      }
  }

  async function disableUser(reverse=false){
    var response;
    if (reverse){
        response = await apiCall('admin/enableUser',{ "method" : "POST", "data" : {id: user._id, type: "account"} });
        if (response["success"]){
            setAlert({"display":"toast","visible":true,"title":"User Enabled","content":`${user.firstName} ${user.lastName} enabled successfully`,"className":"bg-success text-white"})
        }
        else if (response["failure"]) {
            setAlert({"display":"toast","visible":true,"title":"Could not enable user","content":response["failure"]["error"],"className":"bg-danger text-white"})
        }
    }
    else {
        response = await apiCall('admin/disableUser',{ "method" : "POST", "data" : {id: user._id, type: "account"} });
        if (response["success"]){
            setAlert({"display":"toast","visible":true,"title":"User Disabled","content":`${user.firstName} ${user.lastName} disabled successfully`,"className":"bg-success text-white"})
        }
        else if (response["failure"]) {
            setAlert({"display":"toast","visible":true,"title":"Could not disable user","content":response["failure"]["error"],"className":"bg-danger text-white"})
        }
    }
    getUser(userID,false)
  }

  async function forceLogout(){
    var response = await apiCall('admin/forceLogout',{ "method" : "POST", "data" : {id: user._id} });
    if (response["success"]){
        setAlert({"display":"toast","visible":true,"title":"User Logged Out","content":`${user.firstName} ${user.lastName} has been logged out successfully`,"className":"bg-success text-white"})
    }
    else if (response["failure"]) {
        setAlert({"display":"toast","visible":true,"title":"Could not force log out user","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
  }

  useEffect(() => {
    if (getFilter("brand")) {
      setBrandFilters(JSON.parse(getFilter("brand")))
    }
    if (getFilter("influencer")) {
      setInfluencerFilters(JSON.parse(getFilter("influencer")))
    }
    getUser(userID);
  }, []);
  if (!isLoaded) {
    return <FullPageSpinner />;
  }
  else {
    return (
      <div className="bg-page UserSettings">
        <Topbar />
        <div className="pageName">User Settings</div>
        <Container id="applicant-list-container" className="fullscreenWithTopBar top-0" fluid>
          {showAccountsPopup && (
            <Modal show centered>
              <Modal.Header>
                <Modal.Title>Multiple associated influencers found</Modal.Title>
              </Modal.Header>
      
              <Modal.Body className="pb-0">
                <p>Pick which influencer account's email address to update:</p>
                <Row>
                  <Col>
                      <Form.Group className="mb-3">
                          <Form.Select id="targetUser" onChange={e => setEmailTargetUser(e.target.value)} >
                            {linkedInfluencers.map(influencer => <option key={influencer.id} value={influencer.id}> {`${influencer.name} (${influencer.email})`} </option>)}
                          </Form.Select>
                      </Form.Group>
                  </Col>
                </Row>
              </Modal.Body>
      
              <Modal.Footer>
                <Button variant="secondary" onClick={()=>{setShowAccountsPopup(false); submitUpdates("account",null,true); setEmailTargetUser("");}}>Don't Update Influencers</Button>
                <Button variant="primary" type="submit" onClick={() => {submitUpdates("account"); setShowAccountsPopup(false); setEmailTargetUser("");}}>Update</Button>
              </Modal.Footer>
            </Modal>
          )}
          {showSharedAccountsPopup && (
            <Modal show centered>
              <Modal.Header>
                <Modal.Title>Do you want to update this shared influencer</Modal.Title>
              </Modal.Header>
      
              <Modal.Body className="pb-0">
                <p>An influencer associated with this account is also associated with other accounts. Do you want to update them?</p>
                <Row>
                  <Col>
                    <div>{linkedInfluencers.name} ({linkedInfluencers.email})</div>
                  </Col>
                </Row>
              </Modal.Body>
      
              <Modal.Footer>
                <Button variant="secondary" type="submit" onClick={() => {setShowSharedAccountsPopup(false); submitUpdates("account",null,true); setEmailTargetUser("");}}>Ignore</Button>
                <Button variant="primary" type="submit" onClick={() => {submitUpdates("account"); setShowSharedAccountsPopup(false); setEmailTargetUser("");}}>Update</Button>
              </Modal.Footer>
            </Modal>
          )}
          <Tab.Container id="campaign-tab" activeKey={tabID} onSelect={(key) => setTabID(key)}>
          <Container className="px-0 px-md-3">
            <AlertModal modal={showAlert} onClose={hideAlert} />
            <Row className="d-flex justify-content-center mb-4">
              <Col sm={12} md={4} lg={4} xl={3} xxl={3}>
                <div className="pt-lg-4 mb-lg-5 px-3 px-md-0 position-sticky top-0">
                  <div className="d-flex align-items-center justify-content-between mt-4 mb-4 pb-2">
                    <h1 className="h3 text-primary tragedyRegularFont fw-700 mb-0">User Settings</h1>
                  </div>

                  {permissionModal.visible && (
                    <Modal centered show={permissionModal.visible} onHide={() => setPermissionModal({"visible":false,"type":null,"target":null,"value":null})}>
                      <Modal.Header closeButton>
                        <Modal.Title>Set Permissions</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form.Group className="mb-3">
                            <Form.Label className="mb-1">All</Form.Label>
                            <input type="checkbox" className="bg-gray border-gray" id="setOwner" checked={permissionModal.value.includes("All")} onChange={(e) => updatePermissions("owner",e.target.checked)} />
                        </Form.Group>
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          defaultValue={permissions.filter(function(permission) {return permissionModal.value.includes(permission.value)})}
                          options={permissions.filter(function(permission) {return permission.context.includes(permissionModal.type)})} 
                          isDisabled={permissionModal.value.includes("All")}
                          id="permissions" 
                          onChange={ (choice) => updatePermissions("permissions",choice) } 
                        /> 
                      </Modal.Body>
                      <Modal.Footer>
                        <Button size="sm" variant="outline-secondary" onClick={() => setPermissionModal({"visible":false,"type":null,"target":null,"value":null})}>
                          Cancel
                        </Button>
                       <Button size="sm" variant="success" onClick={() => updatePermissions(null,null,true)}>
                          Save
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  )}

                  {permissionModal.visible && (
                    <Modal centered show={permissionModal.visible} onHide={() => setPermissionModal({"visible":false,"type":null,"target":null,"value":null})}>
                      <Modal.Header closeButton>
                        <Modal.Title>Set Permissions</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form.Group className="mb-3">
                            <Form.Label className="mb-1">All</Form.Label>
                            <input type="checkbox" className="bg-gray border-gray" id="setOwner" checked={permissionModal.value.includes("All")} onChange={(e) => updatePermissions("owner",e.target.checked)} />
                        </Form.Group>
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          defaultValue={permissions.filter(function(permission) {return permissionModal.value.includes(permission.value)})}
                          options={permissions.filter(function(permission) {return permission.context.includes(permissionModal.type)})} 
                          isDisabled={permissionModal.value.includes("All")}
                          id="permissions" 
                          onChange={ (choice) => updatePermissions("permissions",choice) } 
                        /> 
                      </Modal.Body>
                      <Modal.Footer>
                        <Button size="sm" variant="outline-secondary" onClick={() => setPermissionModal({"visible":false,"type":null,"target":null,"value":null})}>
                          Cancel
                        </Button>
                       <Button size="sm" variant="success" onClick={() => updatePermissions(null,null,true)}>
                          Save
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  )}

                  <div className="mb-4 pb-2">
                    <div className="bg-light shadow rounded-xl">
                      <div className="p-3">
                        <div className="d-flex align-items-center w-100">
                          <img src={getImageUrl(providers,user.profileImage,["w_80","c_scale"])} width="42px" height="42px" className="object-fit-cover rounded me-2 border p-1" />
                          <div className="w-75">
                            <div className="text-truncate w-100 fw-bold">{user.firstName} {user.lastName}</div>
                            <div className="smaller text-muted text-truncate w-100">{user.email}</div>
                          </div> 
                        </div>
                        
                      </div>
                      <div className="p-3 border-top smaller">
                        <div><span className="fw-500">Last Active:</span> {relativeTimeFormat(user.lastSeen*1000)}</div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-3 pb-2">
                    <Nav variant="tabs" className="border-0">
                      <div className="bg-light shadow rounded-xl brandSettingsNav w-100">
                        <Nav.Link eventKey="details" className="p-3 d-flex align-items-center justify-content-between w-100">
                            <span className="d-flex align-items-center">
                              <Nav.Item>
                                <img src={BrandDetails} className="me-2" /> Account
                              </Nav.Item>
                            </span>
                            <span>
                              <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" stroke="none" fill="#A7A7A7"/>
                              </svg>
                            </span>
                        </Nav.Link>
                        <Nav.Link eventKey="brands" className="p-3 d-flex align-items-center justify-content-between border-top w-100">
                          <span className="d-flex align-items-center">
                            <Nav.Item>
                              <img src={CampaignHistory} className="me-2" /> Brands
                            </Nav.Item>
                          </span>
                          <span>
                            <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" fill="#A7A7A7"/>
                            </svg>
                          </span>
                        </Nav.Link>
                        <Nav.Link eventKey="influencers" className="p-3 d-flex align-items-center justify-content-between border-top w-100">
                          <span className="d-flex align-items-center">
                            <Nav.Item>
                              <img src={CampaignHistory} className="me-2" /> Influencers
                            </Nav.Item>
                          </span>
                          <span>
                            <svg width="10" height="14" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0.202121 11.0106C0.351235 11.1566 0.553783 11.2305 0.756918 11.2304C0.941136 11.2304 1.12582 11.1697 1.27148 11.0469L7.11501 6.11921C7.26929 5.98911 7.35699 5.80648 7.35699 5.61522C7.35699 5.42396 7.26929 5.24133 7.11501 5.11122L1.27148 0.183585C0.965268 -0.0746763 0.486351 -0.0585183 0.202078 0.219836C-0.0822364 0.49819 -0.0643693 0.933236 0.241974 1.19154L5.48786 5.61522L0.242016 10.0389C-0.0643276 10.2972 -0.0821941 10.7322 0.202121 11.0106Z" fill="#A7A7A7"/>
                            </svg>
                          </span>
                        </Nav.Link>
                      </div>
                    </Nav>
                  </div>

                  <div className="mb-4 pb-2">
                    <div className="bg-light shadow rounded-xl brandSettingsNav">
                      <div className={`p-3 d-flex align-items-center justify-content-between ${user.active && "text-danger"}`}>
                          { user.active ? <a className="dropdown-item text-danger fw-bold" onClick={e => disableUser()}>Disable</a> : <a className="dropdown-item text-success fw-bold" onClick={e => disableUser(true)}>Enable</a> }
                          <span>
                          {user.active ? (
                            <svg width="18" height="18" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12.2179 2.78217C10.9836 1.54781 9.31599 0.846273 7.57266 0.825523L7.57213 0.825H7.5C5.72969 0.825 4.03197 1.52825 2.78006 2.78005L2.78005 2.78006C1.52828 4.03194 0.825 5.72988 0.825 7.5C0.825 9.27012 1.52825 10.968 2.78005 12.2199L2.78006 12.22C4.03194 13.4717 5.72988 14.175 7.5 14.175C9.27012 14.175 10.968 13.4717 12.2199 12.22L12.2199 12.2199C13.4717 10.9681 14.175 9.27012 14.175 7.5V7.49981C14.1731 5.7301 13.4693 4.03351 12.2179 2.78217ZM3.78862 3.57622C4.78153 2.63785 6.0932 2.11189 7.45825 2.10359L7.50006 2.10358C8.71196 2.1032 9.88633 2.51264 10.8339 3.26232L3.26056 10.8356C2.44731 9.8059 2.03617 8.51271 2.10849 7.19817L1.93375 7.18855L2.10849 7.19817C2.18427 5.82029 2.78565 4.52392 3.78861 3.57623L3.78862 3.57622ZM11.2114 11.4239C10.2184 12.3623 8.90674 12.8882 7.54166 12.8965L7.49995 12.8965C6.28804 12.8969 5.11367 12.4875 4.16615 11.7378L11.7394 4.1645C12.5527 5.19421 12.9638 6.48739 12.8915 7.80194C12.8157 9.17982 12.2144 10.4762 11.2114 11.4239L11.2114 11.4239Z" fill="#FF0000" stroke="#FF0000" strokeWidth="0.35"/>
                            </svg>
                          ) : (
                              <img src={greenCheck}/>
                          )}
                          </span>
                        </div>
                    </div>
                  </div>

                  <div className="mb-2">
                    <div className="bg-light shadow rounded-xl brandSettingsNav">
                      <a href="/Administration/Users" className="p-3 d-flex align-items-center">
                        <img src={blueLeftArrow} className="me-2" /> Back to Users
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={8} lg={8} xl={9} xxl={8}>
                <Tab.Content>
                  <Tab.Pane eventKey="details">
                    <div className="pb-2 d-block d-md-none">
                      <div className="d-flex align-items-center mb-2 py-2 px-3">
                        <a className="backArrow" onClick={() => setTabID(null)}>
                          <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                          </svg>
                        </a>
                        <div className="sectionTitle w-100 text-center">Edit Account</div>
                      </div>
                    </div>
                    <div className="px-md-4 pt-md-4 mt-0 mt-lg-4 mb-md-4">
                      <div className="px-md-4 py-4 bg-white shadow rounded-xl">
                        <h4 className="mt-md-4 mb-2 ms-4">Edit Account</h4>
                        <div className="text-center mb-2">
                          <div className="d-inline-block position-relative">
                            <img src={selectedImage ? selectedImage.url : getImageUrl(providers,user.profileImage,["w_200","c_scale"])} width="150px" height="150px" className="object-fit-cover rounded m-3 border bg-light p-2" />
                            <label htmlFor="account-upload">
                              <a className="position-absolute end-0 bottom-0">
                                {uploadingImage ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <svg width="36" height="36" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="11.5" cy="11.5" r="11" fill="white" stroke="#E3E3E3"/>
                                  <path d="M15.9851 8.90875L14.0912 7.01483C14.0707 6.99506 14.0383 6.99506 14.0179 7.01483L7.74132 13.2916C7.73366 13.2992 7.72864 13.309 7.72688 13.3197L7.72073 13.3128L7 16L9.68722 15.2793L9.68038 15.2724H9.68032C9.69099 15.2707 9.70085 15.2656 9.70844 15.258L15.9852 8.98207C16.0049 8.9616 16.0049 8.92921 15.9852 8.90874L15.9851 8.90875Z" fill="#797979"/>
                                  <path d="M7.5 13L10 15.5" stroke="white"/>
                                </svg>}
                              </a>
                            </label>
                            <Form.Control type="file" id="account-upload" className="fileUploadBtn position-absolute end-0 bottom-0" accept=".png, .jpg, .jpeg, .webp" onChange={(e) => {updateImage(e,setSelectedImage,setUploadingImage,setAlert); setImageUploaded(false)}}/>
                          </div>
                        </div>
                        <div className="mx-4">

                          <Row>
                            <Col sm={12} md={6}>
                              <FloatingLabel label="First Name" className="mb-3">
                                <Form.Control type="text" value={user.firstName} onChange={(e) => updateUserData(e)} id="firstName" className="rounded-0 border-0 border-bottom" />
                              </FloatingLabel>
                            </Col>
                            <Col sm={12} md={6}>
                              <FloatingLabel label="Last Name" className="mb-3">
                                <Form.Control type="text" value={user.lastName} onChange={(e) => updateUserData(e)} id="lastName" className="rounded-0 border-0 border-bottom" />
                              </FloatingLabel>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm={12} md={6}>
                              <FloatingLabel label="Email" className="mb-3">
                                <Form.Control type="email" value={user.email} onChange={(e) => updateUserData(e)} id="email" className="rounded-0 border-0 border-bottom" />
                              </FloatingLabel>
                            </Col>
                            <Col sm={6} md={6} lg={6}>  
                              <FloatingLabel label="Phone Number" className="mb-3">
                                <Form.Control type="text" value={user.phoneNumber} onChange={(e) => updateUserData(e)} id="phoneNumber" className="rounded-0 border-0 border-bottom" />
                              </FloatingLabel>
                            </Col>
                            <Col sm={6} md={6} lg={3}>   
                              <FloatingLabel controlId="floatingSelect" label="Active" className="mb-3">
                                <Form.Select value={user.active ? "Yes" : "No"} onChange={(e) => updateUserData(e,"active")}>
                                  <option>Yes</option>
                                  <option>No</option>
                                </Form.Select>
                              </FloatingLabel>
                            </Col>
                            <Col sm={6} md={6} lg={3}>  
                              <FloatingLabel controlId="floatingSelect" label="Admin" className="mb-3">
                                <Form.Select value={user.admin ? "Yes" : "No"} onChange={(e) => updateUserData(e,"admin")} >
                                  <option>Yes</option>
                                  <option>No</option>
                                </Form.Select>
                              </FloatingLabel>
                            </Col>
                          </Row>

                          <div className="d-flex align-items-center justify-content-between mt-2 mb-4">
                            <span className="text-muted small d-none d-md-block">Member since {new Date(user.meta.created*1000).toLocaleString('en-US', { month:"long", year:"numeric" })}</span>
                            <div>
                              <button type="button" className="btn btn-sm btn-outline-danger me-2" onClick={() => setShowPasswordReset(true)}>Reset Password</button>
                              <button type="button" className="btn btn-sm btn-primary" disabled={!changeMade} onClick={() => submitUpdates("account")}>Save</button>
                            </div>
                          </div>

                          <div className="d-flex align-items-center justify-content-between mt-2 mb-4">
                              <button type="button" className="btn btn-sm btn-outline-danger me-2" onClick={() => forceLogout()}>Force Logout</button>
                          </div>

                          <Modal centered show={showPasswordReset} onHide={() => {setShowPasswordReset(false);setManualPassword(false);setPasswordDetails({new:"",confirm:""})}}>
                            <Modal.Header className="bg-danger text-white border-0" closeButton>
                              <Modal.Title className="h6">Reset Password</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="d-flex justify-content-evenly py-5">
                              <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => setManualPassword(true)}>Set Password Manually</button>
                              <button type="button" className="btn btn-sm btn-primary" onClick={() => sendPasswordResetLink()}>Send Password Reset Link</button>
                              {manualPassword && (<>
                              <FloatingLabel label="New Password" className="mb-3">
                                <Form.Control type="password" id="new" value={passwordDetails.new} onChange={(e) => updateUserData(e,"password")} placeholder="New Password" />
                              </FloatingLabel>
                              <FloatingLabel label="Confirm Password" className="mb-0">
                                <Form.Control type="password" id="confirm" value={passwordDetails.confirm} onChange={(e) => updateUserData(e,"password")} placeholder="Confirm Password" />
                              </FloatingLabel>
                              </>)}
                            </Modal.Body>
                            <Modal.Footer>
                              <Button size="sm" variant="outline-secondary" onClick={() => {setShowPasswordReset(false);setManualPassword(false);setPasswordDetails({new:"",confirm:""})}}>
                                Cancel
                              </Button>
                              {manualPassword && <Button size="sm" variant="success" onClick={() => submitUpdates("password")}>
                                Save
                              </Button>}
                            </Modal.Footer>
                          </Modal>

                        </div>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="brands">
                    <div className="pb-2 d-block d-md-none">
                      <div className="d-flex align-items-center mb-2 py-2 px-3">
                        <a className="backArrow" onClick={() => setTabID(null)}>
                          <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                          </svg>
                        </a>
                        <div className="sectionTitle w-100 text-center">Brands</div>
                      </div>
                    </div>
                    <div className="px-md-4 pt-md-4 mt-0 mt-lg-4 mb-md-4">
                      <div className="px-md-4 py-md-4 bg-white shadow rounded-xl">
                        <div className="associatedProfiles">
                          <div className="d-flex align-items-center justify-content-between my-4 ms-4 pb-2">
                            <h4 className="d-none d-md-block mb-0">Brands</h4>
                            <div className="d-flex flex-nowrap">
                              {editMode != "brands" ? <Button variant="outline-primary" size="sm" className="text-nowrap me-2 me-md-3" onClick={() => setEditMode("brands")}>Update <span className='d-none d-lg-inline'>Brands</span></Button> : <><Button variant="outline-primary" size="sm" className="text-nowrap me-2 me-md-3" onClick={() => {setEditMode(null);submitUpdates("brands")}}>Save <span className='d-none d-lg-inline'>Brands</span></Button><Button variant="danger" size="sm" className="text-nowrap me-2 me-md-3" onClick={() => {setEditMode(null);resetBrands()}}>Cancel</Button></>}
                              <InputGroup size="sm" className="me-4 w-auto">
                                <Form.Control placeholder="Search" className="border-end-0 form-control-sm" aria-label="Search" defaultValue={brandFilters.search} onChange={e => updateSearch(e,"brand",500)}/>
                                <InputGroup.Text className="bg-white border-end">
                                    <a><img src={searchBlue} /></a>
                                </InputGroup.Text>
                              </InputGroup>
                            </div>
                          </div>
                          <div className="mx-4 overflow-visible">
                            <table className="border-transparent table">
                              <thead className="small">
                                <tr>
                                  <th>Name</th>
                                  <th>Industry</th>
                                  <th className="text-center">Live Campaigns</th>
                                  <th className="text-center">Total Campaigns</th>
                                  <th className="text-center">Country</th>
                                  {editMode != "brands" && <th className="text-center">Permissions</th>}
                                  {editMode == "brands" ? <th className="text-center" width="60px">Add/Remove</th> : <th className="text-center" width="60px">Edit</th>}
                                </tr>
                              </thead>
                              <tbody className="small text-wrap">
                                {editMode == "brands" ? (
                                  <>
                                  {allBrands.map(brand => (
                                  <tr key={brand._id}>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <img src={getImageUrl(providers,brand.profileImage,["w_40","c_scale"])} width="32px" height="32px" className="object-fit-cover rounded border me-2" />
                                        <div className="text-wrap text-truncate">{brand.name}</div>
                                      </div>
                                    </td>
                                    <td><span className="tags text-nowrap">{brand.industry}</span></td>
                                    <td align="center">{brand.stats.liveCampaigns}</td>
                                    <td align="center">{brand.stats.totalCampaigns}</td>
                                    <td align="center"><img src={`https://hatscripts.github.io/circle-flags/flags/${brand.country.toLowerCase()}.svg`} className="border rounded-circle me-2" width="26px" /></td>
                                    {brands.some(existingBrand => brand._id === existingBrand._id) ? <td align="center"><a href="#" onClick={() => updateBrand(brand,false)}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#dc3545"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/></svg></a></td> : <td align="center"><a href="#" onClick={() => updateBrand(brand,true)}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#198754"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg></a></td>}
                                  </tr>
                                ))}
                                  </>
                                )
                                :
                                ( 
                                <>
                                  {brands.map(brand => (
                                    <tr key={brand._id}>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <img src={getImageUrl(providers,brand.profileImage,["w_40","c_scale"])} width="32px" height="32px" className="object-fit-cover rounded border me-2" />
                                          <div className="text-wrap text-truncate">{brand.name}</div>
                                        </div>
                                      </td>
                                      <td><span className="tags text-nowrap">{brand.industry}</span></td>
                                      <td align="center">{brand.stats.liveCampaigns}</td>
                                      <td align="center">{brand.stats.totalCampaigns}</td>
                                      <td align="center"><img src={`https://hatscripts.github.io/circle-flags/flags/${brand.country.toLowerCase()}.svg`} className="border rounded-circle me-2" width="26px" /></td>
                                      <td align="center">{brand.permissions.includes("All") ? <span className="tags text-nowrap">All</span> : <>{permissions.filter(function(permission) {return brand.permissions.includes(permission.value)}).slice(0,2).map(permission => <span className="tags text-nowrap" key={permission.value}>{permission.label}</span>)}</>}{brand.permissions.length > 2 && (<span className="tags text-nowrap">+{brand.permissions.length - 2}</span>)}</td>
                                      <td>
                                        <Dropdown className="brandAction">
                                            <Dropdown.Toggle variant="default" className="border-0">
                                              <img src={settings} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              <a href="#" className="dropdown-item" onClick={() => editPermissions(brand,"brands")}>Edit Permissions</a>
                                            <a href="#" className="dropdown-item" onClick={() => updateBrand(brand,false,true)}>Delete</a>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                      </td>
                                    </tr>
                                  ))}
                                </> 
                                )}
                              </tbody>
                            </table>
                            {editMode == "brands" && <PageScroller endpoint="brands" setState={updateAllBrands} filter={brandFilters} pageSize={25} />}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="influencers">
                    <div className="pb-2 d-block d-md-none">
                      <div className="d-flex align-items-center mb-2 py-2 px-3">
                        <a className="backArrow" onClick={() => setTabID(null)}>
                          <svg width="18" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8134 0.685713C10.5881 0.442875 10.2819 0.319992 9.97492 0.320057C9.6965 0.320057 9.41738 0.421144 9.19723 0.625416L0.365555 8.82172C0.132373 9.03812 -0.000166049 9.34189 -0.000166076 9.66003C-0.000166104 9.97816 0.132373 10.2819 0.365555 10.4983L9.19722 18.6946C9.66003 19.1242 10.3838 19.0973 10.8135 18.6343C11.2432 18.1713 11.2162 17.4477 10.7532 17.0181L2.82476 9.66003L10.7531 2.30204C11.2161 1.87233 11.2431 1.14871 10.8134 0.685713Z" fill="#002651"/>
                          </svg>
                        </a>
                        <div className="sectionTitle w-100 text-center">Influencers</div>
                      </div>
                    </div>
                    <div className="px-md-4 pt-md-4 mt-0 mt-lg-4 mb-md-4">
                      <div className="px-md-4 py-md-4 bg-white shadow rounded-xl">
                        <div className="associatedProfiles">
                          <div className="d-flex align-items-center justify-content-between my-4 ms-4 pb-2">
                            <h4 className="d-none d-md-block mb-0">Influencers</h4>
                            <div className="d-flex flex-nowrap">
                              {editMode != "influencers" ? <Button variant="outline-primary" size="sm" className="text-nowrap me-2 me-md-3" onClick={() => setEditMode("influencers")}>Update <span className='d-none d-lg-inline'>Influencers</span></Button> : <><Button variant="outline-primary" size="sm" className="text-nowrap me-2 me-md-3" onClick={() => {setEditMode(null);submitUpdates("influencers")}}>Save <span className='d-none d-lg-inline'>Influencers</span></Button><Button variant="danger" size="sm" className="text-nowrap me-2 me-md-3" onClick={() => {setEditMode(null);resetInfluencers()}}>Cancel</Button></>}
                              <InputGroup size="sm" className="me-4 w-auto">
                                <Form.Control placeholder="Search" className="border-end-0 form-control-sm" aria-label="Search" defaultValue={influencerFilters.search} onChange={e => updateSearch(e,"influencer",500)}/>
                                <InputGroup.Text className="bg-white border-end">
                                  <a><img src={searchBlue} /></a>
                                </InputGroup.Text>
                              </InputGroup>
                            </div>
                          </div>
                          <div className="mx-4">
                            <table className="border-transparent table">
                              <thead className="small">
                                <tr>
                                  <th>Name</th>
                                  <th>Primary Category</th>
                                  <th className="text-center">Active Bids</th>
                                  <th className="text-center">Total Bids</th>
                                  <th className="text-center">Last Seen</th>
                                  <th className="text-center">Country</th>
                                  {editMode != "influencers" && <th className="text-center">Permissions</th>}
                                  {editMode == "influencers" ? <th className="text-center" width="60px">Add/Remove</th> : <th className="text-center" width="60px">Edit</th>}
                                </tr>
                              </thead>
                              <tbody className="small text-wrap">
                              {editMode == "influencers" ? (
                                  <>
                                  {allInfluencers.map(influencer => (
                                    <tr key={influencer._id}>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <img src={getImageUrl(providers,influencer.profileImage,["w_40","c_scale"])} width="32px" height="32px" className="object-fit-cover rounded border me-2" />
                                          <div className="text-wrap text-truncate">{influencer.name}</div>
                                        </div>
                                      </td>
                                      <td><span className="tags text-nowrap">{influencer.interests[0]}</span></td>
                                      <td align="center">{influencer.stats.activeBids}</td>
                                      <td align="center">{influencer.stats.totalBids}</td>
                                      <td align="center">{influencer.lastSeen > 0 ? relativeTimeFormat(influencer.lastSeen*1000) : "Never"}</td>
                                      <td align="center"><img src={`https://hatscripts.github.io/circle-flags/flags/${influencer.country.toLowerCase()}.svg`} className="border rounded-circle me-2" width="26px" /></td>
  
                                    {influencers.some(existingInfluencer => influencer._id === existingInfluencer._id) ? <td align="center"><a href="#" onClick={() => updateInfluencers(influencer,false)}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#dc3545"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/></svg></a></td> : <td align="center"><a href="#" onClick={() => updateInfluencers(influencer,true)}><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#198754"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg></a></td>}
                                  </tr>
                                ))}
                                  </>
                                )
                                :
                                ( 
                                <>
                                  {influencers.map(influencer => (
                                    <tr key={influencer._id}>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <img src={getImageUrl(providers,influencer.profileImage,["w_40","c_scale"])} width="32px" height="32px" className="object-fit-cover rounded border me-2" />
                                          <div className="text-wrap text-truncate">{influencer.name}</div>
                                        </div>
                                      </td>
                                      <td><span className="tags text-nowrap">{influencer.interests[0]}</span></td>
                                      <td align="center">{influencer.stats.activeBids}</td>
                                      <td align="center">{influencer.stats.totalBids}</td>
                                      <td align="center">{influencer.lastSeen > 0 ? relativeTimeFormat(influencer.lastSeen*1000) : "Never"}</td>
                                      <td align="center"><img src={`https://hatscripts.github.io/circle-flags/flags/${influencer.country.toLowerCase()}.svg`} className="border rounded-circle me-2" width="26px" /></td>
                                      <td align="center">{influencer.permissions.includes("All") ? <span className="tags text-nowrap">All</span> : <>{permissions.filter(function(permission) {return influencer.permissions.includes(permission.value)}).slice(0,1).map(permission => <span className="tags text-nowrap" key={permission.value}>{permission.label}</span>)}</>}{influencer.permissions.length > 1 && (<span className="tags text-nowrap">+{influencer.permissions.length - 1}</span>)}</td>
                                      <td align="center">
                                        <Dropdown className="brandAction">
                                          <Dropdown.Toggle variant="default" className="border-0">
                                            <img src={settings} />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                          <a href="#" className="dropdown-item" onClick={() => editPermissions(influencer,"influencers")}>Edit Permissions</a>
                                            <a href="#" className="dropdown-item" onClick={() => updateInfluencers(influencer,false,true)}>Delete</a>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </td>
                                    </tr>
                                    ))}
                                </> 
                                )}
                                
                              </tbody>
                            </table>
                            {editMode == "influencers" && <PageScroller endpoint="influencers?getStats=true" setState={updateAllInfluencers} filter={influencerFilters} pageSize={25} />}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Container>
          </Tab.Container>
        </Container>
      </div>
    );
  }
}