import React, { useEffect, useState, Component  } from "react";
import { Container, Col, Row, Button, Card, Badge, Form, InputGroup, Modal, Dropdown, DropdownButton } from 'react-bootstrap';
import { getAverageEngagement, getTotalFollowers, getSocialBadge, getMaxFollowers } from "../../helpers/socials";
import AdminNav from '../../components/adminnav/AdminNav';
import { getSmallNumber, getPercentage } from "../../helpers/stats";
import { getDifference, getAge, withinAgeRange } from "../../helpers/dates";
import { getStatusPill } from "../../helpers/status"
import AlertModal from "../../components/alerts/alert";
import { getIndustries } from "../../helpers/dropdowns";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import FullPageSpinner from "../../components/spinner/FullPageSpinner";
import Topbar from "../../components/topbar/Topbar";
import { apiCall, PageScroller } from './../../helpers/api'
import { getFilter, setFilter } from './../../helpers/filters'
import './Applications.css';
import blueTick from './../../images/blue-tick.svg';
import searchBlue from './../../images/search-blue.svg'
import edit from './../../images/edit.svg'
import cross from './../../images/cross.svg'
const interests = getIndustries()

function ApprovalModal(props){
  var error = null;
  const applications = props.selectedApplications;

  const handleSubmit = async e => {
    e.preventDefault();
    props.setIsSubmitting(true)
    if (Object.keys(applications).length == 1){
      var response = await apiCall(`application/approve?&id=${application._id}&bypassVerification=${props.bypassVerification}&influencerType=${props.influencerSizing}`,{ "method" : "PUT", "data" : {} });
    }
    else {
      var response = await apiCall(`application/approve?multiple=True&bypassVerification=${props.bypassVerification}&influencerType=${props.influencerSizing}`,{ "method" : "PUT", "data" : {"applications":Object.keys(applications)} });
    }
    if (response["success"]) {
      props.setSelectedApplications({});
      props.setBypassVerification(false)
      props.onHide();
      props.setAlert({"display":"toast","visible":true,"title":"Applications Approved","content":"All selected applications approved","className":"bg-success text-white"})
      var currentApplications = props.applications
      for (var y in applications){
        for (var x in currentApplications) {
          if (currentApplications[x]["_id"] === applications[y]["_id"]) {
            currentApplications.splice(x,1);
            break
          }
        }
      }
      props.setApplications([...currentApplications]);
    }
    else {
      error = response["failure"]["error"]
      props.setAlert({"display":"toast","visible":true,"title":"Failed to approve applications","content":error,"className":"bg-danger text-white"})
    };
    props.setIsSubmitting(false)
  }
  
  var application = {};
  if (Object.keys(applications).length > 0){
    if (Object.keys(applications).length == 1){
      var application = applications[Object.keys(applications)[0]]
    }
    return (
      <Modal centered show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton className="bg-success text-white">
          <Modal.Title className="h6">Are you sure you want to accept {Object.keys(applications).length == 1 ? application.applicationData.firstName + " " + application.applicationData.lastName : Object.keys(applications).length + " applications"}?</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <p>Each applicant will receive an email letting them know they have been accepted. They will then be able to provide the rest of the details required, such as a profile photo and delivery details.</p>

          <p>Specify the audience size the influencer(s) fit into, or select automatic to automatically apply the correct profile type. </p>
          <Form.Select className="mb-3" value={props.influencerSizing} onChange={(e) => props.setInfluencerSizing(e.target.value)}>
            <option value="automatic">Automatic</option>
            <option value="micro">Micro (&lt;1k followers)</option>
            <option value="small">Small (&lt;10k followers)</option>
            <option value="medium">Medium (&lt;150k Followers)</option>
            <option value="large">Large (&gt;150k Followers)</option>
          </Form.Select>

          <Form.Check 
            type="checkbox"
            label="Approve unverified emails"
            id="bypassVerification"
            value={props.bypassVerification}
            onChange={(e) => props.setBypassVerification(e.target.checked)}
          />
        </Modal.Body>
          <Modal.Footer className="p-3 pt-0 border-0">
          <Form>
            <div className="d-flex align-items-center justify-content-between">
              {error && <div className="error text-danger small">{error}</div>}
              <div>
                <Button variant="outline-secondary" className="m-0 me-2" size="sm" onClick={props.onHide}>
                  Cancel
                </Button>
                <Button variant="success" className="m-0" size="sm" disabled={props.isSubmitting} type="submit" onClick={handleSubmit}>
                  {props.isSubmitting ? <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Confirming...</> : "Yes, I'm sure"}
                </Button>
              </div>
            </div>
          </Form>
        </Modal.Footer>
      </Modal>
    )}
  else{
    return (<></>)
  }
}

function RejectModal(props){
  var isSubmitting = false;
  var error = null;
  const applications = props.selectedApplications;
  const animatedComponents = makeAnimated();
  const reasons = [{"label":"Low Follower Count","value":"Low Follower Count"},{"label":"Low Engagement Rate","value":"Low Engagement Rate"},{"label":"Low Relevant Territory Audience","value":"Low Relevant Territory Audience"},{"label":"Below Expected Standards","value":"Below Expected Standards"},{"label":"Inappropriate Content","value":"Inappropriate Content"},{"label":"Irrelevant Content","value":"Irrelevant Content"},{"label":"Other","value":"Other"}]  

  const handleSubmit = async e => {
    e.preventDefault();
    isSubmitting = true
    var submissionData = { "reason" : props.rejectionReason }
    if (props.rejectionReason == "Other") { submissionData.reason = props.otherReason }
    if (props.rejectionNote != "") { submissionData.note = props.rejectionNote }
    if (Object.keys(applications).length == 1){
      var response = await apiCall(`application/reject?&id=${application._id}`,{ "method" : "PUT", "data" : submissionData});
    }
    else {
      submissionData.applications = Object.keys(applications)
      var response = await apiCall(`application/reject?multiple=True`,{ "method" : "PUT", "data" : submissionData});
    }
    if (response["success"]) {
      props.setSelectedApplications({});
      props.onHide();
      props.setAlert({"display":"toast","visible":true,"title":"Applications Rejected","content":"All selected applications rejected","className":"bg-success text-white"})
      var currentApplications = props.applications
      for (var y in applications){
        for (var x in currentApplications) {
          if (currentApplications[x]["_id"] === applications[y]["_id"]) {
            currentApplications.splice(x,1);
            break
          }
        }
      }
      props.setApplications([...currentApplications]);
    }
    else {
      error = response["failure"]["error"]
      props.setAlert({"display":"toast","visible":true,"title":"Failed to reject applications","content":error,"className":"bg-danger text-white"})
    };
    isSubmitting = false
  }

  const updateReasons = (choice,isOther=false) => {
    if (isOther){
      props.setOtherReason(choice)
    }
    else {
      props.setOtherReason("")
      props.setRejectionReason(choice)
    }
  }
  
  var application = {};
  if (Object.keys(applications).length > 0){
    if (Object.keys(applications).length == 1){
      var application = applications[Object.keys(applications)[0]]
    }
    return (
      <Modal centered show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton className="bg-danger text-white">
          <Modal.Title className="h6">Are you sure you want to reject {Object.keys(applications).length == 1 ? application.applicationData.firstName + " " + application.applicationData.lastName : Object.keys(applications).length + " applications"}?</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <p>Each applicant will receive an email letting them know they have been rejected.</p>
          <Form.Label>Please specify a reason for their rejection</Form.Label>
          <Form.Group className="mb-3">
            <Select closeMenuOnSelect={true} components={animatedComponents} options={reasons} value={{"value":props.rejectionReason,"label":props.rejectionReason}} id="reason" onChange={ (choice) => updateReasons(choice.value) } />
          </Form.Group>
          {props.rejectionReason == "Other" && (
            <>
            <Form.Label>Other Reason</Form.Label>
            <Form.Group className="mb-3">
              <Form.Control as="input" id="otherReason" value={props.otherReason} onChange={ (e) => updateReasons(e.target.value,true) } />
            </Form.Group>
            </>
          )}
            <Form.Label>Additional Feedback</Form.Label>
            <Form.Group className="mb-3">
              <Form.Control as="textarea" rows={3} id="additionalFeedback" value={props.rejectionNote} onChange={ (e) => props.setRejectionNote(e.target.value) } />
            </Form.Group>
        </Modal.Body>
          <Modal.Footer className="p-3 pt-0 border-0">
          <Form>
            <div className="d-flex align-items-center justify-content-between">
              {error && <div className="error text-danger small ms-2">{error}</div>}
              <div>
                <Button variant="outline-secondary" className="m-0 me-2" size="sm" onClick={props.onHide}>
                  Cancel
                </Button>
                <Button variant="danger" className="m-0" size="sm" disabled={props.rejectionReason == ""} type="submit" onClick={handleSubmit}>
                  Yes, I'm sure
                </Button>
              </div>
            </div>              
          </Form>
        </Modal.Footer>
      </Modal>
    )}
  else{
    return (<></>)
  }
}

function ApplicationItem(props) {
  const applicationDate = getDifference(props.application.stateChangeTime)
  var socials = [];
  if (props.application.applicationType == "influencer") {
    socials = props.application.applicationData.socials.filter(function(social) { return social.name != "meta" });
  }
  if (props.application.applicationType == "influencer"){
    const age = getAge(props.application.applicationData.dob)
    return (
      <Col className="mb-4" sm={12} md={6} lg={6} xl={4}>
        <Card className="h-100 shadow">
          <Card.Header className="py-3 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <input type="checkbox" className="form-check-input mt-0" onChange={() => props.updateSelectedApplications(props.application)} />
              <div className="ms-3">
                <div className="mb-0 cursor-pointer fw-bold card-title" onClick={() => props.onOpenApplicationModal(props.application)}>{props.application.name} {getStatusPill("Influencer")} {props.application.verified && getStatusPill("Email Verified")}</div>
                <Card.Text className="text-muted">
                  <span className="me-2">{age} years old</span>
                  <span>{props.application.applicationData.gender}</span>
                </Card.Text>
              </div>
              <img onClick={() => props.editSocials("applicationID",props.application._id)} className="editSocial" src={edit}></img>
            </div>
            <img src={`https://hatscripts.github.io/circle-flags/flags/${props.application.applicationData.country.toLowerCase()}.svg`} className="border rounded-circle me-2" width="26px" />
          </Card.Header>
          <Card.Body>
            <div className="card-text">
              <div className="d-flex align-items-start">
                {socials.map(social => (
                  <div key={social.name} className="me-3 d-flex align-items-start">
                    {getSocialBadge(social.name,null,"20px","colour",social.handle)}
                    {(social.hasOwnProperty("data") && social.data.isVerified) && <img src={blueTick} className="blueTick" />}
                    <div>
                      <div className="text-muted smaller">{(social.hasOwnProperty("data") && social.data.followers > 0 && getSmallNumber(social.data.followers)) || ""}</div>      
                      {social.hasOwnProperty("data") && social.data.hasOwnProperty("engagementRate") ? <Badge bg="info">{social.data.engagementRate.toFixed(2)}%</Badge>  : ""}    
                    </div>
                  </div> 
                ))}
              </div>
              <div className="d-flex align-items-start flex-column flex-lg-row flex-wrap">
                <div className="me-3 mt-3" key="primaryTagTitle">                                    
                  <div className="mb-1 tagsTitle">Primary Category:</div>
                  <span className="tags text-nowrap">{props.application.applicationData.interests[0]}</span>
                </div>
                <div key="tagTitle" className="d-flex flex-column mt-3">                                    
                  <div className="mb-1 tagsTitle">Interests:</div>
                  <div className="d-flex flex-wrap">
                    {props.application.applicationData.interests.length <= 3 ? 
                    (
                      <>
                      {props.application.applicationData.interests.slice((props.application.applicationData.interests.length == 1 ? 0 : 1),props.application.applicationData.interests.length).map(interest => (<span key={props.application._id+interest} className="tags me-1 mb-1 text-nowrap">{interest}</span>))}
                      </>
                    ) :
                      <>
                      {props.application.applicationData.interests.slice(1,3).map(interest => (<span key={props.application._id+interest} className="tags me-1 mb-1 text-nowrap">{interest}</span>))}
                      <span className="tags me-1 mb-1 text-nowrap fw-bold cursor-pointer" onClick={() => props.onOpenApplicationModal(props.application)}>+{props.application.applicationData.interests.length - 2}</span>
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
          <Card.Footer className="d-flex align-items-center justify-content-between">
            <div className="text-muted smaller">{applicationDate.difference} {applicationDate.unit} ago</div>
            {props.application.applicationData.hasOwnProperty("referrer") && (
            <div>
              <span className="fw-500 smaller">Referred by: </span>
              <a className="fw-normal text-muted small" href={props.application.applicationData.referrer.type == "personal" ? `/Administration/Users/?accountFilter=%7B"states"%3A%5B%5D%2C"admins"%3A%5B%5D%2C"search"%3A"${props.application.applicationData.referrer.name}"%7D` : `/Administration/Referrals?referralFilter=%7B%22search%22%3A%22${props.application.applicationData.referrer.title}%22%7D`} target="_blank"><u>{props.application.applicationData.referrer.type == "personal" ? props.application.applicationData.referrer.name : props.application.applicationData.referrer.title}</u></a>
            </div>
            )}
          </Card.Footer>
        </Card>
      </Col>
    )
  }
  else if (props.application.applicationType == "brand") {
    return (
      <Col className="mb-4" sm={12} md={6} lg={6} xl={4}>
        <Card className="h-100 shadow">
          <Card.Header className="py-3 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <input type="checkbox" className="form-check-input mt-0" onChange={() => props.updateSelectedApplications(props.application)} />
              <div className="ms-3">
                <div className="mb-0 cursor-pointer fw-bold card-title" onClick={() => props.onOpenApplicationModal(props.application)}>{props.application.name} {getStatusPill("Brand")}</div>
                <Card.Text className="text-muted">
                  <span>Applied for by {props.application.applicationData.firstName} {props.application.applicationData.lastName}</span>
                </Card.Text>
              </div>
            </div>
            <img src="https://cdn-icons-png.flaticon.com/512/197/197374.png" className="border rounded-circle me-2" width="26px" />
          </Card.Header>
          <Card.Body>
            <div className="card-text">
              <div className="mb-4">
                <div className="me-3" key="primaryTagTitle">                                    
                  <div className="mb-1 tagsTitle">Industry:</div>
                  <span className="tags">{props.application.applicationData.industry}</span>
                </div>
              </div>
              <div key="tagTitle">
                <div className="mb-1 tagsTitle">Website:</div>
                <div className="smaller text-truncate">                                    
                  <a target="blank" href={props.application.applicationData.website} title={props.application.applicationData.website}>{props.application.applicationData.website}</a>
                </div>
              </div>
            </div>
          </Card.Body>
          <Card.Footer className="d-flex align-items-center justify-content-between">
            <div className="text-muted small">{applicationDate.difference} {applicationDate.unit} ago</div>
          </Card.Footer>
        </Card>
      </Col>
    )
  }
  else if (props.application.applicationType == "agency") {
    return (
      <Col className="mb-4" sm={12} md={6} lg={6} xl={4}>
        <Card className="h-100 shadow">
          <Card.Header className="py-3 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <input type="checkbox" className="form-check-input mt-0" onChange={() => props.updateSelectedApplications(props.application)} />
              <div className="ms-3">
                <div className="mb-0 cursor-pointer fw-bold card-title" onClick={() => props.onOpenApplicationModal(props.application)}>{props.application.name} {getStatusPill("Agency")}</div>
                <Card.Text className="text-muted">
                  <span>Applied for by {props.application.applicationData.firstName} {props.application.applicationData.lastName}</span>
                </Card.Text>
              </div>
            </div>
            <img src="https://cdn-icons-png.flaticon.com/512/197/197374.png" className="border rounded-circle me-2" width="26px" />
          </Card.Header>
          <Card.Body>
            <div className="card-text">
              <div className="mb-4">
                <div className="me-3" key="primaryTagTitle">                                    
                  <div className="mb-1 tagsTitle">Representing:</div>
                  {props.application.applicationData.representing.map(type => (
                    <span key={props.application._id+type} className="tags me-1 mb-1 text-nowrap">{type}</span>
                  ))}
                </div>
              </div>
              <div key="tagTitle">
                <div className="mb-1 tagsTitle">Website:</div>
                <div className="smaller text-truncate">             
                  <a target="blank" href={props.application.applicationData.website} title={props.application.applicationData.website}>{props.application.applicationData.website}</a>
                </div>
              </div>
            </div>
          </Card.Body>
          <Card.Footer className="d-flex align-items-center justify-content-between">
            <div className="text-muted small">{applicationDate.difference} {applicationDate.unit} ago</div>
          </Card.Footer>
        </Card>
      </Col>
    )
  }
}

export default function Applications() {  

	const [applications, setApplications] = useState([]);
  const [pagingInfo, setPagingInfo] = useState({});
  const [selectedApplications, setSelectedApplications] = useState({});
  const [open, setOpen] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [bypassVerification, setBypassVerification] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("Low Follower Count")
  const [rejectionNote, setRejectionNote] = useState("")
  const [influencerSizing, setInfluencerSizing] = useState("automatic")
  const [otherReason, setOtherReason] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoaded, setLoading] = useState(true);
  const [ enhancedData, setEnhancedData ] = useState({})
  const [updateCounter, setUpdateCounter] = useState(0)
  const [showAlert, setAlert] = useState({"display":"toast","visible":false,"title":"","content":""});
  const hideAlert = () => setAlert({"display":"toast","visible":false,"title":"","content":""});
  const [applicationFilters, setApplicationFilters] = useState({"types":[],"interests":[],"genders":[],"ages":[],"socials":[],"referred":[],"followers":0,"engagementRate":0,"search":""});
  const [socialEdit, setSocialEdit] = useState({"applicationID":null,"socials":{}})

  useEffect(() => {
    if (getFilter("application")) {
      setApplicationFilters(JSON.parse(getFilter("application")))
    }
  }, []);

  async function getEnhancedApplicationData(influencerID){
    if (enhancedData[influencerID].state == "notLoaded"){
      var tempEnhancedData = enhancedData;
      tempEnhancedData[influencerID].state = "loading";
      setEnhancedData(tempEnhancedData)
      var response = await apiCall(`influencer/enhancedData?id=${influencerID}`,{ "method" : "GET" });
      if (response["success"]) {
        if (response["success"]["socials"].length > 0){
          tempEnhancedData[influencerID].state = "loaded"
          tempEnhancedData[influencerID].data = response["success"]["socials"][0]
        }
        else {
          tempEnhancedData[influencerID].state = "missing"
        }
      }
      else {
        tempEnhancedData[influencerID].state = "missing"
      }
      setEnhancedData(tempEnhancedData)
      setUpdateCounter(updateCounter + 1)
    }
  }

  const lookupSocials = async () => {
    setIsSubmitting(true)
    var response = await apiCall(`/admin/lookupSocials?&id=${selectedApplication._id}`,{ "method" : "PUT", "data" : {"socials":selectedApplication.applicationData.socials} });
    if (response["success"]){
      var tempApplications = [];
      applications.forEach(application => {
        if (application._id == selectedApplication._id){
          application.applicationData.socials = response["success"]["socials"]
        }
        tempApplications.push(application)
      })
      setApplications(tempApplications)
      setAlert({"display":"toast","visible":true,"title":"Social Data Updated","content":"Updated social stats","className":"bg-success text-white"})
    }
    else if (response["failure"]){
      setAlert({"display":"toast","visible":true,"title":"Failed to update application socials","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
    setIsSubmitting(false)
  }

  const syncApplication = async () => {
    setIsSubmitting(true)
    var response = await apiCall(`/diagnostics/syncApplication?&id=${selectedApplication._id}`,{ "method" : "POST" });
    setIsSubmitting(false)
    if (response["success"]){
      setAlert({"display":"toast","visible":true,"title":"Application Synced","content":`Synced Application - ${response["success"]["data"]["approved"] ? 'Auto-Accepted' : 'No Automatic Acception'}`,"className":"bg-success text-white"})
      setOpen(false)
      setSelectedApplication(null)
    }
    else if (response["failure"]){
      setAlert({"display":"toast","visible":true,"title":"Failed to sync application","content":response["failure"]["error"],"className":"bg-danger text-white"})
    }
  }

  const editSocials = async (field,value) => {
    var tempSocialEdit = structuredClone(socialEdit)
    if (field == "applicationID"){
      if (value){
        tempSocialEdit.socials = applications.filter(function(application) { return application._id == value })[0].applicationData.socials
      }
      tempSocialEdit.applicationID = value
    }
    else if (field == "socials"){
      tempSocialEdit.socials.forEach(social => {
        if (social.name == value.name){
          social.handle = value.handle
        }
      })
    }
    else if (field == "update"){
      var response = await apiCall(`application?id=${tempSocialEdit.applicationID}`,{ "method" : "PUT", "data" : {"socials":tempSocialEdit.socials} });
      if (response["success"]){
        setAlert({"display":"toast","visible":true,"title":"Social Data Updated","content":"Updated social handles","className":"bg-success text-white"})
        var tempApplications = structuredClone(applications)
        tempApplications.forEach(application => {
          if (application._id == tempSocialEdit.applicationID) {
            application.applicationData.socials = tempSocialEdit.socials
          }
        })
        setApplications(tempApplications)
        tempSocialEdit.applicationID = null
      }
      else if (response["failure"]){
        setAlert({"display":"toast","visible":true,"title":"Failed to update social handles","content":response["failure"]["error"],"className":"bg-danger text-white"})
      }
    }
    setSocialEdit(tempSocialEdit)
  }

  const ApplicationModal = (props) => {
    const selectedApplication = props.selectedApplication;
    if (selectedApplication != null){
      if (selectedApplication.applicationType == "influencer"){
        var meta = selectedApplication.applicationData.socials.filter(function(social) { return social.name == "meta" })[0];
        if (!meta) {
          meta = {lastUpdate:null,lastFailure:null,failureCount:0}
        }
        const updateTime = getDifference(meta.lastUpdate)
        const failureTime = getDifference(meta.lastFailure)
        const age = getAge(selectedApplication.applicationData.dob);
        const socials = selectedApplication.applicationData.socials.filter(function(social) { return social.name != "meta" });
        if (open) {
          return (
            <Modal
              show
              onHide={props.onHide}
              aria-labelledby="contained-modal-title-vcenter"
              className="right" 
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {selectedApplication.name}'s Profile
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="d-flex mb-3">
                  <div>
                    <h5 className="mb-0">{selectedApplication.name} {selectedApplication.verified && getStatusPill("Email Verified")}</h5>
                    <div className="small mb-2 text-muted">{age} years old • {selectedApplication.applicationData.gender}</div>
                      {socials.map(social => (
                        <div className="mb-1" key={social.name}>
                          {getSocialBadge(social.name,null,"20px","colour",social.handle)} {social.handle}
                          {(social.hasOwnProperty("data") && social.data.isVerified) && <img src={blueTick} className="blueTick" />}
                          <div>
                            <div className="text-muted smaller">{(social.hasOwnProperty("data") && social.data.followers > 0 && `${getSmallNumber(social.data.followers)} followers`) || ""}</div>      
                            {social.hasOwnProperty("data") && social.data.hasOwnProperty("engagementRate") ? <Badge bg="info">{social.data.engagementRate.toFixed(2)}%</Badge>  : ""}    
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="border-top pt-3">
                  <h6 className="mb-3">Contact Details</h6>
                  <div className="d-flex align-items-start flex-wrap">
                    <div className="me-3 mb-3">                                    
                      <div className="mb-1 tagsTitle" key="primaryModalTagTitle">Email:</div>
                      <span className="text-nowrap">{selectedApplication.applicationData.email}</span>
                    </div>
                  </div>
                </div>
                <div className="border-top pt-3">
                  <h6 className="mb-3">Primary Category & Interests</h6>
                  <div className="d-flex align-items-start flex-wrap">
                    <div className="me-3 mb-3">                                    
                      <div className="mb-1 tagsTitle" key="primaryModalTagTitle">Primary Category:</div>
                      <span className="tags text-nowrap">{selectedApplication.applicationData.interests[0]}</span>
                    </div>
                    <div className="d-flex flex-column mb-3">                                    
                      <div className="mb-1 tagsTitle" key="modalTagTitle">Interests:</div>
                      <div className="d-flex flex-wrap">
                        {selectedApplication.applicationData.interests.slice(1).map(interest => (<span key={"modal"+interest} className="tags me-1 mb-1 text-nowrap">{interest}</span>))}
                      </div>
                    </div>
                  </div>
                </div>
    
                {selectedApplication.applicationData.applicationMessage && (
                <div className="border-top pt-3">
                  <h6 className="mb-3">Application Message</h6>
                  <div className="d-flex align-items-start flex-wrap">
                    <div className="me-3 mb-3">                                    
                      <div className="mb-1 tagsTitle">{selectedApplication.applicationData.applicationMessage}</div>
                    </div>
                  </div>
                </div>
                )}
    
                {selectedApplication.applicationData.referenceSource && (
                <div className="border-top pt-3">
                  <h6 className="mb-3">Found Via</h6>
                  <div className="d-flex align-items-start flex-wrap">
                    <div className="me-3 mb-3">                                    
                      <div className="mb-1 tagsTitle">{selectedApplication.applicationData.referenceSource}</div>
                    </div>
                  </div>
                </div>
                )}

                {enhancedData[selectedApplication._id].state == "loaded" ? (
                  <div className="border-top pt-3 mb-3">
                    <h6 className="mb-3">Audience</h6>
                    <div>
                      <div className="mb-3 text-nowrap text-muted">                                    
                        <div className="mb-1 tagsTitle">Territory by Country:</div>
                        {enhancedData[selectedApplication._id].data.topCountries.map(country => (<div key={country.name}><b>{country.name}</b> ({getPercentage(country.weight,2)})</div>))}
                      </div>
                      <div className="mb-3 text-nowrap text-muted">                                    
                        <div className="mb-1 tagsTitle">Territory by City:</div>
                        {enhancedData[selectedApplication._id].data.topCities.map(city => (<div key={city.name}><b>{city.name}</b> ({getPercentage(city.weight,2)})</div>))}
                      </div>
                      <div className="mb-3 text-nowrap text-muted">                                    
                        <div className="mb-1 tagsTitle">Top Ages:</div>
                        {enhancedData[selectedApplication._id].data.topAges.map(age => (<div key={age.code}><b>{age.code}</b> ({getPercentage(age.weight,2)})</div>))}
                      </div>
                      <div className="mb-3 text-nowrap text-muted">                                    
                        <div className="mb-1 tagsTitle">Gender Split:</div>
                        {enhancedData[selectedApplication._id].data.genders.map(gender => (<div key={gender.name}><b>{gender.name}</b> ({getPercentage(gender.weight)})</div>))}
                      </div>
                    </div>
                  </div>
                  ) : (
                  <>
                    {enhancedData[selectedApplication._id].state == "notLoaded" ? ( 
                    <div className="border-top pt-3 mb-3"><h6 className="mb-3">Audience</h6><div>Loading Influencer Data...</div></div> 
                    ) : ( 
                    <></> 
                    )}
                  </>
                  )}
    
                <div className="border-top pt-3">
                  <h6 className="mb-3">Dyzio</h6>
                  <div className="d-flex align-items-start flex-wrap">
                    <div className="me-3 mb-3">   
                      <div className="mb-1 tagsTitle"><strong>Last Synced:</strong> {meta.lastUpdate ? `${updateTime.difference} ${updateTime.unit} ago` : "Never"}</div>   
                      <div className="mb-1 tagsTitle"><strong>Last Failure:</strong> {meta.lastFailure > 0 ? `${failureTime.difference} ${failureTime.unit} ago` : "Never"}</div>   
                      <div className="mb-1 tagsTitle"><strong>Failure Reason:</strong> {meta.failureReason ? meta.failureReason : ""}</div>   
                      <div className="mb-1 tagsTitle"><strong>Failure Count:</strong> {meta.failureCount}</div>                       
                      <button className='btn btn-primary w-auto' onClick={() => lookupSocials()}>Look Up Socials {isSubmitting && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}</button>
                      <button className='btn btn-primary w-auto' onClick={() => syncApplication()}>Sync {isSubmitting && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}</button>
                    </div>
                  </div>
                </div>
                </Modal.Body>
            </Modal>
          );
        }
        else {
          return <></>
        }
      }
      else if (selectedApplication.applicationType == "brand"){
        return (
          <Modal
            show={props.show}
            onHide={props.onHide}
            aria-labelledby="contained-modal-title-vcenter"
            className="right" 
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {selectedApplication.name}'s Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex align-items-center mb-3">
                <div>
                  <h5 className="mb-0">{selectedApplication.name}</h5>
                  <div className="small mb-2 text-muted">{selectedApplication.applicationData.country}</div>
                </div>
              </div>
              <div className="border-top pt-3 mb-3">
                <h6 className="mb-3">About</h6>
                <div><strong>Website:</strong> {selectedApplication.applicationData.website}</div>
                <div><strong>Phone:</strong> {selectedApplication.applicationData.phoneNumber}</div>
                <div><strong>Email:</strong> {selectedApplication.applicationData.email}</div>
              </div>
              <div className="border-top pt-3 mb-3">
                <h6 className="mb-3">Industry</h6>
                <div className="d-flex align-items-center">
                  <div className="me-3">                                    
                    <span className="tags">{selectedApplication.applicationData.industry}</span>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        );
      }
  
      else if (selectedApplication.applicationType == "agency"){
        return (
          <Modal
            show={props.show}
            onHide={props.onHide}
            aria-labelledby="contained-modal-title-vcenter"
            className="right" 
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {selectedApplication.applicationData.firstName}'s Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex align-items-center mb-3">
                <div>
                  <h5 className="mb-0">{selectedApplication.name}</h5>
                  <div className="small mb-2 text-muted">{selectedApplication.applicationData.country}</div>
                </div>
              </div>
              <div className="border-top pt-3 mb-3">
                <h6 className="mb-3">About</h6>
                <div><strong>Website:</strong> {selectedApplication.applicationData.website}</div>
                <div><strong>Phone:</strong> {selectedApplication.applicationData.phoneNumber}</div>
                <div><strong>Email:</strong> {selectedApplication.applicationData.email}</div>
              </div>
              <div className="border-top pt-3 mb-3">
                <h6 className="mb-3">Representing</h6>
                <div className="d-flex align-items-center">
                  <div className="me-3">                                    
                    {selectedApplication.applicationData.representing.map(type => (
                      <span key={selectedApplication._id+type} className="tags me-1 mb-1 text-nowrap">{type}</span>
                    ))}
                  </div>
                </div>
                <br />
                {selectedApplication.applicationData.clients && (
                  <>
                  <h6 className="mb-3">Potential Clients</h6>
                  <div className="d-flex align-items-center">
                    <div className="me-3">                                    
                      <span>{selectedApplication.applicationData.clients}</span>
                    </div>
                  </div>
                  </>
                )}
              </div>
            </Modal.Body>
          </Modal>
        );
      }
    }
  }

  const [modalShow, setModalShow] = React.useState(false);

  const onOpenApplicationModal = application => {
      setSelectedApplication(application);
      if (application.applicationType == "influencer"){
        getEnhancedApplicationData(application._id)
      }
      setOpen(true);
    };

  const updateSelectedApplications = (application) => {
    var applications = selectedApplications;
    if (applications.hasOwnProperty(application._id)) {
      applications = Object.fromEntries(Object.entries(applications).filter(([key]) => key != application._id)); //If we don't add this, state doesn't update :) 
    }
    else {
      applications[application._id] = application
      applications = Object.fromEntries(Object.entries(applications).filter(([key]) => key != null)); //If we don't add this, state doesn't update :) 
    }
    setSelectedApplications(applications);
  }

  const handleFilterUpdate = (value,added,type,delay=0) => {
    var filters = applicationFilters;
    if (type == "reset"){
      filters = {"types":[],"interests":[],"genders":[],"ages":[],"socials":[],"referred":[],"followers":0,"engagementRate":0,"search":""}
    }
    else if (type == "types"){
      if (added) { filters.types.push(value) }
      else { filters.types = applicationFilters.types.filter(function(type) { return type != value })}
    }
    else if (type == "interests"){
      if (added) { filters.interests.push(value) }
      else { filters.interests = applicationFilters.interests.filter(function(interest) { return interest != value })}
    }
    else if (type == "genders"){
      if (added) { filters.genders.push(value) }
      else { filters.genders = applicationFilters.genders.filter(function(gender) { return gender != value })}
    }
    else if (type == "ages"){
      if (added) { filters.ages.push(value) }
      else { filters.ages = applicationFilters.ages.filter(function(age) { return age != value })}
    }
    else if (type == "socials"){
      if (added) { filters.socials.push(value) }
      else { filters.socials = applicationFilters.socials.filter(function(social) { return social != value })}
    }
    else if (type == "followers" || type == "engagementRate" ){
      filters[type] = value
    }
    else if (type == "referred" ){
      if (added) { filters.referred.push(value) }
      else { filters.referred = applicationFilters.referred.filter(function(referrerState) { return referrerState != value })}
    }
    else if (type == "search") {
      filters.search = value.target.value.toLowerCase();
    }
    setApplicationFilters(Object.assign({},applicationFilters, filters));
    setFilter(filters,"application")
  }

  const setState = async (event,result) => { 
    result = await result;
    var tempEnhancedData = enhancedData;
    if (event.pageData.page >= 1) {
        for (var x in result["applications"]) {
            applications.push(result["applications"][x])  
        }
        setApplications(applications)
    } else {
      setApplications(result["applications"])
    }
    if (result) {
        result["applications"].forEach(application => {
        if (application.applicationType == "influencer"){
          if (!tempEnhancedData.hasOwnProperty(application._id)){
            tempEnhancedData[application._id] = {"state":"notLoaded","data":null}
          }
        }
      })
      setEnhancedData(tempEnhancedData)
    }
    setPagingInfo(result["_paging"])
  }

  return (
    <div className="bg-page pendingApplications">
      <Topbar />
      <div className="pageName">Applications</div>
      <Container id="applicant-list-container" className="fullscreenWithTopBar top-0" fluid>
        <AlertModal modal={showAlert} onClose={hideAlert} />
        <Row>
          <AdminNav />
            <div className="col-xl-12 col-xxl-10 px-0">
              <div className="ps-4 py-3 py-xl-4 pe-0 bg-white shadow-sm">
                <div className="d-flex align-items-center justify-content-between mb-lg-4 ms-xl-4">
                  <h1 className="pageTitle text-nowrap text-primary tragedyRegularFont fw-700 mb-0 fs-3">Pending Applications</h1>
                  <div className="form-group d-flex">
                    <InputGroup size="sm" className="me-3 desktopFlex">
                      <Form.Control placeholder="Search" className="border-end-0" aria-label="Search" defaultValue={applicationFilters.search} onChange={e => handleFilterUpdate(e,true,"search")}/>
                      <InputGroup.Text id="basic-addon2" className="bg-white border-end">
                        <a><img src={searchBlue} /></a>
                      </InputGroup.Text>
                    </InputGroup>
                    <div className="bg-gray pb-1 pt-1 ps-2 pe-3 rounded-start text-nowrap pageDataCount">
                      <span className="fw-500 me-1">Total: </span><span>{pagingInfo.total} Applications</span>
                    </div>
                  </div>
                </div>
                <div className="desktopFlex align-items-center mx-xl-4 filters">
                    <div className="fw-500 me-2 smaller">Filter:</div>
                    <div className="form-group me-2">
                      <DropdownButton size="sm" variant="light" title="Application Type">
                        <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                          <input type="checkbox" className="form-check-input me-2 mb-2" checked={applicationFilters.types.includes("influencer")} onChange={(e) => handleFilterUpdate("influencer",e.target.checked,"types")} />
                          <label title="" className="form-check-label">Influencer</label>
                        </div>
                        <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                          <input type="checkbox" className="form-check-input me-2 mb-2" checked={applicationFilters.types.includes("brand")} onChange={(e) => handleFilterUpdate("brand",e.target.checked,"types")} />
                          <label title="" className="form-check-label">Brand</label>
                        </div>
                        <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                          <input type="checkbox" className="form-check-input me-2 mb-2" checked={applicationFilters.types.includes("agency")} onChange={(e) => handleFilterUpdate("agency",e.target.checked,"types")} />
                          <label title="" className="form-check-label">Agency</label>
                        </div>
                      </DropdownButton>
                    </div>
                    <div className="form-group me-2">
                      <DropdownButton size="sm" variant="light" title="Categories/Interests">
                        {interests.map(interest => (
                          <div key={interest.value} className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                            <input type="checkbox" className="form-check-input me-2 mb-2" checked={applicationFilters.interests.includes(interest.value)} onChange={(e) => handleFilterUpdate(interest.value,e.target.checked,"interests")} />
                            <label title="" className="form-check-label">{interest.label}</label>
                          </div>
                        ))}
                      </DropdownButton>
                    </div>
                    <div className="form-group me-2">
                      <DropdownButton size="sm" variant="light" title="Age">
                        <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                          <input type="checkbox" className="form-check-input me-2 mb-2" checked={applicationFilters.ages.includes("18-24")} onChange={(e) => handleFilterUpdate("18-24",e.target.checked,"ages")} />
                          <label title="" className="form-check-label">18 - 24</label>
                        </div>
                        <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                          <input type="checkbox" className="form-check-input me-2 mb-2" checked={applicationFilters.ages.includes("25-34")} onChange={(e) => handleFilterUpdate("25-34",e.target.checked,"ages")} />
                          <label title="" className="form-check-label">25 - 34</label>
                        </div>
                        <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                          <input type="checkbox" className="form-check-input me-2 mb-2" checked={applicationFilters.ages.includes("35-44")} onChange={(e) => handleFilterUpdate("35-44",e.target.checked,"ages")} />
                          <label title="" className="form-check-label">35 - 44</label>
                        </div>
                        <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                          <input type="checkbox" className="form-check-input me-2 mb-2" checked={applicationFilters.ages.includes("45-59")} onChange={(e) => handleFilterUpdate("45-59",e.target.checked,"ages")} />
                          <label title="" className="form-check-label">45 - 59</label>
                        </div>
                        <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                          <input type="checkbox" className="form-check-input me-2 mb-2" checked={applicationFilters.ages.includes("60-125")} onChange={(e) => handleFilterUpdate("60-125",e.target.checked,"ages")} />
                          <label title="" className="form-check-label">60+</label>
                        </div>
                      </DropdownButton>
                    </div>
                    <div className="form-group me-2">
                      <DropdownButton size="sm" variant="light" title="Gender">
                        <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                          <input type="checkbox" className="form-check-input me-2 mb-2" checked={applicationFilters.genders.includes("Male")} onChange={(e) => handleFilterUpdate("Male",e.target.checked,"genders")} />
                          <label title="" className="form-check-label">Male</label>
                        </div>
                        <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                          <input type="checkbox" className="form-check-input me-2 mb-2" checked={applicationFilters.genders.includes("Female")} onChange={(e) => handleFilterUpdate("Female",e.target.checked,"genders")} />
                          <label title="" className="form-check-label">Female</label>
                        </div>
                        <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                          <input type="checkbox" className="form-check-input me-2 mb-2" checked={applicationFilters.genders.includes("Other")} onChange={(e) => handleFilterUpdate("Other",e.target.checked,"genders")} />
                          <label title="" className="form-check-label">Other</label>
                        </div>
                      </DropdownButton>
                    </div>
                    <div className="form-group me-2">
                      <DropdownButton size="sm" variant="light" title="Social">
                        {["Facebook","Twitter","Instagram","TikTok","YouTube"].map(social => (
                          <div key={social} className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                            <input type="checkbox" className="form-check-input me-2 mb-2" checked={applicationFilters.socials.includes(social.toLowerCase())} onChange={(e) => handleFilterUpdate(social.toLowerCase(),e.target.checked,"socials")} />
                            <label title="" className="form-check-label">{social}</label>
                          </div>
                        ))}
                      </DropdownButton>
                    </div>
                    <div className="form-group me-2">
                      <DropdownButton size="sm" variant="light" title="Followers">
                        <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                          <input type="range" id="followers" className="mx-2" min={0} max={1000000} step={10000} value={applicationFilters.followers} onChange={e => handleFilterUpdate(e.target.value,null,"followers",1000)} />  {getSmallNumber(parseInt(applicationFilters.followers),0)}
                        </div>
                      </DropdownButton>
                    </div>
                    <div className="form-group me-2">
                      <DropdownButton size="sm" variant="light" title="Engagement Rate">
                        <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                          <input type="range" id="engagementRate" className="mx-2" min={0} max={20} step={0.1} value={applicationFilters.engagementRate} onChange={e => handleFilterUpdate(e.target.value,null,"engagementRate",1000)} />  {applicationFilters.engagementRate}%
                        </div>
                      </DropdownButton>
                    </div>
                    <div className="form-group me-2">
                      <DropdownButton size="sm" variant="light" title="Referred">
                      <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                          <input type="checkbox" className="form-check-input me-2 mb-2" checked={applicationFilters.referred.includes(true)} onChange={(e) => handleFilterUpdate(true,e.target.checked,"referred")} />
                          <label title="" className="form-check-label">Yes</label>
                        </div>
                        <div className="form-check d-flex align-items-center mx-3 mt-1 text-nowrap">
                          <input type="checkbox" className="form-check-input me-2 mb-2" checked={applicationFilters.referred.includes(false)} onChange={(e) => handleFilterUpdate(false,e.target.checked,"referred")} />
                          <label title="" className="form-check-label">No</label>
                        </div>
                      </DropdownButton>
                    </div>
                    <a className="text-danger small" onClick={(e) => handleFilterUpdate(null,null,"reset")}><u>Reset</u></a>
                  </div>
              </div> 
              {(socialEdit.applicationID) && (
              <Modal show backdrop="static" centered size="lg" onHide={() => editSocials("applicationID",null)} >
              <Modal.Header className="pe-4" closeButton>
                <Modal.Title className="h5">
                  Edit Socials
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="pb-0">
                {applications.filter(function(application) { return application._id == socialEdit.applicationID })[0].applicationData.socials.filter(function(social) { return social.name != "meta" }).map(social => (
                        <div className="mb-1" key={social.name}>
                          {getSocialBadge(social.name,null,"20px","colour",social.handle)} <input class="socialInput" onChange={(e) => editSocials("socials",{"name":social.name,"handle":e.target.value})} value={socialEdit.socials.filter(function(editSocial) { return editSocial.name == social.name })[0].handle ? socialEdit.socials.filter(function(editSocial) { return editSocial.name == social.name })[0].handle : ""} />
                          {(social.hasOwnProperty("data") && social.data.isVerified) && <img src={blueTick} className="blueTick" />}
                          <div>
                            <div className="text-muted smaller">{(social.hasOwnProperty("data") && social.data.followers > 0 && `${getSmallNumber(social.data.followers)} followers`) || ""}</div>      
                            {social.hasOwnProperty("data") && social.data.hasOwnProperty("engagementRate") ? <Badge bg="info">{social.data.engagementRate.toFixed(2)}%</Badge>  : ""}    
                          </div>
                        </div>
                      ))}
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => editSocials("update",true)} variant="success" size="sm">Save</Button>
              </Modal.Footer>
              </Modal>
              )}
              { !isLoaded ? <FullPageSpinner /> : (
              <>          
                <div className="p-4 applicationsList pb-0">
                  <Row className="mx-xl-2 ps-1">
                    {applications.map(application => <ApplicationItem key={application._id} application={application} class={this} updateSelectedApplications={updateSelectedApplications} onOpenApplicationModal={onOpenApplicationModal} setModalShow={setModalShow} editSocials={editSocials} />)}
                    <ApplicationModal onHide={() => setOpen(false)} selectedApplication={selectedApplication} />
                    <ApprovalModal show={showAcceptModal} onHide={() => setShowAcceptModal(false)} selectedApplications={selectedApplications} setSelectedApplications={setSelectedApplications} bypassVerification={bypassVerification} setBypassVerification={setBypassVerification} setAlert={setAlert} applications={applications} setApplications={setApplications} setInfluencerSizing={setInfluencerSizing} influencerSizing={influencerSizing} isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting} />
                    <RejectModal show={showRejectModal} onHide={() => setShowRejectModal(false)} selectedApplications={selectedApplications} setSelectedApplications={setSelectedApplications} setAlert={setAlert} rejectionReason={rejectionReason} setRejectionReason={setRejectionReason} setOtherReason={setOtherReason} otherReason={otherReason} rejectionNote={rejectionNote} setRejectionNote={setRejectionNote} applications={applications} setApplications={setApplications} />
                  </Row>
                  <PageScroller endpoint="applications" setState={setState} filter={applicationFilters} pageSize={100} />
                </div>
              </>
              ) }
            </div>
        </Row>
        <Container fluid className="position-fixed end-0 bottom-0 shadow">
          <Row>
            <Col xxl={2} className="d-none d-xxl-flex"></Col>
            <Col xxl={10} xl={12} className="px-0">
              <div className="d-flex align-items-center justify-content-end bg-white p-3 pe-lg-5 pe-sm-3">
                {/* <div><span className="text-muted small">-- pagination will be added here --</span></div> */}
                <div>
                  <button type="button" className="btn btn-sm btn-outline-danger me-3" disabled={Object.keys(selectedApplications).length == 0} onClick={() => setShowRejectModal(true)}>Decline {Object.keys(selectedApplications).length} {Object.keys(selectedApplications).length == 1 ? "Application " : "Applications"}</button>
                  <button type="button" className="btn btn-sm btn-success" disabled={Object.keys(selectedApplications).length == 0} onClick={() => setShowAcceptModal(true)}>Approve {Object.keys(selectedApplications).length} {Object.keys(selectedApplications).length == 1 ? "Application\u00A0" : "Applications"}</button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>

    </div>
  );
}